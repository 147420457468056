import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const endpoint = `${config.globalApi}/currency`;

export const saveCurrency = (currency) => {
  // CREATE A NEW CURRENCY
  if (!currency.id) {
    const currencyObj = {
      donatable: Boolean(currency.donatable),
      currencyTranslations: [
        {
          name: currency.englishName,
          locale: "en",
        },
        {
          name: currency.arabicName,
          locale: "ar",
        },
      ],
    };
    return http.post(`${endpoint}/add`, currencyObj, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
  // UPDATE EXISTING CURRENCY
  if (currency.id) {
    const currencyObj = {
      id: currency.id,
      donatable: currency.donatable,
      currencyTranslations: [
        {
          name: currency.englishName,
          locale: "en",
          id: currency.englishId,
        },
        {
          name: currency.arabicName,
          locale: "ar",
          id: currency.arabicId,
        },
      ],
    };
    return http.post(`${endpoint}/update/${currency.id}`, currencyObj, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
};

export const getCurrencies = () => {
  return http.get(endpoint + "?all=true", {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
};

export const getActiveCurrencies = () => {
  return http.get(endpoint + "?active=true", {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
};

export const getCurrency = (currencyId) => {
  return http.get(`${endpoint}/detail/${currencyId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
};

export const toggleCurrencyActive = (currencyId) => {
  return http.post(
    `${endpoint}/active/${currencyId}`,
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
        "Accept-Language": "en",
        "Content-Type": "application/json",
      },
    }
  );
};
