import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginForm from "./components/Login/LoginForm";
import Forget from "./components/forgot-password/forget-password";
import Reset from "./components/Reset_Password/Reset_Password";
import ResponsiveDrawer from "./components/common/drawer";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  render() {
    return (
      <>
        <ToastContainer />
        <Switch>
          <Route exact path="/reset-password/:token" component={Reset} />
          <Route exact path="/forget-password" component={Forget} />
          <Route path="/" exact component={LoginForm} />
          <ResponsiveDrawer />
        </Switch>
      </>
    );
  }
}

export default App;
