import React, { Component } from "react";
import { DonateToProjects } from "../../../services/donationService";
import { getProjects } from "../../../services/projectsServices";
import { toast } from "react-toastify";
import isEmpty from "../../../utils/objectEmpty";

class HubDonationForm extends Component {
  state = {
    hubId: "",
    amount: "",
    projectId: "",
    sadagaat: false,
    projectOptions: [],
    projects: [],
    project: {},
  };

  _project = React.createRef();
  _amount = React.createRef();

  async componentDidMount() {
    const id = this.props.match.params.id;
    try {
      let { data: projects } = await getProjects();
      if (id === "sadagaat") {
        projects = projects.filter((project) => project.donationProgress < 100);
        const projectOptions = this.mapToOptions(projects);
        this.setState({ sadagaat: true, projectOptions, projects });
      } else {
        projects = projects.filter(
          (project) =>
            project.donationProgress < 100 && project.hubId === parseInt(id)
        );
        const projectOptions = this.mapToOptions(projects);
        this.setState({ hubId: id, projectOptions, projects });
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  mapToOptions = (array) => {
    return array.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
  };

  handleProjectChange = (event) => {
    const project = [...this.state.projects].find(
      (project) => project.id == event.target.value
    );
    this.setState({ projectId: event.target.value, project });
  };

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { hubId, projectId, amount, sadagaat } = this.state;
    try {
      await DonateToProjects(hubId, "", projectId, amount, sadagaat);
      toast.success("Money Added To Project");
      this._amount.current.value = "";
      this._project.current.value = "";
    } catch (error) {
      toast.warn(error.response.data);
    }
  };

  render() {
    const { project } = this.state;
    const max = isEmpty(project) ? "" : project.goal - project.raised;
    return (
      <div className="card text-center mx-auto mt-5 px-5 py-3 w-50 rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="projects">Projects</label>
            <select
              ref={this._project}
              required
              className="form-control"
              id="projects"
              placeholder="Select Hub "
              aria-describedby="Hubs"
              onChange={this.handleProjectChange}
            >
              <option value=""></option>
              {this.state.projectOptions}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              max={max}
              ref={this._amount}
              type="number"
              className="form-control text-center"
              id="amount"
              required
              onChange={this.handleAmountChange}
            />
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default HubDonationForm;
