import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/partener";

export function getPartners() {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getSinglePrtner(id) {
  return http.get(`${apiEndpoint}/${id}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function addPartner(data) {
  const partner = {
    base64Image: data.base64Image,
    link: data.link,
  };
  return http.post(`${apiEndpoint}/add`, partner, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function updatePartner(data) {
  const partner = {
    id: data.id,
    base64Image: data.base64Image,
    link: data.link,
  };
  return http.post(`${apiEndpoint}/add`, partner, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deletePartner(id) {
  return http.delete(`${apiEndpoint}/delete/${id}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
