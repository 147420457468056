import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import Review from "./review";
import Editor from "../../common/editor";
import Joi from "joi";
import Input, { ArabicInput } from "../../common/input";
import Select, { ArabicSelect } from "../../common/select";
import {
  getValidationSchema,
  validateCurrentStep,
  validateAllFields,
} from "./validations";
import { toast } from "react-toastify";
import { fileToBase64 } from "../../../utils/filereader";
import {
  getProject,
  deleteProjectImages,
  saveProject,
  updateProjectImages,
  addProgramVideo,
  deleteProgramVideo,
} from "../../../services/volunteerProjects";
import NotFound from "../../NotFound/NotFound";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navButtons: {
    display: "flex",
    justifyContent: "center",
  },
}));

function getSteps() {
  return ["Information", "Description", "Media", "location", "Review"];
}

export default function MultiStepForm(props) {
  // ******** STATE & SIDE EFFECTS ************ //
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({
    id: "",
    englishId: "",
    arabicId: "",
    nameArabic: "",
    descriptionArabic: " ",
    nameEnglish: "",
    descriptionEnglish: "",
    locationNameArabic: "",
    locationNameEnglish: "",
    target: "",
    statusArabic: "",
    statusEnglish: "",
    subscribed: "",
    base64Images: [],
    base64Image: "",
    startAt: "",
    percentage: 0,
    file: "",
    files: [],
    video: null,
    videosToDelete: [],
    programVideos: [],
    projectImages: [],
    imagesToDelete: [],
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);

  const populateProject = async () => {
    try {
      const projectId = props.match.params.id;
      const { data: project } = await getProject(projectId);
      const mappedProject = mapToViewModel(project);
      setState(mappedProject);
    } catch (ex) {
      setError(true);
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        toast.error("No Such Project");
    }
  };

  const mapToViewModel = (project) => {
    const arabic = project.programTranslations.find(
      (trans) => trans.locale === "ar"
    );
    const english = project.programTranslations.find(
      (trans) => trans.locale === "en"
    );
    return {
      id: project.id || "",
      englishId: english.id || "",
      arabicId: arabic.id || "",
      nameArabic: arabic.name || "",
      descriptionArabic: arabic.description || "",
      nameEnglish: english.name || "",
      descriptionEnglish: english.description || "",
      locationNameArabic: arabic.locationName || "",
      locationNameEnglish: english.locationName || "",
      statusEnglish: english.status || "",
      statusArabic: arabic.status || "",
      target: project.target || "",
      subscribed: project.subscribed || "",
      startAt: project.startAt || "",
      projectImages: project.images || [],
      programVideos: project.videos || [],
      base64Images: state.base64Images,
      base64Image: state.base64Image,
      percentage: state.percentage,
      file: state.file,
      files: state.files,
      video: null,
      imagesToDelete: [],
      videosToDelete: [],
    };
  };

  useEffect(() => {
    populateProject();
    return () => {
      // CLEAN UP FUNCTION CALL
    };
  }, []);

  // ******** END OF STATE & SIDE EFFECTS ************ //

  // ********* RENDERING INPUTS ************* //
  const renderEditor = (name, label, language, required) => {
    return (
      <Editor
        language={language}
        label={label}
        name={name}
        value={state[name]}
        onChange={handleEditorChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <Input
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max='9999-12-31'
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <ArabicInput
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max='9999-12-31'
        />
      );
    }
    return (
      <ArabicInput
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderSelect = (name, label, options, required) => {
    return (
      <Select
        name={name}
        value={state[name]}
        label={label}
        options={options}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicSelect = (name, label, options, required) => {
    return (
      <ArabicSelect
        name={name}
        value={state[name]}
        label={label}
        options={options}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  // ******** END OF RENDERING INPUTS ********** //

  // ********** EVENT HANDLERS **************** //
  const handleInputChange = ({ currentTarget: input }) => {
    const errs = { ...errors };
    const res = validateProperty(input);

    if (input.name === "target") {
      if (res) {
        if (res.length === 1) {
          if (res[0].path[0] === "target") {
            errs[input.name] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["target"];
            }
          }
          if (res[0].path[0] === "subscribed") {
            errs["subscribed"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["subscribed"];
            }
            delete errs[input.name];
          }
        }
        if (res.length === 2) {
          if (res[0].path[0] === "target") {
            errs[input.name] = res[0].message.replace(/[""]/g, "");
            errs["subscribed"] = res[1].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["subscribed"];
            }
          }
          if (res[0].path[0] === "subscribed") {
            errs[input.name] = res[1].message.replace(/[""]/g, "");
            errs["subscribed"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["target"];
            }
          }
        }
      } else {
        delete errs[input.name];
        delete errs["subscribed"];
      }
      const data = { ...state };
      data[input.name] = input.value;
      setState(data);
      setErrors(errs);
    }

    if (input.name !== "target") {
      if (res) errs[input.name] = res;
      else delete errs[input.name];
      const data = { ...state };
      data[input.name] = input.value;
      setState(data);
      setErrors(errs);
    }
  };

  const handleDropzoneChange = ({ meta, file }, status) => {
    const files = [...state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
    if (status === "done") {
      if (files.includes(file)) {
        return;
      }
      setState({ ...state, files: updatedFiles, base64Images: [] });
    }
    if (status === "removed") {
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
  };

  const handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      if (file === state.video) {
        return;
      }
      setState({ ...state, video: file });
    }
    if (status === "removed") {
      setState({ ...state, video: null });
    }
  };

  const handleEditorChange = (editorData, name) => {
    const errs = { ...errors };
    const errorMessage = validateEditor(name, editorData);
    if (errorMessage) errs[name] = errorMessage;
    else delete errs[name];
    const updatedState = { ...state };
    updatedState[name] = editorData;
    setState(updatedState);
    setErrors(errs);
  };

  const handleMainImageChange = async (evt) => {
    try {
      let file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
      } else {
        const updatedState = { ...state };
        const base64 = await fileToBase64(file);
        updatedState.base64Image = base64;
        updatedState.file = file;
        setState(updatedState);
      }
    } catch (error) {
      return;
    }
  };

  const handleNext = () => {
    if (activeStep === 4) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleButtonDisable = () => {
    const errors = validateCurrentStep(activeStep, state);
    if (activeStep === 4) {
      return false;
    }
    if (activeStep !== 4) {
      return errors ? true : false;
    }
  };

  const handleDeleteImage = (imageId) => {
    const originalImages = [...state.projectImages];
    const updatedImages = originalImages.filter((img) => img.id !== imageId);
    const imagesToDelete = [...state.imagesToDelete];
    imagesToDelete.push(imageId);
    setState({ ...state, projectImages: updatedImages, imagesToDelete });
  };

  const handleVideoDelete = (videoId) => {
    const originalVideos = [...state.programVideos];
    const updatedVideos = originalVideos.filter((img) => img.id !== videoId);
    const videosToDelete = [...state.videosToDelete];
    videosToDelete.push(videoId);
    setState({ ...state, programVideos: updatedVideos, videosToDelete });
  };

  const handlePercentageChange = (percentage) => {
    setState({ ...state, percentage });
  };

  const handleProjectSave = async (data) => {
    toast.info("saving project", {
      position: "bottom-right",
      hideProgressBar: true,
      closeButton: false,
      autoClose: 1500,
    });
    if (state.imagesToDelete.length !== 0) {
      await deleteProjectImages(state.imagesToDelete, state.id);
    }
    if (state.videosToDelete.length !== 0) {
      await deleteProgramVideo(state.videosToDelete, state.id);
    }
    if (state.base64Images.length > 0) {
      await updateProjectImages(state.id, state.base64Images);
    }
    if (state.video instanceof File) {
      await addProgramVideo(state.video, state.id, handlePercentageChange);
    }
    await saveProject(data);
    setState({ ...state, base64Images: [], imagesToDelete: [] });
    toast.success("Saved");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleSubmit = async () => {
    const { files } = state;
    try {
      for (let i = 0; i < files.length; i++) {
        readFile(files[i]);
      }
      setTimeout(() => handleProjectSave(state), 1000);
    } catch (ex) {
      setState({ ...state, base64Images: [] });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  const handleSkipToLast = () => {
    setActiveStep(steps.length - 1);
  };

  const handleSkipButtonDisable = () => {
    if (activeStep === steps.length - 1 || validateAllFields(state)) {
      return true;
    }
  };

  // ********** END OF EVENT HANDLERS ********** //

  // ********** VALIDATORS AND HELPER FUNCTIONS *********** //
  const validateProperty = ({ name, value }) => {
    let obj = { [name]: value };
    let schema = Joi.object({
      [name]: getValidationSchema(activeStep).extract(name),
    });
    if (name === "subscribed") {
      obj = { [name]: value, target: parseFloat(state.target) };
      schema = Joi.object({
        [name]: getValidationSchema(activeStep).extract(name),
        target: getValidationSchema(activeStep).extract("target"),
      });
    }
    if (name === "target") {
      obj = { [name]: value, subscribed: parseFloat(state.subscribed) };
      schema = Joi.object({
        [name]: getValidationSchema(activeStep).extract(name),
        subscribed: getValidationSchema(activeStep).extract("subscribed"),
      });
      const { error } = schema.validate(obj, {
        convert: true,
        abortEarly: false,
      });
      return error ? error.details : null;
    }
    let { error } = schema.validate(obj, { convert: true });
    if (
      error &&
      error.details[0].message === '"Registered Volunteers" must be a number'
    )
      return "Registered Volunteers must be a number";
    if (error && error.details[0].message === '"Target" must be a number')
      return null;
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  const validateEditor = (name, value) => {
    const obj = { [name]: value };
    const schema = Joi.object({
      [name]: getValidationSchema(activeStep).extract(name),
    });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FirstStep
            renderInput={renderInput}
            renderArabicInput={renderArabicInput}
            renderSelect={renderSelect}
            renderArabicSelect={renderArabicSelect}
          />
        );
      case 1:
        return <SecondStep renderEditor={renderEditor} />;
      case 2:
        return (
          <ThirdStep
            file={state.file}
            files={state.files}
            video={state.video}
            programVideos={state.programVideos}
            projectImages={state.projectImages}
            handleChangeImage={handleMainImageChange}
            handleDropzoneChange={handleDropzoneChange}
            handleVideoChange={handleVideoChange}
            handleVideoDelete={handleVideoDelete}
            deleteImage={handleDeleteImage}
          />
        );
      case 3:
        return (
          <FourthStep
            renderInput={renderInput}
            renderArabicInput={renderArabicInput}
          />
        );
      case 4:
        return <Review data={state} />;
      default:
        return "Unknown step";
    }
  }

  const readFile = async (file) => {
    const data = { ...state };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    setState(data);
  };
  // ********** END OF VALIDATORS AND HELPER FUNCTIONS *********** //

  if (error) {
    return <NotFound />;
  }
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <>
          {getStepContent(activeStep)}
          <div className={classes.navButtons}>
            <Button
              variant='contained'
              disabled={activeStep <= 0}
              onClick={handleBack}
              className={classes.button}>
              Back
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleNext}
              disabled={handleButtonDisable()}
              className={classes.button}>
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSkipToLast}
              disabled={handleSkipButtonDisable()}
              className={classes.button}>
              Last
            </Button>
          </div>
        </>
      }
    </div>
  );
}
