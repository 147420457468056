import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAdmin } from "../../utils/checkRole";
import PropTypes from "prop-types";

/**
 * Render an Admin Only route
 */
const PrivateAdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin() ? <Component {...props} /> : <Redirect to="/404" />
      }
    />
  );
};

PrivateAdminRoute.propTypes = {
  /** Component to render as admin */
  Component: PropTypes.any.isRequired,
  /** Rest of props */
  rest: PropTypes.any,
};

export default PrivateAdminRoute;
