import { getUserRole } from "../services/authService";

const token = getUserRole().token;
const role = getUserRole().userRole;

export function isAdmin() {
  // return token && role === "ROLE_ADMIN" ? true : false;
  return (token && role === "ROLE_ADMIN");
}

export function isAuthorizer() {
  // return token && role === "ROLE_AUTHORIZER" ? true : false;
  if (token && role === "ROLE_AUTHORIZER") {
    return true;
  } else {
    return false;
  }
}

export function isDataEntry() {
  return (token && role === "ROLE_DATA_ENTRY");
}
