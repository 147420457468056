import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getProject } from "../../services/projectsServices";
import { toast } from "react-toastify";
import isEmpty from "../../utils/objectEmpty";
import NotFound from "../NotFound/NotFound";
import Loader from "../common/loader";
import { projectImage } from "../../services/imageUrl";
import parse from "html-react-parser";

class SingleProjectPage extends Component {
  state = { project: {}, isLoading: true, arabic: {} };

  async componentDidMount() {
    const projectId = this.props.match.params.id;
    try {
      const { data: project } = await getProject(projectId);
      const arabic = project.projectTranslations.find(
        (trans) => trans.locale === "ar"
      );
      this.setState({ project, arabic, isLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        this.setState({ isLoading: false });
        toast.error("No Such Project ");
      }
    }
  }

  statusMapping = (status) => {
    console.log(status);
    switch (status) {
      case "completed":
        return "مكتمل";
      case "ongoing":
        return "قيد العمل";
      case "planned":
        return "مخطط";
      default:
        return null;
    }
  };

  render() {
    const { project, arabic, isLoading } = this.state;
    if (isLoading === true) {
      return <Loader />;
    } else if (isEmpty(project) && isLoading === false) {
      return <NotFound />;
    } else if (!isEmpty(project) && isLoading === false) {
      return (
        <div className="container">
          <div className="card-body m-3 shadow p-3 bg-white rounded ">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {/* <img
                  src={`${globalApi}/projects/${project.images[2].id}/image`}
                  className="img-fluid rounded "
                  alt={`${project.images[0].name}`}
                /> */}
                <img
                  src={projectImage(project.id)}
                  className="img-fluid rounded "
                  alt=""
                />
                <div className="progress mt-3">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${project.projectProgress}%` }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {`${project.projectProgress} %`}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <table className="table table-sm table-hover">
                  <caption>{project.name} details</caption>
                  <tbody>
                    <tr>
                      <th className="text-success">Name</th>
                      <td>{project.name}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Description</th>
                      <td>{parse(project.description)}</td>
                    </tr>
                    <tr dir="rtl">
                      <th className="text-success">Arabic Name</th>
                      <td>{arabic.name}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Arabic Description</th>
                      <td>{parse(arabic.description)}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Location</th>
                      <td>{project.locationName}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Arabic Location</th>
                      <td>{arabic.locationName}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Status</th>
                      <td>{project.status}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Arabic Status</th>
                      <td>{this.statusMapping(arabic.status)}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Goal</th>
                      <td>{project.goal}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Raised</th>
                      <td>{project.raised}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Donation Progress</th>
                      <td>{project.donationProgress}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <Link
                  to="/projects"
                  className="btn btn-outline-secondary btn-block"
                >
                  Back
                </Link>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <Link
                  to={`/projects/form/${project.id}`}
                  className="btn btn-outline-success btn-block"
                >
                  Edit
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SingleProjectPage;
