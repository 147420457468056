import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import Review from "../review";
import Editor from "../../common/editor";
import Joi from "joi";
import Input, { ArabicInput } from "../../common/input";
import Select, { ArabicSelect } from "../../common/select";
import {
  getValidationSchema,
  validateCurrentStep,
  validateAllFields,
} from "./validations";
import { toast } from "react-toastify";
import { fileToBase64 } from "../../../utils/filereader";
import {
  addProjectStep,
  deleteProjectImages,
  deleteProjectStep,
  getProject,
  getProjectSteps,
  saveProject,
  updateProjectImages,
} from "../../../services/projectsServices";
import { getSubhubs } from "../../../services/subhubsServices";
import NotFound from "../../NotFound/NotFound";
import FifthStep from "./FifthStep";
import ProjectsSteps from "./ProjectSteps";
import { getActiveCurrencies } from "../../../services/currencyService";
import CheckBox from "../../common/checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navButtons: {
    display: "flex",
    justifyContent: "center",
  },
}));

function getSteps() {
  return [
    "Information",
    "Description",
    "Media",
    "location",
    "Timeline",
    "Review",
  ];
}

export default function MultiStepForm(props) {
  // ******** STATE & SIDE EFFECTS ************ //
  const classes = useStyles();
  const steps = getSteps();
  const [sendingData, setSendingData] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [subhubOptions, setSubhubOptions] = useState([]);
  const [state, setState] = useState({
    id: "",
    englishId: "",
    arabicId: "",
    nameArabic: "",
    descriptionArabic: " ",
    nameEnglish: "",
    descriptionEnglish: "",
    locationNameArabic: "",
    locationNameEnglish: "",
    pinToHome: false,
    locationLng: "",
    locationLat: "",
    goal: "",
    projectProgress: "",
    statusArabic: "",
    statusEnglish: "",
    raised: "",
    subHubId: "",
    base64Images: [],
    base64Image: "",
    startAt: "",
    percentage: 0,
    file: "",
    files: [],
    projectImages: [],
    imagesToDelete: [],
    stepArabicDescription: "",
    stepEnglishDecsription: "",
    stepBase64Images: [],
    stepImageFiles: [],
    stepVideo: {},
  });
  const [currencies, setCurrencies] = useState([]);
  const [projectSteps, setProjectSteps] = useState([]);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);

  const fetchSubhub = async () => {
    try {
      const { data: subhubs } = await getSubhubs();
      const subhubOptions = subhubs.map((subhub) => ({
        name: subhub.name,
        value: subhub.id,
      }));
      setSubhubOptions(subhubOptions);
    } catch (error) {
      setSubhubOptions([]);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const { data: currencies } = await getActiveCurrencies();
      const currencyOptions = currencies.map((currency) => ({
        name: currency.currencyTranslations[0].name,
        value: `${currency.id}`,
      }));
      setCurrencies(currencyOptions);
    } catch (error) {
      setCurrencies([]);
    }
  };

  const populateProject = async () => {
    try {
      const projectId = props.match.params.id;
      const { data: project } = await getProject(projectId);
      const mappedProject = mapToViewModel(project);
      setState(mappedProject);
    } catch (ex) {
      setError(true);
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        toast.error("No Such Project");
    }
  };

  const fetchProjectSteps = async () => {
    try {
      const projectId = props.match.params.id;
      const { data: steps } = await getProjectSteps(projectId);
      setProjectSteps(steps);
    } catch (error) {
      toast.error("could not get steps");
    }
  };

  const mapToViewModel = (project) => {
    const arabic = project.projectTranslations.find(
      (trans) => trans.locale === "ar"
    );
    const english = project.projectTranslations.find(
      (trans) => trans.locale === "en"
    );
    return {
      id: project.id || "",
      englishId: english.id || "",
      arabicId: arabic.id || "",
      currencyId: project.currency ? `${project.currency.id}` : "",
      nameArabic: arabic.name || "",
      descriptionArabic: arabic.description || "",
      nameEnglish: english.name || "",
      descriptionEnglish: english.description || "",
      locationNameArabic: arabic.locationName || "",
      locationNameEnglish: english.locationName || "",
      pinToHome: project.pinToHome || false,
      locationLat: project.locationLat || "",
      locationLng: project.locationLng || "",
      statusEnglish: english.status || "",
      statusArabic: arabic.status || "",
      goal: project.goal || "",
      projectProgress: project.projectProgress || 0,
      raised: project.raised || "",
      subHubId: `${project.subHubId}`,
      startAt: project.startAt || "",
      projectImages: project.images || [],
      base64Images: state.base64Images,
      base64Image: state.base64Image,
      percentage: state.percentage,
      file: state.file,
      files: state.files,
      stepArabicDescription: state.stepArabicDescription,
      stepEnglishDecsription: state.stepEnglishDecsription,
      stepBase64Images: state.stepBase64Images,
      stepImageFiles: state.stepImageFiles,
      stepVideo: state.stepVideo,
      imagesToDelete: [],
    };
  };

  useEffect(() => {
    fetchSubhub();
    fetchCurrencies();
    populateProject();
    fetchProjectSteps();
    return () => {
      // CLEAN UP FUNCTION CALL
    };
  }, []);

  // ******** END OF STATE & SIDE EFFECTS ************ //

  // ********* RENDERING INPUTS ************* //
  const renderEditor = (name, label, language, required) => {
    return (
      <Editor
        language={language}
        label={label}
        name={name}
        value={state[name]}
        onChange={handleEditorChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderCheckbox = (name, label, required) => {
    return (
      <CheckBox
        name={name}
        checked={state[name]}
        label={label}
        onChange={handleCheckboxChange}
        error={errors[name]}
        required={required}
      />
    );
  };

  const renderInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <Input
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max="9999-12-31"
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <ArabicInput
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max="9999-12-31"
        />
      );
    }
    return (
      <ArabicInput
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderSelect = (name, label, options, required) => {
    return (
      <Select
        name={name}
        value={state[name]}
        label={label}
        options={options}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicSelect = (name, label, options, required) => {
    return (
      <ArabicSelect
        name={name}
        value={state[name]}
        label={label}
        options={options}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  // ******** END OF RENDERING INPUTS ********** //

  // ********** EVENT HANDLERS **************** //
  const handleInputChange = ({ currentTarget: input }) => {
    const errs = { ...errors };
    const res = validateProperty(input);

    if (input.name === "goal") {
      if (res) {
        if (res.length === 1) {
          if (res[0].path[0] === "goal") {
            errs[input.name] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["goal"];
            }
          }
          if (res[0].path[0] === "raised") {
            errs["raised"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["raised"];
            }
            delete errs[input.name];
          }
        }
        if (res.length === 2) {
          if (res[0].path[0] === "goal") {
            errs[input.name] = res[0].message.replace(/[""]/g, "");
            errs["raised"] = res[1].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["raised"];
            }
          }
          if (res[0].path[0] === "raised") {
            errs[input.name] = res[1].message.replace(/[""]/g, "");
            errs["raised"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["goal"];
            }
          }
        }
      } else {
        delete errs[input.name];
        delete errs["raised"];
      }
      const data = { ...state };
      data[input.name] = input.value;
      setState(data);
      setErrors(errs);
    }

    if (input.name !== "goal") {
      if (res) errs[input.name] = res;
      else delete errs[input.name];
      const data = { ...state };
      data[input.name] = input.value;
      setState(data);
      setErrors(errs);
    }
  };

  const handleCheckboxChange = ({ target: checkbox }) => {
    const errs = { ...errors };
    const res = validateProperty({
      name: checkbox.name,
      value: checkbox.checked,
    });
    if (res) errors[checkbox.name] = res;
    else delete errors[checkbox.name];
    const data = { ...state };
    data[checkbox.name] = checkbox.checked;
    setState(data);
    setErrors(errs);
  };

  const handleDropzoneChange = ({ meta, file }, status) => {
    const files = [...state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
    if (status === "done") {
      if (files.includes(file)) {
        return;
      }
      setState({ ...state, files: updatedFiles, base64Images: [] });
    }
    if (status === "removed") {
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
  };

  const handleMapChange = (lat, lng) => {
    setState({
      ...state,
      locationLat: lat,
      locationLng: lng,
    });
  };

  const handleStepImageChange = ({ meta, file }, status) => {
    const files = [...state.stepImageFiles];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = !files.includes(file)
      ? [...state.stepImageFiles, file]
      : [...state.stepImageFiles];

    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      setState({
        ...state,
        stepImageFiles: filteredFiles,
        stepBase64Images: [],
      });
    }
    if (status === "done") {
      if (files.includes(file)) {
        return;
      } else {
        setState({
          ...state,
          stepImageFiles: updatedFiles,
          stepBase64Images: [],
        });
      }
    }
    if (status === "removed") {
      setState({
        ...state,
        stepImageFiles: filteredFiles,
        stepBase64Images: [],
      });
    }
  };

  const handleStepVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      setState({ ...state, stepVideo: file });
    }
    if (status === "removed") {
      setState({ ...state, stepVideo: {} });
    }
  };

  const handleStepDelete = async (step) => {
    const originalSteps = [...projectSteps];
    const steps = originalSteps.filter((s) => s.id !== step.id);
    setProjectSteps(steps);
    try {
      const res = await deleteProjectStep(step.id);
      if (res) toast.success("Deleted Successfully !");
    } catch (ex) {
      toast.error("This step has already been deleted.");
      if (ex.response && ex.response.status === 404) {
      } else {
        toast.error("could not delete step");
      }
      setProjectSteps(originalSteps);
    }
  };

  const handleEditorChange = (editorData, name) => {
    const errs = { ...errors };
    const errorMessage = validateEditor(name, editorData);
    if (errorMessage) errs[name] = errorMessage;
    else delete errs[name];
    const updatedState = { ...state };
    updatedState[name] = editorData;
    setState(updatedState);
    setErrors(errs);
  };

  const handleMainImageChange = async (evt) => {
    try {
      let file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
      } else {
        const updatedState = { ...state };
        const base64 = await fileToBase64(file);
        updatedState.base64Image = base64;
        updatedState.file = file;
        setState(updatedState);
      }
    } catch (error) {
      return;
    }
  };

  const handleStepSubmit = () => {
    const { stepImageFiles } = state;
    try {
      for (let i = 0; i < stepImageFiles.length; i++) {
        stepImagesToBase64(stepImageFiles[i]);
      }
    } catch (ex) {
      setState({ ...state, stepBase64Images: [] });
      toast.error("Something went wrong !");
      return;
    }
    setTimeout(() => handleStepSave(), 1000);
  };

  const handleStepSave = async () => {
    const step = {
      id: state.id,
      stepArabicDescription: state.stepArabicDescription,
      stepEnglishDecsription: state.stepEnglishDecsription,
      video: state.stepVideo,
      base64Images: state.stepBase64Images,
    };

    try {
      toast.info("Saving step", {
        position: "bottom-right",
        hideProgressBar: true,
        closeButton: false,
        autoClose: 1500,
      });
      await addProjectStep(step);
      await fetchProjectSteps();
      setState({
        ...state,
        stepBase64Images: [],
        stepImageFiles: [],
        stepVideo: {},
        stepEnglishDecsription: "",
      });
      setState({ ...state, stepArabicDescription: "" });
      setErrors({
        ...errors,
        stepEnglishDecsription: "",
        stepArabicDescription: "",
      });
      toast.success("Saved Successfully");
    } catch (error) {
      toast.error("Could not save step");
    }
  };

  const handleNext = () => {
    if (activeStep === 5) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleButtonDisable = () => {
    const errors = validateCurrentStep(activeStep, state);
    if (activeStep === 4) {
      return false;
    }
    if (activeStep !== 4) {
      if (sendingData) {
        return true;
      } else {
        return errors ? true : false;
      }
    }
  };

  const handleDeleteImage = (imageId) => {
    const originalImages = [...state.projectImages];
    const updatedImages = originalImages.filter((img) => img.id !== imageId);
    const imagesToDelete = [...state.imagesToDelete];
    imagesToDelete.push(imageId);
    setState({ ...state, projectImages: updatedImages, imagesToDelete });
  };

  const handlePercentageChange = (percentage) => {
    setState({ ...state, percentage });
  };

  const handleProjectSave = async (data) => {
    toast.info("saving project", {
      position: "bottom-right",
      hideProgressBar: true,
      closeButton: false,
      autoClose: 1500,
    });
    if (state.imagesToDelete.length !== 0) {
      await deleteProjectImages(state.imagesToDelete);
    }
    if (state.base64Images.length > 0) {
      await updateProjectImages(
        state.id,
        state.base64Images,
        handlePercentageChange
      );
    }
    await saveProject(data);
    setState({ ...state, base64Images: [], imagesToDelete: [] });
    toast.success("Saved");
    setTimeout(() => {
      props.history.push("/projects");
    }, 3000);
  };

  const handleSubmit = async () => {
    setSendingData(true);
    const { files } = state;
    try {
      for (let i = 0; i < files.length; i++) {
        readFile(files[i]);
      }
      setTimeout(() => handleProjectSave(state), 1000);
    } catch (ex) {
      setSendingData(false);
      setState({ ...state, base64Images: [] });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  const handleStepButtonDisable = () => {
    const errors = validateCurrentStep(activeStep, state);
    return errors ? true : false;
  };

  const handleStepUpdate = (steps) => {
    setProjectSteps(steps);
  };

  const handleSkipToLast = () => {
    setActiveStep(steps.length - 1);
  };

  const handleSkipButtonDisable = () => {
    if (activeStep === steps.length - 1 || validateAllFields(state)) {
      return true;
    }
  };

  // ********** END OF EVENT HANDLERS ********** //

  // ********** VALIDATORS AND HELPER FUNCTIONS *********** //
  const validateProperty = ({ name, value }) => {
    let obj = { [name]: value };
    let schema = Joi.object({
      [name]: getValidationSchema(activeStep).extract(name),
    });
    if (name === "raised") {
      obj = { [name]: value, goal: parseFloat(state.goal) };
      schema = Joi.object({
        [name]: getValidationSchema(activeStep).extract(name),
        goal: getValidationSchema(activeStep).extract("goal"),
      });
    }
    if (name === "goal") {
      obj = { [name]: value, raised: parseFloat(state.raised) };
      schema = Joi.object({
        [name]: getValidationSchema(activeStep).extract(name),
        raised: getValidationSchema(activeStep).extract("raised"),
      });
    }
    if (name === "goal") {
      const { error } = schema.validate(obj, {
        convert: true,
        abortEarly: false,
      });
      return error ? error.details : null;
    }

    let { error } = schema.validate(obj, { convert: true });
    if (error && error.details[0].message === '"Raised" must be a number')
      return "Raised must be a number";
    if (error && error.details[0].message === '"Goal" must be a number')
      return null;
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  const validateEditor = (name, value) => {
    const obj = { [name]: value };
    const schema = Joi.object({
      [name]: getValidationSchema(activeStep).extract(name),
    });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FirstStep
            currencies={currencies}
            renderInput={renderInput}
            renderArabicInput={renderArabicInput}
            renderSelect={renderSelect}
            renderArabicSelect={renderArabicSelect}
            renderCheckbox={renderCheckbox}
            subhubOptions={subhubOptions}
          />
        );
      case 1:
        return <SecondStep renderEditor={renderEditor} />;
      case 2:
        return (
          <ThirdStep
            file={state.file}
            files={state.files}
            projectImages={state.projectImages}
            handleChangeImage={handleMainImageChange}
            handleDropzoneChange={handleDropzoneChange}
            deleteImage={handleDeleteImage}
          />
        );
      case 3:
        return (
          <FourthStep
            renderInput={renderInput}
            renderArabicInput={renderArabicInput}
            onMapChange={handleMapChange}
            latlng={[
              parseFloat(state.locationLat) || 0,
              parseFloat(state.locationLng) || 0,
            ]}
          />
        );
      case 4:
        return (
          <div className="card-body m-3 shadow p-3 bg-white rounded">
            <FifthStep
              renderEditor={renderEditor}
              onImageChange={handleStepImageChange}
              onStepVideoChange={handleStepVideoChange}
              onStepSave={handleStepSubmit}
              disableStepSave={handleStepButtonDisable}
              steps={projectSteps}
              files={state.stepImageFiles}
              video={state.stepVideo}
            />
            <ProjectsSteps
              steps={projectSteps}
              onDelete={handleStepDelete}
              onStepChange={handleStepUpdate}
              onStepUpdate={fetchProjectSteps}
            />
          </div>
        );
      case 5:
        return <Review data={state} />;
      default:
        return "Unknown step";
    }
  }

  const readFile = async (file) => {
    const data = { ...state };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    setState(data);
  };

  const stepImagesToBase64 = async (file) => {
    const data = { ...state };
    const base64 = await fileToBase64(file);
    data.stepBase64Images.push(base64);
    setState(data);
  };
  // ********** END OF VALIDATORS AND HELPER FUNCTIONS *********** //

  if (error) {
    return <NotFound />;
  }
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getStepContent(activeStep)}
      <div className={classes.navButtons}>
        <Button
          variant="contained"
          disabled={activeStep <= 0}
          onClick={handleBack}
          className={classes.button}
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNext}
          disabled={handleButtonDisable()}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? "Submit" : "Next"}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSkipToLast}
          disabled={handleSkipButtonDisable()}
          className={classes.button}
        >
          Last
        </Button>
      </div>
    </div>
  );
}
