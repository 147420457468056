import React from "react";
import Dropzone from "react-dropzone-uploader";
import PropTypes from "prop-types";

// IMAGE UPLOAD
const UploadZone = ({ onChange, placeholder }) => {
  return (
    <Dropzone
      onChangeStatus={onChange}
      accept="image/*"
      inputContent={placeholder}
      inputWithFilesContent="Add"
      styles={{
        dropzone: {
          marginTop: "10px",
          overflow: "auto",
          border: "5px solid #eee",
          height: "100px",
        },
        dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
        inputLabel: { fontSize: "1rem", color: "#01a553" },
        preview: { width: "100%" },
      }}
    />
  );
};

/**
 * Upload Image files
 */
export const Upload = ({ onChange, placeholder, files }) => {
  return (
    <Dropzone
      initialFiles={files}
      onChangeStatus={onChange}
      accept="image/*"
      inputWithFilesContent="Add"
      inputContent={(files, extra) =>
        extra.reject ? "Only Image files!" : placeholder
      }
      styles={{
        dropzone: {
          marginTop: "10px",
          overflow: "auto",
          padding: "5px",
          height: "100px",
        },
        dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
        inputLabel: { fontSize: "1rem", color: "#01a553" },
        preview: { width: "100%" },
      }}
    />
  );
};

// VIDEO UPLOAD
export const VideoUpload = ({ onChange, placeholder, file }) => {
  return (
    <Dropzone
      initialFiles={file}
      onChangeStatus={onChange}
      accept="video/webm, video/mp4, video/ogv"
      inputContent={placeholder}
      maxFiles="1"
      inputWithFilesContent="Add"
      styles={{
        dropzone: {
          marginTop: "10px",
          overflow: "auto",
          padding: "5px",
          height: "100px",
        },
        dropzoneReject: {
          borderColor: "red",
          backgroundColor: "#DAA",
        },
        inputLabel: { fontSize: "1rem", color: "#01a553" },
        preview: { width: "100%" },
      }}
    />
  );
};

Upload.propTypes = {
  /** Change Event Handler */
  onChange: PropTypes.func.isRequired,
  /** Field Placeholder */
  placeholder: PropTypes.string.isRequired,
  /** initial files */
  files: PropTypes.array,
};
export default UploadZone;
