import React from "react";
import { getMember, saveMember } from "../../services/memberService";
import {
  countries,
  bloodTypes,
  ageGroup,
  educationLevels,
  sadagaat,
  voulenteeringTimes,
  booleanOptions,
  yesAndNoOptions,
  communityAndCountry,
  genders, states, lastContribution, jobTitle,
} from "../../utils/selectOptions";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";

class MemberForm extends Form {
  state = {
    sendingData: false,
    data: {
      id: "",
      name: "",
      gender: "",
      dateOfBirth: "",
      phoneNumber: "",
      whatsPhone: "",
      email: "",
      bloodGroup: "",
      liveInSudan: "",
      hasCommunityInCountry: "",
      educationLevel: "",
      studyField: "",
      job: "",
      jobTitle: "",
      state: "",
      city: "",
      locality: "",
      neighborhood: "",
      university: "",
      volunteeredBefore: "",
      volunteeredProjects: "",
      heardAboutSadagaat: "",
      volunteerTime: "",
      volunteeredPeriod: "",
      lastContribution: "",
      receiveEmails: "",
      note: "",
    },
    errors: {},
  };

  // schema = {
  //   id: Joi.label("Id"),
  //   name: Joi.string().trim().required().label("Name"),
  //   gender: Joi.string().trim().required().label("Gender"),
  //   dateOfBirth: Joi.label("Date of birth"),
  //   phoneNumber: Joi.string().required().trim().min(10).max(13).label("Number"),
  //   secondPhoneNumber: Joi.string()
  //     .allow(["", " ", "  ", "   ", "    "])
  //     .trim()
  //     .min(10)
  //     .max(13)
  //     .label("Whatsapp Phone Number"),
  //   email: Joi.string()
  //     .trim()
  //     .email({ minDomainAtoms: 2 })
  //     .required()
  //     .label("Email"),
  //   bloodGroup: Joi.label("Blood Group"),
  //   educationLevel: Joi.label("Education Level"),
  //   studyField: Joi.label("Study Field"),
  //   university: Joi.label("University"),
  //   job: Joi.label("Job"),
  //   jobTitle: Joi.string().trim().label("Job Title"),
  //   state: Joi.label("State"),
  //   city: Joi.label("City"),
  //   volunteeredBefore: Joi.label("Volunteered Before"),
  //   heardAboutSadagaat: Joi.label("Heard About Sadagaat"),
  //   volunteerTime: Joi.label("Volunteer Time"),
  //   countryProjects: Joi.label("Country Projects"),
  //   volunteeredPeriod: Joi.label("Volunteering Period"),
  //   volunteeredProjects: Joi.label("volunteered Projects"),
  //   receiveEmails: Joi.label("Receive Emails"),
  // };

  schema = {
    id: Joi.label("Id"),
    name: Joi.string().trim().required().label("Name"),
    gender: Joi.string().trim().required().label("Gender"),
    dateOfBirth: Joi.label("Date of birth"),
    phoneNumber: Joi.string().required().trim().min(10).max(13).label("Phone Number"),
    whatsPhone: Joi.string()
        .trim()
        .min(10)
        .max(13)
        .label("Whatsapp Phone Number"),
    email: Joi.string()
        .trim()
        .email({ minDomainAtoms: 2 })
        .required()
        .label("Email"),
    bloodGroup: Joi.label("Blood Group"),
    liveInSudan: Joi.boolean().label("Lives in Sudan?"),
    hasCommunityInCountry: Joi.label("Does Sadagaat have a community in your country ?"),
    educationLevel: Joi.label("Education Level"),
    studyField: Joi.label("Study Field"),
    university: Joi.label("University"),
    job: Joi.boolean().label("Job"),
    jobTitle: Joi.label("Job Title"),
    state: Joi.label("State"),
    city: Joi.label("City"),
    locality: Joi.label("Locality"),
    neighborhood: Joi.label("Neighborhood"),
    volunteeredBefore: Joi.label("Did you volunteer in Sadagaat charity organization before ?"),
    volunteeredProjects: Joi.label("What Project's have you participated in with sadagaat ?"),
    heardAboutSadagaat: Joi.label("From where did you hear about Sadagaat ?"),
    volunteerTime: Joi.label("What is the suitable time for you to volunteer in Sadagaat ?"),
    volunteeredPeriod: Joi.label("When was your last contribute in Sadagaat voluntary work?"),
    lastContribution: Joi.label("When was your last contribute in Sadagaat voluntary work?"),
    receiveEmails: Joi.label("Would you like us to send a newsletter about Sadagaat work in your email ?"),
    note: Joi.label("Note"),
  }

  async populateMember() {
    try {
      const memberId = this.props.match.params.id;
      if (memberId === "new") return;
      const { data: member } = await getMember(memberId);
      console.log(member);
      this.setState({ data: this.mapToViewModel(member) });
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/members");
      toast.warn("No Such Member !");
    }
  }

  async componentDidMount() {
    await this.populateMember();
  }

  mapToViewModel(member) {
    const date = new Date(member.dateOfBirth).toLocaleDateString(undefined, {
      year: 'numeric', month: '2-digit', day: '2-digit'
    }).split("/");
    return {
      id: member.id ?? "",
      name: member.name ?? "",
      gender: member.gender ?? "",
      dateOfBirth: member.dateOfBirth ? `${date[2]}-${date[0]}-${date[1]}` : "",
      phoneNumber: member.phoneNumber ?? "",
      whatsPhone: member.whatsPhone ?? "",
      email: member.email ?? "",
      bloodGroup: member.bloodGroup ?? "",
      liveInSudan: member.liveInSudan ?? "",
      hasCommunityInCountry: member.hasCommunityInCountry ?? "",
      educationLevel: member.educationLevel ?? "",
      studyField: member.studyField ?? "",
      job: member.job ?? "",
      jobTitle: member.jobTitle ?? "",
      state: member.state ?? "",
      city: member.city ?? "",
      locality: member.locality ?? "",
      neighborhood: member.neighborhood ?? "",
      university: member.university ?? "",
      volunteeredBefore: member.volunteeredBefore ?? "",
      volunteeredProjects: member.volunteeredProjects ?? "",
      heardAboutSadagaat: member.heardAboutSadagaat ?? "",
      volunteerTime: member.volunteerTime ?? "",
      volunteeredPeriod: member.volunteeredPeriod ?? "",
      lastContribution: member.lastContribution ?? "",
      receiveEmails: member.receiveEmails ?? "",
      note: member.note ?? "",
    };
  }

  doSubmit = async () => {
    try {
      await saveMember(this.state.data);
      toast.success("Saved");
      setTimeout(function () {
        window.location.replace("/members");
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        toast.error("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Unexpected Error");
      }
    }
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("name", "Name", "text", true)}
              {this.renderSelect("gender", "Gender", genders, true)}
              {this.renderInput("email", "Email", "text", true)}
              {this.renderInput("phoneNumber", "Phone Number", "number", true)}

              {this.state.data.phoneNumber &&
                this.renderInput(
                  "whatsPhone",
                  "Whatsapp Phone Number",
                  "number"
                )}
              {this.renderInput("dateOfBirth", "Date of birth", "date")}

              {this.renderSelect("bloodGroup", "Blood Group", bloodTypes)}
              {this.renderSelect(
                "educationLevel",
                "Education Level",
                educationLevels
              )}
              {this.renderInput("studyField", "Study Field")}
              {this.renderInput("university", "University")}
              {this.renderSelect("job", "Are you working currently ?", booleanOptions)}
              {this.state.data.job === "true" &&
                  this.renderSelect("jobTitle", "What's your job", jobTitle)}
              {this.renderSelect(
                  "receiveEmails",
                  "Would you like us to send a newsletter about Sadagaat work in your email ?",
                  booleanOptions
              )}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderSelect("liveInSudan", "Lives in Sudan?", booleanOptions)}
              {this.renderSelect("state", "State", states)}
              {this.renderInput("city", "City")}
              {this.renderInput("locality", "Locality", "text")}
              {this.renderInput("neighborhoud", "Neighborhood", "text")}
              {this.renderSelect(
                "volunteeredBefore",
                "Did you volunteer in Sadagaat charity organization before ?",
                booleanOptions
              )}
              {this.state.data.volunteeredBefore === "true" &&
                  <>
                    {this.renderInput(
                        "volunteeredProjects",
                        "What Project's have you participated in with sadagaat ?"
                    )}
                    {this.renderInput("volunteeredPeriod", "Since when ?")}
                    {this.renderSelect(
                        "lastContribution",
                        "When was your last contribute in Sadagaat voluntary work?", lastContribution)}
                  </>}
              {this.renderSelect(
                "heardAboutSadagaat",
                "From where did you hear about Sadagaat ?",
                sadagaat
              )}
              {this.renderSelect(
                "volunteerTime",
                "What is the suitable time for you to volunteer in Sadagaat ?",
                voulenteeringTimes
              )}
              {this.renderSelect(
                "hasCommunityInCountry",
                "Does Sadagaat have a community in your country ?",
                booleanOptions
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-xs-12">
              {this.renderTextArea("note", "Note")}
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default MemberForm;
