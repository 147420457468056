/* THIS FILE WAS GENERATED TO SET UP A LOGGING SERVICE TO LOG CLIENT SIDE EXCEPTIONS TO A REMOTE SERVER */

function init() {}

function log(error) {
  console.error(error);
}

export default {
  init,
  log,
};
