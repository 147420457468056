import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "../common/input";
import {
  Button,
  Card,
  CardHeader,
  Typography,
  CardContent,
  Paper,
  Link,
} from "@material-ui/core";
import Volunteers from "@material-ui/icons/AccessibilityNew";
import Donors from "@material-ui/icons/AttachMoney";
import { getTotalDonorsNumber } from "../../services/donationService";
import { getTotalMembersNumber } from "../../services/memberService";
import { toast } from "react-toastify";

const classes = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    height: "100px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  coverBlue: {
    width: 50,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#3f51b5",
  },
  coverGreen: {
    width: 50,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#01a553",
  },
  searchBox: {},
  linkContainer: {
    marginTop: "20px",
  },
  textCenter: {
    textAlign: "center",
  },
};

/**
 * Render Dashboard's Home Page
 * @component
 */
class Home extends Component {
  state = {
    data: {
      startDate: "",
      endDate: "",
    },
    errors: {},
    volunteers: 0,
    donors: 0,
  };

  schema = {
    startDate: Joi.date()
      .required()
      .max("now")
      .label("Start Date")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.max":
              err.message = "today or earlier";
              break;
            case "date.base":
              err.message = "Invalid date";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    endDate: Joi.date()
      .min(Joi.ref("startDate"))
      .required()
      .label("End Date")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.min":
              err.message = "greater than or equal to start date";
              break;
            case "date.base":
              err.message = "Invalid date";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  };

  async componentDidMount() {
    try {
      const { data: donors } = await getTotalDonorsNumber();
      const { data: volunteers } = await getTotalMembersNumber();
      this.setState({ ...this.state, volunteers, donors });
    } catch (ex) {
      toast.error("something went wrong");
    }
  }

  renderInput(name, label, type, required) {
    const { data, errors } = this.state;
    if (type === "date") {
      return (
        <Input
          border="none"
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          max="9999-12-31"
          required={required}
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
      />
    );
  }

  renderButton(label) {
    return (
      <div className="text-center">
        <button disabled={this.validate()} className="btn btn-outline-success">
          {label}
        </button>
      </div>
    );
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const res = this.validateProperty(input);
    if (input.name === "startDate") {
      if (res) {
        if (res.length === 1) {
          if (res[0].path[0] === "startDate") {
            errors[input.name] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "date.base") {
              delete errors["startDate"];
            }
          }
          if (res[0].path[0] === "endDate") {
            errors["endDate"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "date.base") {
              delete errors["endDate"];
            }
            delete errors[input.name];
          }
        }
        if (res.length === 2) {
          if (res[0].path[0] === "startDate") {
            errors[input.name] = res[0].message.replace(/[""]/g, "");
            errors["endDate"] = res[1].message.replace(/[""]/g, "");
            if (res[1].type === "date.base") {
              delete errors["endDate"];
            }
          }
          if (res[0].path[0] === "endDate") {
            errors[input.name] = res[1].message.replace(/[""]/g, "");
            errors["endDate"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "date.base") {
              delete errors["endDate"];
            }
          }
        }
      } else {
        delete errors[input.name];
        delete errors["endDate"];
      }

      const data = { ...this.state.data };
      data[input.name] = input.value;
      this.setState({ data, errors });
    }

    if (input.name !== "startDate") {
      if (res === "End Date references startDate which is not a date") {
        delete errors[input.name];
      } else if (res) errors[input.name] = res;
      else delete errors[input.name];

      const data = { ...this.state.data };
      data[input.name] = input.value;
      this.setState({ data, errors });
    }
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    let obj = { [name]: value };
    let schema = { [name]: this.schema[name] };
    if (name === "startDate") {
      obj = { [name]: value, endDate: this.state.data.endDate };
      schema = { [name]: this.schema[name], endDate: this.schema["endDate"] };
    }
    if (name === "endDate") {
      obj = { [name]: value, startDate: this.state.data.startDate };
      schema = {
        [name]: this.schema[name],
        startDate: this.schema["startDate"],
      };
    }

    if (name === "startDate") {
      const { error } = Joi.validate(obj, schema, {
        convert: true,
        abortEarly: false,
      });
      return error ? error.details : null;
    }

    let { error } = Joi.validate(obj, schema, { convert: true });
    if (error) {
      if (error.details[0].type === "date.min") {
        error.details[0].message = "greater than or equal to start date";
      }
    }
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  handleReset = async () => {
    this.setState({
      ...this.state,
      errors: {},
      data: { ...this.state.data, startDate: "", endDate: "" },
    });
    const { data: donors } = await getTotalDonorsNumber();
    const { data: volunteers } = await getTotalMembersNumber();
    this.setState({ ...this.state, donors, volunteers });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.setState({ sendingData: true });
    this.doSubmit();
  };

  doSubmit = async () => {
    try {
      let { startDate, endDate } = this.state.data;
      startDate = startDate.replace(/-/g, "/");
      endDate = endDate.replace(/-/g, "/");
      const { data: donors } = await getTotalDonorsNumber(startDate, endDate);
      const { data: volunteers } = await getTotalMembersNumber(
        startDate,
        endDate
      );
      this.setState({ ...this.state, volunteers, donors });
    } catch (ex) {
      toast.error("something went wrong");
    }
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit} style={{ minHeight: "125px" }}>
          <div className="row">
            <div className="col-sm-12 col-lg-3 col-md-3 col-xl-3 col-xs-12">
              {this.renderInput("startDate", "Start Date", "date")}
            </div>
            <div className="col-sm-12 col-lg-3 col-md-3 col-xl-3 col-xs-12">
              {this.renderInput("endDate", "End Date", "date")}
            </div>
            <div className="col-sm-12 col-lg-3 col-md-3 col-xl-3 col-xs-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "90px",
                  width: "100%",
                }}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={this.validate() ? true : false}
                  onClick={() => this.validate()}
                >
                  Filter
                </Button>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 col-md-3 col-xl-3 col-xs-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "90px",
                  width: "100%",
                }}
              >
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => this.handleReset()}
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <Card style={classes.card}>
              <div style={classes.coverBlue}>
                <Volunteers />
              </div>
              <CardHeader style={classes.textCenter} title="Volunteers" />
              <div style={classes.details}>
                <CardContent style={classes.content}>
                  <Typography component="h5" variant="h5">
                    {this.state.volunteers}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <Card style={classes.card}>
              <div style={classes.coverGreen}>
                <Donors />
              </div>
              <CardHeader style={classes.textCenter} title="Donors" />
              <div style={classes.details}>
                <CardContent style={classes.content}>
                  <Typography component="h5" variant="h5">
                    {this.state.donors}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </div>
        </div>
        <div className="row" style={classes.linkContainer}>
          <div className="col-12">
            <Paper
              style={{
                ...classes.textCenter,
                padding: "10px",
              }}
            >
              <Typography component="h6" variant="h6">
                To know about the number of visitors <br />
                and the website activity visit <br />
                <Link
                  style={{ textDecoration: "none" }}
                  color="primary"
                  rel="noopener"
                  target="_blank"
                  href="https://analytics.google.com/analytics/web"
                >
                  google analytics
                </Link>
              </Typography>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
