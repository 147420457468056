import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getHubs, deleteHub, approveHub } from "../../services/hubServices";
import { toast } from "react-toastify";
import HubsTable from "./hubsTable";
import Loader from "../common/loader";

/**
 * Fetch Hubs from API and Display Hubs table, Add new Hub
 * @component
 */
class Hubs extends Component {
  state = { hubs: [], loading: true };

  async componentDidMount() {
    try {
      let { data: hubs } = await getHubs();
      hubs.forEach((hub) => {
        hub.hubTranslations[0] = hub.hubTranslations.find(
          (t) => t.locale === "ar"
        );
      });
      this.setState({ hubs, loading: false });
    } catch (error) {
      toast.error("Could not fetch hubs from server");
    }
  }

  handleHubsApproval = async (hub) => {
    const originalHubs = this.state.hubs;
    const hubs = [...this.state.hubs];
    const index = hubs.findIndex((n) => n.id === hub.id);
    hubs[index] = {
      ...hubs[index],
      approvedByAdmin: !hubs[index].approvedByAdmin,
    };
    this.setState({ hubs });
    try {
      await approveHub(hub);
      toast.success("Approval Changed");
    } catch (error) {
      toast.error("Could Not Change Approval");
      this.setState({ hubs: originalHubs });
    }
  };

  // handle Hubs Delete
  handleHubsDelete = async (hub) => {
    const originalHubs = this.state.hubs;
    const hubs = originalHubs.filter((n) => n.id !== hub.id);
    this.setState({ hubs });

    try {
      const res = await deleteHub(hub.id);
      if (res) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This contact has already been deleted.");
      this.setState({ hubs: originalHubs });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Link
          to="/hubs/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{ marginBottom: 20 }}
        >
          New Hub
        </Link>
        <HubsTable
          hubs={this.state.hubs}
          onDelete={this.handleHubsDelete}
          approveHub={this.handleHubsApproval}
        />
      </>
    );
  }
}

export default Hubs;
