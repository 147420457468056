import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

/**
 * An input Field that works as a search component
 * @component
 */
const SearchBox = ({ value, onChange, placeholder }) => {
  return (
    <div className="p-3 bg-white">
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth
        type="text"
        name="query"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </div>
  );
};

export default SearchBox;

SearchBox.propTypes = {
  /** The current value of the search field */
  value: PropTypes.string.isRequired,
  /** Placeholder text to be displayed when the field has no value */
  placeholder: PropTypes.string.isRequired,
  /** Change handler fired when the field value changes */
  onChange: PropTypes.func.isRequired,
};
