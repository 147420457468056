import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";

/**
 * Render a styled CheckBox
 */
export default function CheckBox({ checked, name, label, onChange }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={onChange}
          color="primary"
        />
      }
      label={label}
    />
  );
}

CheckBox.propTypes = {
  /** checkbox state  */
  checked: PropTypes.bool.isRequired,
  /** checkbox name */
  name: PropTypes.string.isRequired,
  /** checkbox label */
  label: PropTypes.string.isRequired,
  /** function to execute upon change */
  onChange: PropTypes.func.isRequired,
};
