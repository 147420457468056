import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { getCandidates } from "../../services/candidateService";

function Candidates() {
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        async function fetchCandidates() {
            let page = 0, pageSize = 10;
            let data = [];
            let content = await getCandidates(page, pageSize);
            while (content.data.length > 0)
            {
                data = data.concat(content.data.map(d => {
                    const [country, city] = d.location.split('#@@#');
                    const trainingType = d.trainingType.split('_').join(" ").toLowerCase();
                    return { ...d, country, city, trainingType: `${trainingType[0].toUpperCase()}${trainingType.slice(1)}` };
                }));

                content = await getCandidates(page, pageSize);
                page++;
            }
            setCandidates(data);
        }

        fetchCandidates();
    }, []);

    return (
        <>
            <MaterialTable
                title="Candidates Table"
                data={candidates}
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Phone Number", field: "phoneNumber", },
                    { title: "Email", field: "email", },
                    { title: "Gender", field: "gender", },
                    { title: "Current Location", field: "country" },
                    { title: "City", field: "city" },
                    { title: "Nationality", field: "nationality", },
                    { title: "Interest Area", field: "interestArea", },
                    { title: "IT Background", field: "iTBackground", },
                    { title: "Reasoning", field: "reasoning", },
                    { title: "Course", field: "trainingType", },
                    { title: "Laptop", field: "hasDevice", type: "boolean" },
                    { title: "Internet Access", field: "hasInternet", type: "boolean" },
                    { title: "Comment", field: "comment", type: "string" }
                ]}
                options={{
                    headerStyle: {
                        backgroundColor: "#01a553",
                    },
                    grouping: true,
                    searchFieldStyle: {
                        backgroundColor: "#01a553",
                        padding: "0 5px",
                        borderRadius: "10px",
                    },
                    exportAllData: true,
                    exportButton: { csv: true, pdf: false }
                }}
            />
        </>
    )
}

export default Candidates;