import React from "react";
import { savehHub } from "./../../services/hubServices";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";
import { fileToBase64 } from "../../utils/filereader";

class HubsForm extends Form {
  state = {
    sendingData: false,
    data: {
      arabicName: "",
      arabicDescription: "",
      arabicFormatedDescription: "",
      englishFormatedDescription: "",
      englishName: "",
      englishDescription: "",
      base64Image: "",
    },
    errors: {},
  };

  schema = {
    arabicDescription: Joi.string()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),

    arabicName: Joi.string()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    englishDescription: Joi.string().trim().required().label("Description"),
    // arabicFormatedDescription: Joi.any().when("englishFormatedDescription", {
    //   is: Joi.string(),
    //   then: Joi.string()
    //     .required()
    //     .label("Arabic Name")
    //     .regex(/[\u0600-\u06FF]/),
    //   otherwise: Joi.label("More information in Arabic"),
    // }),

    // englishFormatedDescription: Joi.any().when("arabicFormatedDescription", {
    //   is: Joi.string(),
    //   then: Joi.string().required().trim().label("More Information"),
    //   otherwise: Joi.label("More Information"),
    // }),

    englishFormatedDescription: Joi.string()
      .allow("")
      .trim()
      .max(2097152)
      .label("More Information")
      .error(() => {
        return {
          message: "Max Size is 2MB",
        }
      }),

    arabicFormatedDescription: Joi.string()
      .allow("")
      .max(2097152)
      .label("More Information in Arabic")
      // .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "Max Size is 2MB",
        }
      }),

    englishName: Joi.string().trim().required().label("Name"),
    base64Image: Joi.string().required().label("Image"),
  };

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        const base64 = await fileToBase64(file);
        this.setState({ data: { ...this.state.data, base64Image: base64 } });
      }
    } catch (error) {
      return;
    }
  };

  doSubmit = async (e) => {
    try {
      await savehHub(this.state.data);
      toast.success("Saved");
      setTimeout(function () {
        window.location.replace("/hubs");
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      }
    }
  };

  toolbar = [
    "heading",
    "|",
    "bold",
    "link",
    "|",
    'alignment',
    "bulletedList",
    "numberedList",
    "|",
    "insertTable",
    "highlight",
    "imageUpload",
    "|",
    "undo",
    "redo",
  ];

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("englishName", "Name", "text", true)}
              {this.renderTextArea("englishDescription", "Description", true)}
              {this.renderEditor(
                "englishFormatedDescription",
                "More information",
                "en",
                false,
                this.toolbar
              )}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderArabicInput(
                "arabicName",
                "Arabic Name",
                "text",
                true
              )}
              {this.renderArabicTextArea(
                "arabicDescription",
                "Arabic Description",
                true
              )}
              {this.renderEditor(
                "arabicFormatedDescription",
                "More information in Arabic",
                { ui: "ar", content: "en" },
                false,
                this.toolbar
              )}
            </div>
          </div>
          <label htmlFor="file" style={{ display: "block", paddingTop: 10 }}>
            Upload Image <span className="text-danger">*</span>
          </label>
          <input
            accept="image/*"
            ref="file"
            type="file"
            name="file"
            className="upload-file btn btn-success"
            onChange={this.handleChangeImage}
            encType="multipart/form-data"
          />
          <br />
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default HubsForm;
