import React from "react";
import { resetPassword } from "../../services/passwordService";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../images/logo.jpg";
import wave from "../images/wave.png";
import auth from "../../services/authService";
import Form from "../common/form";
import Joi from "joi-browser";
import "../styles/login.css";

/**
 * Render Reset Password form to erset user password
 * @component
 */
class ResetPassword extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        confirmPassword: "",
        password: "",
      },
      errors: {},
      token: this.props.match.params.token,
    };
  }

  schema = {
    password: Joi.string()
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/)
      .required()
      .label("Password")
      .error(() => {
        return {
          message:
            "8 characters with (lowercase, uppercase, number and symbol)",
        };
      }),
    confirmPassword: Joi.any()
      .equal(Joi.ref("password"))
      .required()
      .label("Confirm Password")
      .options({ language: { any: { allowOnly: "must match new password" } } }),
  };

  handleConfirm = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleFocus = (event) => {
    event.target.parentNode.parentNode.classList.add("focus");
  };

  handleFocusRemoval = (event) => {
    if (event.target.value === "") {
      event.target.parentNode.parentNode.classList.remove("focus");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { password, token } = this.state;
      await resetPassword(password, token);
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/dashboard";
    } catch (ex) {
      toast.error(ex.response ? ex.response.data : "Something went wrong", {
        position: "top-center",
      });
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/dashboard" />;
    return (
      <>
        <img className="wave" src={wave} alt="" />
        <div className="login-container">
          <div className="img">{/* <img src={background} alt="" /> */}</div>
          <div className="login-content">
            <form
              className="login-form"
              ref={this.form}
              onSubmit={this.handleSubmit}
            >
              <img src={logo} alt="" />
              <div className="input-div one">
                <div className="i">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="div">
                  <h5>Password</h5>
                  <input
                    className="input"
                    name="password"
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocusRemoval}
                    value={this.state.data["password"]}
                    onChange={this.handleChange}
                    error={this.state.errors["password"]}
                    type="password"
                  />
                </div>
              </div>
              {this.state.errors["password"] && (
                <p
                  className="alert alert-light text-danger text-center"
                  style={{ borderRadius: "30px", fontSize: "10px" }}
                >
                  {this.state.errors["password"]}
                </p>
              )}
              <div className="input-div pass">
                <div className="i">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="div">
                  <h5>Confirm Password</h5>
                  <input
                    name="confirmPassword"
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocusRemoval}
                    value={this.state.data["confirmPassword"]}
                    onChange={this.handleChange}
                    error={this.state.errors["confirmPassword"]}
                    type="password"
                    required
                    className="input"
                  />
                </div>
              </div>
              {this.state.errors["confirmPassword"] && this.validate() && (
                <p
                  className="alert alert-light text-danger text-center"
                  style={{ borderRadius: "30px", fontSize: "10px" }}
                >
                  {this.state.errors["confirmPassword"]}
                </p>
              )}
              <input
                type="submit"
                className="submit-button"
                value="Reset"
                disabled={this.validate()}
              />
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default ResetPassword;
