import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

/**
 * Pagination component to display paginated data
 * @component
 * @example return (<Pagination itemsCount={100} pageSize={10}, currentPage={1} onPageChange={()=> {console.log('page changed')}}/>)
 */
const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  let pages = _.range(1, pagesCount + 1);

  // DISPLAY ONLY PAGINATED DATA
  return (
    <nav className="mt-2">
      <ul className="pagination justify-content-center">
        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <button className="page-link " onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  /** The total number of items to be displayed */
  itemsCount: PropTypes.number.isRequired,
  /** Number of items to be displayed in each page */
  pageSize: PropTypes.number.isRequired,
  /** Number of the current page */
  currentPage: PropTypes.number.isRequired,
  /** Handler function for page change */
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
