import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Upload, VideoUpload } from "./dropZone";
import LineGrid from "./lineGrid";
import VideosContainer from "./videoContainer";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * Controls the visibility of a dialog
 * @component
 */
const DeleteDialog = ({ dialogOpen, handleClose, item, handleDelete }) => {
  function deleteFunction() {
    handleClose();
    handleDelete(item);
  }
  return (
    <Dialog
      fullWidth={true}
      open={dialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={500}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Confirm Delete Operation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deleting is permanent and data can not be restored !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button onClick={deleteFunction} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  /** Specifies if the dialog should be displayed or not */
  dialogOpen: PropTypes.bool.isRequired,
  /** Closes the dialog and sets DialogOpen to False */
  handleClose: PropTypes.func.isRequired,
  /** The item id to be deleted */
  item: PropTypes.number.isRequired,
  /** The function to handle the delete operation */
  handleDelete: PropTypes.func.isRequired,
};

/**
 * Display Refund Dialog
 * @component
 * */
export const RefundDialog = ({
  dialogOpen,
  handleClose,
  item,
  handleRevert,
}) => {
  function refundFunction() {
    handleRevert(item);
    handleClose();
  }
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={500}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Reverse Donation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to take back {item.amount} from {item.project}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button onClick={refundFunction} color="secondary">
          Yes !
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RefundDialog.propTypes = {
  /** Specifies if the dialog should be displayed or not */
  dialogOpen: PropTypes.bool.isRequired,
  /** Closes the dialog and sets DialogOpen to False */
  handleClose: PropTypes.func.isRequired,
  /** The item id to be deleted */
  item: PropTypes.number.isRequired,
  /** The function to handle the refund operation */
  handleRevert: PropTypes.func.isRequired,
};

/**
 * Edit Dialog
 * @component
 * */
export const EditStepDialog = (props) => {
  const {
    open,
    images,
    videos,
    renderEditor,
    onImageChange,
    onVideoChange,
    onImageDelete,
    onVideoDelete,
    disableSubmitButton,
    onSubmit,
    onClose,
  } = props;

  function submit() {
    onSubmit();
    onClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        disableEnforceFocus
        fullScreen
      >
        <DialogContent>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {renderEditor("englishDecsription", "Description", "en", true)}
                <Upload
                  onChange={onImageChange}
                  placeholder="Add Step Images"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {renderEditor(
                  "arabicDescription",
                  "Arabic Description",
                  "ar",
                  true
                )}
                <VideoUpload
                  onChange={onVideoChange}
                  placeholder="Upload Video"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <LineGrid
                  images={images}
                  onDelete={onImageDelete}
                  type="steps"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {videos.length > 0 && (
                  <VideosContainer
                    videos={videos}
                    onDelete={onVideoDelete}
                    type="step"
                  />
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={submit}
            disabled={disableSubmitButton()}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditStepDialog.propTypes = {
  /** specifies if the dialog is open or not */
  open: PropTypes.bool.isRequired,
  /** List of the videos to be displayed in the dialog */
  videos: PropTypes.array.isRequired,
  /** List of images to be displayed in the dialog */
  images: PropTypes.array.isRequired,
  /** A function to render a rich text editor with the given parameters */
  renderEditor: PropTypes.func.isRequired,
  /** Handler function when the user changes the step images */
  onImageChange: PropTypes.func.isRequired,
  /** Haandler function when the user changes the step videos */
  onVideoChange: PropTypes.func.isRequired,
  /** Handler function when deleting a step image */
  onImageDelete: PropTypes.func.isRequired,
  /** Handler function when deleting a step video */
  onVideoDelete: PropTypes.func.isRequired,
  /** A function to determine whether the submit button is open or closed */
  disableSubmitButton: PropTypes.func.isRequired,
  /** Handler function when the user clicks the submit button */
  onSubmit: PropTypes.func.isRequired,
  /** Handler function to close the dialog when the user cancels the operation */
  onClose: PropTypes.func.isRequired,
};
/** @component */
export default DeleteDialog;
