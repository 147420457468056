import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import SaveIcon from "@material-ui/icons/EditOutlined";
import { isDataEntry } from "../../utils/checkRole";
import Done from "@material-ui/icons/CheckBoxOutlined";
import Clear from "@material-ui/icons/CheckBoxOutlineBlankOutlined";

function CurrencyTable({ currencies, toggleActive }) {
  const [state, setState] = useState({ edit: false, id: "" });

  const handleRoute = (rowData) => {
    setState({ edit: true, id: rowData.id });
  };

  if (!state.edit) {
    return (
      <MaterialTable
        title="Currencies Table"
        columns={[
          { title: "Name", field: "currencyTranslations[0].name" },
          { title: "Arabic Name", field: "currencyTranslations[1].name" },
        ]}
        data={currencies}
        actions={[
          {
            icon: () => <SaveIcon color="primary" />,
            tooltip: "Edit Currency",
            onClick: (event, rowData) => {
              handleRoute(rowData);
            },
          },
          (rowData) => ({
            hidden: isDataEntry(),
            icon: () => (rowData.active ? <Done /> : <Clear />),
            tooltip: rowData.active ? "Deactivate" : "Activate",
            onClick: (event, rowData) => {
              toggleActive(rowData);
            },
          }),
        ]}
        options={{
          headerStyle: {
            backgroundColor: "#01a553",
          },
          grouping: true,
          searchFieldStyle: {
            backgroundColor: "#01a553",
            padding: "0 5px",
            borderRadius: "10px",
          },
        }}
      />
    );
  }

  if (state.edit === true) {
    return <Redirect to={`/currencies/form/${state.id}`} />;
  }
}

export default CurrencyTable;
