import React from "react";
import Joi from "joi-browser";
import { fileToBase64 } from "../../../utils/filereader";
import { toast } from "react-toastify";
import Form from "../../common/form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Button } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * Render a form dialog to Edit a Slider item
 * @displayName EditSliderItem
 */
export default class Editdialog extends Form {
  state = {
    data: {
      id: "",
      base64Image: "",
      link: undefined,
    },
    errors: {},
  };

  componentDidUpdate(nextProps) {
    const { item } = this.props;
    if (nextProps.item !== item) {
      if (item) {
        this.setState({
          data: { id: item.id, link: item.link ?? undefined, base64Image: "" },
          errors: {},
        });
      }
    }
  }

  schema = {
    id: Joi.label("id"),
    link: Joi.string()
      .regex(
        /(?:http|https):\/\/((?:[\w-]+)(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
      )
      .trim()
      .label("URL")
      .allow('')
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.regex.base":
              err.message = "Enter a valid URL";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    base64Image: Joi.label("Image"),
  };

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        toast.warn("Please select a png/jpeg image");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      }
      if (file.size > 1000000) {
        toast.warn("Maximum allowed size for images is 1 MB");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        const base64 = await fileToBase64(file);
        const data = { ...this.state.data };
        data.base64Image = base64;
        this.setState({ data });
      }
    } catch (error) {
      return;
    }
  };

  edit = () => {
    this.props.onClose();
    this.props.handleEdit(this.state.data);
  };

  doSubmit = () => {
    this.props.handleEdit(this.state.data);
  };

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.dialogOpen}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
        transitionDuration={500}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          Edit Slider Data
        </DialogTitle>
        <DialogContent>
          <form onSubmit={this.props.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <label htmlFor="file" style={{ display: "block" }}>
                  Upload Image
                </label>
                <input
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  name="file"
                  className="upload-file btn btn-success"
                  onChange={this.handleChangeImage}
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderInput("link", "URL", "text", false)}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            onClick={this.edit}
            color="primary"
            disabled={
              !!(this.validate() ||
                  (this.validate() === null && this.state.sendingData === true))
            }
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
Editdialog.displayName = "EditSliderItem";
