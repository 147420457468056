import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/cover-image";

export function getCoverImage(name) {
  return `${apiEndpoint}/${name}`;
}

export async function setCoverImages(covers) {
  const removeFalsy = (obj) => {
    let coversArray = [];
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) {
        coversArray.push({ type: prop, base64Image: obj[prop] });
      }
    });
    return coversArray;
  };

  const data = removeFalsy(covers);
  return await http.post(apiEndpoint, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
