import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

export function getCopyright() {
  return http.get(`${config.globalApi}/copy-right`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
      "accept-language": "en",
    },
  });
}

export function setCopyright({ copyrightAr, copyrightEn }) {
  const updatedCopyright = {
    copyRightTranslation: [
      { text: copyrightEn, locale: "en" },
      { text: copyrightAr, locale: "ar" },
    ],
  };

  return http.post(`${config.globalApi}/copy-right`, updatedCopyright, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
