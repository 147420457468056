import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getMembers, deleteMember } from "../../services/memberService";
import { toast } from "react-toastify";
import MembersTable from "./membersTable.jsx";
import Loader from "../common/loader";

/**
 * Fetch Members from API and Display Members table, Add new Member
 * @component
 */
class Members extends Component {
  state = { members: [], loading: true };

  async componentDidMount() {
    try {
      const { data: members } = await getMembers();
      this.setState({ members, loading: false });
    } catch (error) {
      toast.error("Could not fetch members from the server");
    }
  }

  //handle member deletion
  handleMemberDelete = async (member) => {
    const originalMembers = this.state.members;
    const members = originalMembers.filter((m) => m.id !== member.id);
    this.setState({ members });

    try {
      const res = await deleteMember(member.id);
      if (res) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This member has already been deleted.");

      this.setState({ members: originalMembers });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) return <Loader />;
    return (
      <>
        <Link
          to="/members/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{ marginBottom: 20 }}
        >
          New Member
        </Link>
        <MembersTable
          members={this.state.members}
          onDelete={this.handleMemberDelete}
        />
      </>
    );
  }
}

export default Members;
