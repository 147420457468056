import React, { useEffect, useState } from "react";
import { Upload, VideoUpload } from "../../common/dropZone";
import Typography from "@material-ui/core/Typography";
import LineGrid from "../../common/lineGrid";
import VideosContainer from "../../common/videoContainer";

const ThirdStep = (props) => {
  const {
    handleChangeImage,
    handleDropzoneChange,
    files,
    file,
    deleteImage,
    projectImages,
    video,
    programVideos,
    handleVideoDelete,
    handleVideoChange
  } = props;
  const [initialFiles, setInitialFiles] = useState([]);   

  useEffect(() => {
    setInitialFiles(files);
  }, []);

  return (
    <>
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <div className='row'>
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <label
              htmlFor="file"
              style={{
                display: "block",
                marginTop: "3px",
              }}
            >
              Change Main Image
            </label>
            <input
              id="file"
              accept="image/*"
              type="file"
              name="base64Image"
              className="btn btn-success mb-2"
              onChange={handleChangeImage}
              encType="multipart/form-data"
            />
            <Typography variant="caption" display="block" gutterBottom>
              {file ? file.name : ""}
            </Typography>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <Upload
              onChange={handleDropzoneChange}
              files={initialFiles}
              placeholder="Add or drop program images"
            />
            <LineGrid
            images={projectImages}
            onDelete={deleteImage}
            type="programs"
            />
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          <VideoUpload
                  onChange={handleVideoChange}
                  file={video ? [video] : [] }
                  placeholder="Upload Video"
                />
          {programVideos && (
                  <VideosContainer
                    videos={programVideos}
                    type="programs"
                    onDelete={handleVideoDelete}
                  />
                )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdStep;
