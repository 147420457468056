import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/roles";

export function getAllRoles() {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getPortalRoles() {
  return http.get(apiEndpoint + "/portal", {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
