import React from "react";
import RingLoader from "react-spinners/RingLoader";

/** 
 * this is the loading spinner for fetching data,
 * Can be changed by choosing a different component from 
 * the react-spinners library 

 * @component
 * @example return (<Loader/>)
*/

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "88vh",
      }}
    >
      <RingLoader size={180} color={"#006991"} />
    </div>
  );
};

export default Loader;
