import React, { useEffect, useState } from "react";
import { getSubhub } from "../../services/subhubsServices";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Progress from "../common/progress";
import parse from "html-react-parser";
import { Table } from "reactstrap";
import { getCurrency } from "../../services/currencyService";

const Review = ({ data }) => {
  const [subhub, setSubhub] = useState("subhub name");
  const [currency, setCurrency] = useState("Currency Name");

  const fetchSubhub = async () => {
    try {
      const { data: subhub } = await getSubhub(data.subHubId);
      setSubhub(subhub.name);
    } catch (error) {
      setSubhub("Subhub Name");
    }
  };

  const fetchCurrency = async () => {
    try {
      const { data: currency } = await getCurrency(data.currencyId);
      setCurrency(
        currency.currencyTranslations.find((t) => t.locale === "en").name
      );
    } catch (error) {
      setCurrency(" Currency Name ");
    }
  };

  const statusMapping = (status) => {
    switch (status) {
      case "completed":
        return "مكتمل";
      case "ongoing":
        return "قيد العمل";
      case "planned":
        return "مخطط";
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchSubhub();
    fetchCurrency();
  }, []);

  return (
    <>
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <Table borderless>
              <tbody>
                <tr>
                  <td>
                    <b>Name</b>
                  </td>
                  <td>{data.nameEnglish}</td>
                </tr>
                <tr>
                  <td>
                    <b>Status</b>
                  </td>
                  <td>{data.statusEnglish}</td>
                </tr>
                <tr>
                  <td>
                    <b>Raised</b>
                  </td>
                  <td>{data.raised}</td>
                </tr>
                <tr>
                  <td>
                    <b>location</b>
                  </td>
                  <td>{data.locationNameEnglish}</td>
                </tr>
                <tr>
                  <td>
                    <b>Subhub</b>
                  </td>
                  <td>{subhub}</td>
                </tr>
                <tr>
                  <td>
                    <b>Date</b>
                  </td>
                  <td>{data.startAt}</td>
                </tr>

                <tr>
                  <td>
                    <b>Pinned To Home</b>
                  </td>
                  <td>{data.pinToHome ? "Yes" : "No"}</td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="description"
                        id="description"
                      >
                        <Typography>
                          <b>Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {parse(data.descriptionEnglish)}
                      </AccordionDetails>
                    </Accordion>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <Table borderless>
              <tbody>
                <tr>
                  <td>
                    <b>Arabic Name</b>
                  </td>
                  <td>{data.nameArabic}</td>
                </tr>
                <tr>
                  <td>
                    <b>Arabic Status</b>
                  </td>
                  <td>{statusMapping(data.statusArabic)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Goal</b>
                  </td>
                  <td>{data.goal}</td>
                </tr>
                <tr>
                  <td>
                    <b>Arabic location</b>
                  </td>
                  <td>{data.locationNameArabic}</td>
                </tr>
                <tr>
                  <td>
                    <b>Project Progress</b>
                  </td>
                  <td>{data.projectProgress}</td>
                </tr>
                <tr>
                  <td>
                    <b>Project Currency</b>
                  </td>
                  <td>{currency}</td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="description"
                        id="description"
                      >
                        <Typography>
                          <b>Arabic Description</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails dir="rtl">
                        {parse(data.descriptionArabic)}
                      </AccordionDetails>
                    </Accordion>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="m-2">
        {data.percentage === 0 || data.percentage === 100 ? null : (
          <Progress value={parseInt(data.percentage)} label="Uploading Video" />
        )}
      </div>
    </>
  );
};

export default Review;
