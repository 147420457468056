import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";
import progress from "../utils/progress";

const apiEndpoint = config.globalApi + "/news";

function newsUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getNews() {
  return http.get(apiEndpoint, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

function approveNewsUrl(id) {
  return `${apiEndpoint}/approve/${id}`;
}

export function getNew(newsId) {
  return http.get(newsUrl(newsId), {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveNews(news, file, onPercentageChange) {
  const newNews = {
    base64Image: news.base64Image,
    base64Images: news.base64Images,
    expireDate: news.expireDate,
    newsTranslations: [
      {
        locale: "en",
        name: news.name,
        description: news.description,
      },
      {
        locale: "ar",
        name: news.arabicName,
        description: news.arabicDescription,
      },
    ],
  };

  const details = JSON.stringify(newNews);
  const blob = new Blob([details], {
    type: "application/json",
  });

  const formData = new FormData();
  formData.append("file", file);
  formData.append("news", blob);

  if (news.id) {
    delete newNews.base64Images;
    const updatedNews = {
      ...newNews,
      newsTranslations: [
        { ...newNews.newsTranslations[0], id: news.englishId },
        { ...newNews.newsTranslations[1], id: news.arabicId },
      ],
    };
    return http.put(newsUrl(news.id), updatedNews, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.post(apiEndpoint, formData, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    });
  }
}

export async function approveNews(news) {
  await http.put(
    approveNewsUrl(news.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export async function updateNewsImages(id, newImages, onPercentageChange) {
  await http.post(
    `${apiEndpoint}/image/add`,
    { id: id, base64Images: newImages },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    }
  );
}

export function addNewsVideos(video, id, onPercentageChange) {
  const data = new FormData();
  data.append("file", video);

  return http.post(`${apiEndpoint}/upload-video/${id}`, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
    onUploadProgress: (progressEvent) =>
      onPercentageChange(progress(progressEvent)),
  });
}

export async function deleteNewsVideo(videos) {
  return videos.forEach((video) => {
    http.post(`${apiEndpoint}/video/delete`, video, {
      headers: {
        Authorization: "Bearer " + getJwt(),
        "Content-Type": "application/json",
      },
    });
  });
}

export function deleteNewsImages(images) {
  return http.post(`${apiEndpoint}/image/delete`, images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteNews(newsId) {
  return http.delete(newsUrl(newsId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
