import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/about-us";

export function getAboutUs() {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getAboutUsImage(name) {
  return http.get(`${apiEndpoint}/${name}/image`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function updateAboutUs(about) {
  const images = [];
  if (about.about1Image) {
    images.push({
      name: "ABOUTUS1",
      base64Image: about.about1Image,
    });
  }

  if (about.about2Image) {
    images.push({
      name: "ABOUTUS2",
      base64Image: about.about2Image,
    });
  }

  if (about.about3Image) {
    images.push({
      name: "ABOUTUS3",
      base64Image: about.about3Image,
    });
  }
  const data = {
    images: images,
    aboutUsTranslation: [
      {
        text: about.englishText,
        vision: about.visionEnglish,
        mission: about.missionEnglish,
        value: about.valueEnglish,
        locale: "en",
      },
      {
        text: about.arabicText,
        vision: about.visionArabic,
        mission: about.missionArabic,
        value: about.valueArabic,
        locale: "ar",
      },
    ],
  };
  return http.post(apiEndpoint, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
