import React from "react";
import { Card, CardImg, CardTitle, CardBody, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Card to display donation amount, Hub or subhub image alongside it's name and a button with a link to donate
 * @component
 */

const DonationCard = ({ path, title, image, amount, btn_text }) => {
  return (
    <div>
      <Card className="mt-4 shadow p-3 bg-white rounded">
        <CardImg top height="100px " width="100px" src={image} alt="" />
        <CardBody>
          <CardTitle>
            <p className="text-center">
              <strong>{title}</strong>
            </p>
          </CardTitle>
          <CardText className="text-center">
            <strong className="text-success">
              {new Intl.NumberFormat("en-GB", {
                notation: "compact",
                compactDisplay: "short",
              }).format(amount)}
            </strong>
          </CardText>
          <div className="text-center">
            <Link to={path} className="btn btn-outline-success">
              {btn_text}
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

DonationCard.propTypes = {
  /** The amount of donation to display */
  amount: PropTypes.number.isRequired,
  /** The path to redirect to on button click */
  path: PropTypes.string.isRequired,
  /** The text to display on the button */
  btn_text: PropTypes.string.isRequired,
  /** The image Url to display */
  image: PropTypes.string.isRequired,
  /** The title of the Hub/Subhub */
  title: PropTypes.string.isRequired,
};

export default DonationCard;
