/* 
List Of Countries 
*/

export const countries = [
  { value: "Afghanistan", name: "Afghanistan" },
  { value: "Albania", name: "Albania" },
  { value: "Algeria", name: "Algeria" },
  { value: "American Samoa", name: "American Samoa" },
  { value: "Andorra", name: "Andorra" },
  { value: "Angola", name: "Angola" },
  { value: "Anguilla", name: "Anguilla" },
  { value: "Antarctica", name: "Antarctica" },
  { value: "Antigua and Barbuda", name: "Antigua and Barbuda" },
  { value: "Argentina", name: "Argentina" },
  { value: "Armenia", name: "Armenia" },
  { value: "Aruba", name: "Aruba" },
  { value: "Australia", name: "Australia" },
  { value: "Austria", name: "Austria" },
  { value: "Azerbaijan", name: "Azerbaijan" },
  { value: "Bahrain", name: "Bahrain" },
  { value: "Bangladesh", name: "Bangladesh" },
  { value: "Barbados", name: "Barbados" },
  { value: "Belarus", name: "Belarus" },
  { value: "Belgium", name: "Belgium" },
  { value: "Belize", name: "Belize" },
  { value: "Benin", name: "Benin" },
  { value: "Bermuda", name: "Bermuda" },
  { value: "Bhutan", name: "Bhutan" },
  { value: "Bolivia", name: "Bolivia" },
  { value: "Bosnia and Herzegovina", name: "Bosnia and Herzegovina" },
  { value: "Botswana", name: "Botswana" },
  { value: "Bouvet Island", name: "Bouvet Island" },
  { value: "Brazil", name: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    name: "British Indian Ocean Territory",
  },
  { value: "Brunei", name: "Brunei" },
  { value: "Bulgaria", name: "Bulgaria" },
  { value: "Burkina Faso", name: "Burkina Faso" },
  { value: "Burundi", name: "Burundi" },
  { value: "Cambodia", name: "Cambodia" },
  { value: "Cameroon", name: "Cameroon" },
  { value: "Canada", name: "Canada" },
  { value: "Cape Verde", name: "Cape Verde" },
  { value: "Cayman Islands", name: "Cayman Islands" },
  { value: "Central African Republic", name: "Central African Republic" },
  { value: "Chad", name: "Chad" },
  { value: "Chile", name: "Chile" },
  { value: "China", name: "China" },
  { value: "Christmas Island", name: "Christmas Island" },
  { value: "Cocos (Keeling) Islands", name: "Cocos (Keeling) Islands" },
  { value: "Colombia", name: "Colombia" },
  { value: "Comoros", name: "Comoros" },
  { value: "Congo", name: "Congo" },
  { value: "Cook Islands", name: "Cook Islands" },
  { value: "Costa Rica", name: "Costa Rica" },
  { value: "Cote d'Ivoire", name: "Cote d'Ivoire" },
  { value: "Croatia", name: "Croatia" },
  { value: "Cuba", name: "Cuba" },
  { value: "Cyprus", name: "Cyprus" },
  { value: "Czech Republic", name: "Czech Republic" },
  {
    value: "Democratic Republic of the Congo",
    name: "Democratic Republic of the Congo",
  },
  { value: "Denmark", name: "Denmark" },
  { value: "Djibouti", name: "Djibouti" },
  { value: "Dominica", name: "Dominica" },
  { value: "Dominican Republic", name: "Dominican Republic" },
  { value: "East Timor", name: "East Timor" },
  { value: "Ecuador", name: "Ecuador" },
  { value: "Egypt", name: "Egypt" },
  { value: "El Salvador", name: "El Salvador" },
  { value: "Equatorial Guinea", name: "Equatorial Guinea" },
  { value: "Eritrea", name: "Eritrea" },
  { value: "Estonia", name: "Estonia" },
  { value: "Ethiopia", name: "Ethiopia" },
  { value: "Faeroe Islands", name: "Faeroe Islands" },
  { value: "Falkland Islands", name: "Falkland Islands" },
  { value: "Fiji", name: "Fiji" },
  { value: "Finland", name: "Finland" },
  {
    value: "Former Yugoslav Republic of Macedonia",
    name: "Former Yugoslav Republic of Macedonia",
  },
  { value: "France", name: "France" },
  { value: "French Guiana", name: "French Guiana" },
  { value: "French Polynesia", name: "French Polynesia" },
  { value: "French Southern Territories", name: "French Southern Territories" },
  { value: "Gabon", name: "Gabon" },
  { value: "Georgia", name: "Georgia" },
  { value: "Germany", name: "Germany" },
  { value: "Ghana", name: "Ghana" },
  { value: "Gibraltar", name: "Gibraltar" },
  { value: "Greece", name: "Greece" },
  { value: "Greenland", name: "Greenland" },
  { value: "Grenada", name: "Grenada" },
  { value: "Guadeloupe", name: "Guadeloupe" },
  { value: "Guam", name: "Guam" },
  { value: "Guatemala", name: "Guatemala" },
  { value: "Guinea", name: "Guinea" },
  { value: "Guinea-Bissau", name: "Guinea-Bissau" },
  { value: "Guyana", name: "Guyana" },
  { value: "Haiti", name: "Haiti" },
  {
    value: "Heard Island and McDonald Islands",
    name: "Heard Island and McDonald Islands",
  },
  { value: "Honduras", name: "Honduras" },
  { value: "Hong Kong", name: "Hong Kong" },
  { value: "Hungary", name: "Hungary" },
  { value: "Iceland", name: "Iceland" },
  { value: "India", name: "India" },
  { value: "Indonesia", name: "Indonesia" },
  { value: "Iran", name: "Iran" },
  { value: "Iraq", name: "Iraq" },
  { value: "Ireland", name: "Ireland" },
  { value: "Israel", name: "Israel" },
  { value: "Italy", name: "Italy" },
  { value: "Jamaica", name: "Jamaica" },
  { value: "Japan", name: "Japan" },
  { value: "Jordan", name: "Jordan" },
  { value: "Kazakhstan", name: "Kazakhstan" },
  { value: "Kenya", name: "Kenya" },
  { value: "Kiribati", name: "Kiribati" },
  { value: "Kuwait", name: "Kuwait" },
  { value: "Kyrgyzstan", name: "Kyrgyzstan" },
  { value: "Laos", name: "Laos" },
  { value: "Latvia", name: "Latvia" },
  { value: "Lebanon", name: "Lebanon" },
  { value: "Lesotho", name: "Lesotho" },
  { value: "Liberia", name: "Liberia" },
  { value: "Libya", name: "Libya" },
  { value: "Liechtenstein", name: "Liechtenstein" },
  { value: "Lithuania", name: "Lithuania" },
  { value: "Luxembourg", name: "Luxembourg" },
  { value: "Macau", name: "Macau" },
  { value: "Madagascar", name: "Madagascar" },
  { value: "Malawi", name: "Malawi" },
  { value: "Malaysia", name: "Malaysia" },
  { value: "Maldives", name: "Maldives" },
  { value: "Mali", name: "Mali" },
  { value: "Malta", name: "Malta" },
  { value: "Marshall Islands", name: "Marshall Islands" },
  { value: "Martinique", name: "Martinique" },
  { value: "Mauritania", name: "Mauritania" },
  { value: "Mauritius", name: "Mauritius" },
  { value: "Mayotte", name: "Mayotte" },
  { value: "Mexico", name: "Mexico" },
  { value: "Micronesia", name: "Micronesia" },
  { value: "Moldova", name: "Moldova" },
  { value: "Monaco", name: "Monaco" },
  { value: "Mongolia", name: "Mongolia" },
  { value: "Montenegro", name: "Montenegro" },
  { value: "Montserrat", name: "Montserrat" },
  { value: "Morocco", name: "Morocco" },
  { value: "Mozambique", name: "Mozambique" },
  { value: "Myanmar", name: "Myanmar" },
  { value: "Namibia", name: "Namibia" },
  { value: "Nauru", name: "Nauru" },
  { value: "Nepal", name: "Nepal" },
  { value: "Netherlands", name: "Netherlands" },
  { value: "Netherlands Antilles", name: "Netherlands Antilles" },
  { value: "New Caledonia", name: "New Caledonia" },
  { value: "New Zealand", name: "New Zealand" },
  { value: "Nicaragua", name: "Nicaragua" },
  { value: "Niger", name: "Niger" },
  { value: "Nigeria", name: "Nigeria" },
  { value: "Niue", name: "Niue" },
  { value: "Norfolk Island", name: "Norfolk Island" },
  { value: "North Korea", name: "North Korea" },
  { value: "Northern Marianas", name: "Northern Marianas" },
  { value: "Norway", name: "Norway" },
  { value: "Oman", name: "Oman" },
  { value: "Pakistan", name: "Pakistan" },
  { value: "Palau", name: "Palau" },
  { value: "Panama", name: "Panama" },
  { value: "Papua New Guinea", name: "Papua New Guinea" },
  { value: "Paraguay", name: "Paraguay" },
  { value: "Peru", name: "Peru" },
  { value: "Philippines", name: "Philippines" },
  { value: "Pitcairn Islands", name: "Pitcairn Islands" },
  { value: "Poland", name: "Poland" },
  { value: "Portugal", name: "Portugal" },
  { value: "Puerto Rico", name: "Puerto Rico" },
  { value: "Qatar", name: "Qatar" },
  { value: "Reunion", name: "Reunion" },
  { value: "Romania", name: "Romania" },
  { value: "Russia", name: "Russia" },
  { value: "Rwanda", name: "Rwanda" },
  { value: "Sqo Tome and Principe", name: "NiuSqo Tome and Principee" },
  { value: "Saint Helena", name: "Saint Helena" },
  { value: "Saint Kitts and Nevis", name: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", name: "Saint Lucia" },
  { value: "Saint Pierre and Miquelon", name: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    name: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", name: "Samoa" },
  { value: "San Marino", name: "San Marino" },
  { value: "Saudi Arabia", name: "Saudi Arabia" },
  { value: "Senegal", name: "Senegal" },
  { value: "Serbia", name: "Serbia" },
  { value: "Seychelles", name: "Seychelles" },
  { value: "Sierra Leone", name: "Niue" },
  { value: "Singapore", name: "Singapore" },
  { value: "Slovakia", name: "Slovakia" },
  { value: "Slovenia", name: "Slovenia" },
  { value: "Solomon Islands", name: "Solomon Islands" },
  { value: "Somalia", name: "Somalia" },
  { value: "South Africa", name: "South Africa" },
  {
    value: "South Georgia and the South Sandwich Islands",
    name: "South Georgia and the South Sandwich Islands",
  },
  { value: "South Korea", name: "South Korea" },
  { value: "South Sudan", name: "South Sudan" },
  { value: "Spain", name: "Spain" },
  { value: "Sri Lanka", name: "Sri Lanka" },
  { value: "Sudan", name: "Sudan" },
  { value: "Suriname", name: "Suriname" },
  { value: "Svalbard and Jan Mayen", name: "Svalbard and Jan Mayen" },
  { value: "Swaziland", name: "Swaziland" },
  { value: "Sweden", name: "Sweden" },
  { value: "Switzerland", name: "Switzerland" },
  { value: "Syria", name: "Syria" },
  { value: "Taiwan", name: "Taiwan" },
  { value: "Tajikistan", name: "Tajikistan" },
  { value: "Tanzania", name: "Tanzania" },
  { value: "Thailand", name: "Thailand" },
  { value: "The Bahamas", name: "The Bahamas" },
  { value: "The Gambia", name: "The Gambia" },
  { value: "Togo", name: "Togo" },
  { value: "Tokelau", name: "Tokelau" },
  { value: "Tonga", name: "Tonga" },
  { value: "Trinidad and Tobago", name: "Trinidad and Tobago" },
  { value: "Turkey", name: "Turkey" },
  { value: "Turkmenistan", name: "Turkmenistan" },
  { value: "Turks and Caicos Islands", name: "Turks and Caicos Islands" },
  { value: "Tuvalu", name: "Tuvalu" },
  { value: "Virgin Islands", name: "Virgin Islands" },
  { value: "Uganda", name: "Uganda" },
  { value: "Ukraine", name: "Ukraine" },
  { value: "United Arab Emirates", name: "United Arab Emirates" },
  { value: "United Kingdom", name: "United Kingdom" },
  { value: "United States", name: "United States" },
  {
    value: "United States Minor Outlying Islands",
    name: "United States Minor Outlying Islands",
  },
  { value: "Uruguay", name: "Uruguay" },
  { value: "Uzbekistan", name: "Uzbekistan" },
  { value: "Vanuatu", name: "Vanuatu" },
  { value: "Vatican City", name: "Vatican City" },
  { value: "Venezuela", name: "Venezuela" },
  { value: "Vietnam", name: "Vietnam" },
  { value: "Wallis and Futuna", name: "Wallis and Futuna" },
  { value: "Western Sahara", name: "Western Sahara" },
  { value: "Yemen", name: "Yemen" },
  { value: "Yugoslavia", name: "Yugoslavia" },
  { value: "Zambia", name: "Zambia" },
  { value: "Zimbabwe", name: "Zimbabwe" },
];

export const states = [
  { value: "Khartoum", name: "Khartoum" },
  { value: "North Kordofan", name: "North Kordofan" },
  { value: "Northern", name: "Northern" },
  { value: "Kassala", name: "Kassala" },
  { value: "Blue Nile", name: "Blue Nile" },
  { value: "North Darfur", name: "North Darfur" },
  { value: "South Darfur", name: "South Darfur" },
  { value: "South Kordofan", name: "South Kordofan" },
  { value: "Al Jazirah", name: "Al Jazirah" },
  { value: "White Nile", name: "White Nile" },
  { value: "River Nile", name: "River Nile" },
  { value: "Red Sea", name: "Red Sea" },
  { value: "Al Qadarif", name: "Al Qadarif" },
  { value: "Sennar", name: "Sennar" },
  { value: "West Darfur", name: "West Darfur" },
  { value: "Central Darfur", name: "Central Darfur" },
  { value: "East Darfur", name: "East Darfur" },
  { value: "West Kordofan", name: "West Kordofan" },
]

export const bloodTypes = [
  { value: "A+", name: "A+" },
  { value: "A-", name: "A-" },
  { value: "B+", name: "B+" },
  { value: "B-", name: "B-" },
  { value: "O+", name: "O+" },
  { value: "O-", name: "O-" },
  { value: "AB+", name: "AB+" },
  { value: "AB-", name: "AB-" },
];

/* 
Age Group
*/

export const jobTitle = [
  {value: "private_sector", name: "Private Sector" },
  {value: "public_sector", name: "Public Sector" },
  {value: "free_business", name: "Free Business" },
]

export const ageGroup = [
  { value: "18 >", name: "18 >" },
  { value: "18-22", name: "18-22" },
  { value: "23–27", name: "23–27" },
  { value: "28–32", name: "28–32" },
  { value: "33–37", name: "33–37" },
  { value: "38–42", name: "38–42" },
  { value: "> 42", name: "> 42" },
];

/* 
Education Levels
*/

export const educationLevels = [
  { value: "Literate", name: "Literate" },
  { value: "Primary", name: "Primary" },
  { value: "Secondary", name: "Secondary" },
  { value: "Graduate", name: "Graduate" },
  { value: "Post Graduate", name: "Post Graduate" },
];

/*
When was your last contribute in Sadagaat voluntary work ?
*/

export const lastContribution = [
  { value: "less_then_year", name: "Less then year" },
  { value: "more_then_year", name: "More then year" },
]

/* 
Where Did You Hear About Sadagaat
*/

export const sadagaat = [
  { value: "social_media", name: "Social Media" },
  { value: "friends_or_family", name: "Friends or Family" },
];

/* 
What Is The Most Appropriate Time For You To Volunteer
*/

export const voulenteeringTimes = [
  { value: "morning", name: "Morning" },
  { value: "evening", name: "Evening" },
];

/* 
True And False Options
*/

export const booleanOptions = [
  { value: true, name: "Yes" },
  { value: false, name: "No" },
];

/* 
Yes And No Questions
*/

export const yesAndNoOptions = [
  { value: "Yes", name: "Yes" },
  { value: "No", name: "No" },
];

/* 
Did Sadagaat Execute Any Projects In Your Country ? 
*/

export const communityAndCountry = [
  { value: "Yes", name: "Yes" },
  { value: "No", name: "No" },
  { value: "I don't know", name: "I don't know" },
];

/* 
Gender
*/

export const genders = [
  { value: "MALE", name: "Male" },
  { value: "FEMALE", name: "Female" },
];
