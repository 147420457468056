import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  useMap,
} from "react-leaflet";

const Map = ({ onMapChange, latlng }) => {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    setPosition(latlng);
  }, [latlng]);

  function LocationMarker() {
    useMapEvents({
      click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]);
        onMapChange(e.latlng.lat, e.latlng.lng);
      },
    });
    return position === null ? null : <Marker position={position}></Marker>;
  }

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-xs-12">
        <MapContainer>
          <ChangeView center={latlng} zoom={7} scrollWheelZoom={true} />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarker />
        </MapContainer>
      </div>
    </div>
  );
};

export default Map;
