import React from "react";
import PropTypes from "prop-types";

const Input = ({ name, label, error, type, required, border, ...rest }) => {
  if (error === "End Date references startDate which is not a date") {
    error = "* Greater than or equal to start date";
  }
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>

      <input
        style={{
          borderRadius: border === "none" ? 0 : "20px",
          border: "solid #029746",
        }}
        {...rest}
        name={name}
        id={name}
        type={type}
        className="form-control"
      />
      {error && (
        <p
          className={
            error === "* Greater than or equal to start date"
              ? "alert alert-light text-primary"
              : "alert alert-light text-danger text-center"
          }
          style={{ borderRadius: "30px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

/**
 * Render an Arabic (rtl) input field
 */
export const ArabicInput = ({
  name,
  label,
  error,
  type,
  required,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <input
        style={{
          borderRadius: "20px",
          border: "solid #029746",
        }}
        dir="rtl"
        {...rest}
        name={name}
        id={name}
        type={type}
        className="form-control"
      />
      {error && (
        <p
          className="alert alert-light text-danger text-center"
          style={{ borderRadius: "30px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

ArabicInput.propTypes = {
  /** input name */
  name: PropTypes.string.isRequired,
  /** input label */
  label: PropTypes.string.isRequired,
  /** error message */
  error: PropTypes.string,
  /** input type */
  type: PropTypes.string.isRequired,
  /** is required */
  required: PropTypes.bool,
  /** rest of props */
  rest: PropTypes.any,
};

export default Input;
