import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";

const FourthStep = ({
  renderInput,
  renderArabicInput,
  onMapChange,
  latlng,
}) => {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    setPosition(latlng);
  }, [latlng]);

  function LocationMarker() {
    useMapEvents({
      click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]);
        onMapChange(e.latlng.lat, e.latlng.lng);
      },
    });
    return position === null ? null : <Marker position={position}></Marker>;
  }
  return (
    <>
      <div className='card-body m-3 shadow p-3 bg-white rounded'>
        <div className='row'>
          <div className='col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12'>
            {renderInput("locationNameEnglish", "Location", "text", true)}
          </div>
          <div className='col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12'>
            {renderArabicInput(
              "locationNameArabic",
              "Arabic Location",
              "text",
              true
            )}
          </div>
        </div>
        <div className='mt-2'>
          <div className='row border border-left-0 border-right-0'>
            <div className='col-sm-12 col-lg-12 col-md-12 col-xl-12 col-xs-12 pt-3'>
              <p className='text-center '>
                Enter Location coordinates <b className='text-danger'>OR </b>
                drop pin on map
              </p>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12'>
              {renderInput("locationLat", "Latitude", "number")}
            </div>
            <div className='col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12'>
              {renderInput("locationLng", "Longitude", "number")}
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-lg-12 col-md-12 col-xl-12 col-xs-12'>
              <MapContainer center={latlng} zoom={7} scrollWheelZoom={true}>
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <LocationMarker />
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthStep;
