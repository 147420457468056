import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import NotFound from "../NotFound/NotFound";
import { toast } from "react-toastify";
import { gethHub, savehHub } from "./../../services/hubServices";
import { fileToBase64 } from "../../utils/filereader";

class HubsEditForm extends Form {
  state = {
    sendingData: false,
    data: {
      id: "",
      englishId: "",
      arabicId: "",
      arabicFormatedDescription: "",
      englishFormatedDescription: "",
      arabicDescription: "",
      arabicName: "",
      englishDescription: "",
      englishName: "",
      base64Image: "",
    },
    errors: {},
    error: false,
  };

  schema = {
    id: Joi.label("Id"),
    englishId: Joi.label("Id"),
    arabicId: Joi.label("Id"),
    arabicDescription: Joi.string()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicName: Joi.string()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    englishDescription: Joi.string().trim().required().label("Description"),

    // arabicFormatedDescription: Joi.any().when("englishFormatedDescription", {
    //   is: Joi.string(),
    //   then: Joi.string()
    //     .required()
    //     .label("More Information in Arabic")
    //     .regex(/[\u0600-\u06FF]/)
    //     .error(() => {
    //       return {
    //         message: "required and arabic characters only",
    //       };
    //     }),
    //   otherwise: Joi.label("More information in Arabic"),
    // }),

    // englishFormatedDescription: Joi.any().when("arabicFormatedDescription", {
    //   is: Joi.string(),
    //   then: Joi.string().required().trim().label("More Information"),
    //   otherwise: Joi.label("More Information"),
    // }),

    englishFormatedDescription: Joi.string()
      .allow("")
      .trim()
      .max(2097152)
      .label("More Information")
      .error(() => {
        return {
          message: "Max Size is 2MB",
        }
      }),

    arabicFormatedDescription: Joi.string()
      .allow("")
      .max(2097152)
      .label("More Information in Arabic")
      // .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "Max Size is 2MB",
        };
      }),

    englishName: Joi.string().trim().required().label("Name"),
    base64Image: Joi.label("Image"),
  };

  async componentDidMount() {
    await this.populateHub();
  }

  async populateHub() {
    try {
      const hubId = this.props.match.params.id;
      const { data: hub } = await gethHub(hubId);

      this.setState({ data: this.mapToViewModel(hub) });
    } catch (ex) {
      this.setState({ error: true });
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        toast.error("No Such hub !");
    }
  }

  mapToViewModel(hub) {
    const arabic = hub.hubTranslations.find((trans) => trans.locale === "ar");
    const english = hub.hubTranslations.find((trans) => trans.locale === "en");
    return {
      id: hub.id,
      englishId: english.id || "",
      arabicId: arabic.id || "",
      arabicDescription: arabic.description || "",
      arabicName: arabic.name || "",
      englishDescription: english.description || "",
      englishName: english.name || "",
      englishFormatedDescription: english.formatedDescription || "",
      arabicFormatedDescription: arabic.formatedDescription || "",
    };
  }

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        toast.info("Please upload an image");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        if (file.size > 2097152) {
          toast.info("Maximum allowed size for images is 2Mb");
          evt.target.type = "text";
          evt.target.type = "file";
          this.setState({ data: { ...this.state.data, base64Image: "" } });
        } else {
          const base64 = await fileToBase64(file);
          this.setState({ data: { ...this.state.data, base64Image: base64 } });
        }
      }
    } catch (error) {
      return;
    }
  };

  doSubmit = async (e) => {
    try {
      await savehHub(this.state.data);
      toast.success("Saved");
      // setTimeout(function () {
      //   window.location.reload();
      // }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      }
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
    }
  };

  toolbar = [
    "heading",
    "|",
    "bold",
    "link",
    "|",
    'alignment',
    "bulletedList",
    "numberedList",
    "|",
    "insertTable",
    "highlight",
    "imageUpload",
    "|",
    "undo",
    "redo",
  ];

  render() {
    if (this.state.error) {
      return <NotFound />;
    }
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("englishName", "Name", "text", true)}
              {this.renderTextArea("englishDescription", "Description", true)}
              {this.renderEditor(
                "englishFormatedDescription",
                "More Information",
                "en",
                false,
                this.toolbar
              )}
              <label
                htmlFor="file"
                style={{ display: "block", paddingTop: 10 }}
              >
                Upload Image
              </label>
              <input
                accept="image/*"
                type="file"
                name="file"
                id="file"
                className="upload-file btn btn-success"
                onChange={this.handleChangeImage}
                encType="multipart/form-data"
              />
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderArabicInput(
                "arabicName",
                "Arabic Name",
                "text",
                true
              )}
              {this.renderArabicTextArea(
                "arabicDescription",
                "Arabic Description",
                true
              )}
              {this.renderEditor(
                "arabicFormatedDescription",
                "More Information in Arabic",
                { ui: "ar", content: "en" },
                false,
                this.toolbar
              )}
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default HubsEditForm;
