import React from "react";
import Card from "./cards";
import { hubImage } from "../../services/imageUrl";
import { subhubImage } from "../../services/imageUrl";
import PropTypes from "prop-types";

/**
 * Render Cards to donate to projects
 *
 */

const Cards = ({ data, path, hubs }) => {
  return (
    <div className="row">
      {data.map((item) => (
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12" key={item.id}>
          <Card
            btn_text="Donate To Project"
            data={item}
            title={item.name}
            image={hubs ? hubImage(item.id) : subhubImage(item.id)}
            amount={item.available}
            path={`${path}/${item.id}`}
          />
        </div>
      ))}
    </div>
  );
};

Cards.propTypes = {
  /** hubs data fetched from the api */
  data: PropTypes.array.isRequired,
  /** the path for each rendered entry */
  path: PropTypes.string.isRequired,
  /** paginated hubs data */
  hubs: PropTypes.array,
};

export default Cards;
