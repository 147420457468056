import http from "./httpService";
import config from "../config.json";
import { isAdmin, isAuthorizer } from "../utils/checkRole";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/subHubs";

function subhubUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function approveSubHubUrl(id) {
  return `${apiEndpoint}/approve/${id}`;
}

export function getSubhubs() {
  return http.get(apiEndpoint, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getSubhub(subhubId) {
  return http.get(subhubUrl(subhubId), {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getSubhubProjects(subhubId) {
  return http.get(`${apiEndpoint}/${subhubId}/projects`, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveSubhub(subhub) {
  const newSubHub = {
    hubId: subhub.hubId,
    description: subhub.descriptionEnglish,
    locale: "en",
    name: subhub.nameEnglish,
    base64Image: subhub.base64Image,
    subHubTranslations: [
      {
        id: subhub.englishId,
        name: subhub.nameEnglish,
        description: subhub.descriptionEnglish,
        locale: "en",
      },
      {
        id: subhub.arabicId,
        name: subhub.nameArabic,
        description: subhub.descriptionArabic,
        locale: "ar",
      },
    ],
  };
  if (subhub.id) {
    return http.put(subhubUrl(subhub.id), newSubHub, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.post(apiEndpoint, newSubHub, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
}

export async function approveSubHub(subHub) {
  await http.put(
    approveSubHubUrl(subHub.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function deleteSubhub(subhubId) {
  return http.delete(subhubUrl(subhubId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
