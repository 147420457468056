import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser } from "../../services/authService";
import PropTypes from "prop-types";

/**
 * Render a route for cuurent user
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        getCurrentUser() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

PrivateRoute.propTypes = {
  /** Component to render as admin */
  Component: PropTypes.any.isRequired,
  /** Rest of props */
  rest: PropTypes.any,
};
export default PrivateRoute;
