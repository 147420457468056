import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import NotFound from "../NotFound/NotFound";
import { toast } from "react-toastify";
import { addVacancy, getVacancy } from "../../services/vacancies";

class VacanciesForm extends Form {
  state = {
    sendingData: false,
    data: {
      id: "",
      arabicId: "",
      englishId: "",
      title: "",
      arabicTitle: "",
      description: "",
      arabicDescription: "",
      endDate: "",
      url: "",
    },
    errors: {},
    error: false,
  };

  schema = {
    id: Joi.label("Id"),
    arabicId: Joi.label("arabicId"),
    englishId: Joi.label("englishId"),
    arabicTitle: Joi.string()
      .trim()
      .required()
      .label("Arabic Text")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicDescription: Joi.string()
      .trim()
      .required()
      .label("Arabic Text")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    title: Joi.string()
      .trim()
      .required()
      .regex(/^[A-Za-z\s\d!@#‘’$%^&*“”()_+\-=[\]{};':"\\|,.<>…/`~?]*$/)
      .label("English Text")
      .error(() => {
        return {
          message: "required and english characters only",
        };
      }),
    description: Joi.string()
      .trim()
      .required()
      .regex(/^[A-Za-z\s\d!@#‘’$%^&*“”()_+\-=[\]{};':"\\|,.<>…/`~?]*$/)
      .label("English Text")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.regex.base":
              err.message = "English Characters Only";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    endDate: Joi.date()
      .min(new Date().toISOString().split("T")[0])
      .label("End Date")
      .required()
      .error(() => {
        return {
          message: "today or greater",
        };
      }),
    url: Joi.string().trim().allow("").label("URL"),
    // .regex(
    //   /(?:http|https):\/\/((?:[\w-]+)(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
    // )
    // .trim()
    // .required()
    // .error((errors) => {
    //   errors.forEach((err) => {
    //     switch (err.type) {
    //       case "string.regex.base":
    //         err.message = "Enter a valid url";
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   return errors;
    // }),
  };

  async componentDidMount() {
    await this.populateVacancy();
  }

  async populateVacancy() {
    const vacancyId = this.props.match.params.id;
    if (vacancyId === "new") return;

    try {
      const { data: vacancy } = await getVacancy(vacancyId);
      const mappedVacancy = this.mapToViewModel(vacancy);
      this.setState({
        data: mappedVacancy,
      });
    } catch (ex) {
      this.setState({ error: true });
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/dashboard");
      toast.warn("Something Went Wrong !");
    }
  }

  mapToViewModel(vacancy) {
    const arabic = vacancy.vacancieTranslation.find(
      (trans) => trans.locale === "ar"
    );
    const english = vacancy.vacancieTranslation.find(
      (trans) => trans.locale === "en"
    );
    return {
      id: vacancy.id || "",
      arabicId: arabic.id || "",
      englishId: english.id || "",
      title: english.title || "",
      arabicTitle: arabic.title || "",
      description: english.description || "",
      arabicDescription: arabic.description || "",
      url: vacancy.url || "",
      endDate: vacancy.endDate || "",
    };
  }

  doSubmit = async () => {
    const { data } = this.state;
    try {
      await addVacancy(data);
      toast.success("Saved");
      setTimeout(() => {
        window.location.replace("/vacancies");
      }, 3000);
    } catch (ex) {
      this.setState({ sendingData: false });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.url);
      }
    }
  };

  render() {
    const { error } = this.state;

    if (error) {
      return <NotFound />;
    }
    return (
      <>
        <div className="card-body m-3 shadow p-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderInput("title", "Title", "text", true)}
                {this.renderInput("endDate", "End Date", "date", true)}
                {this.renderEditor("description", "Description", "en", true)}
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderArabicInput(
                  "arabicTitle",
                  "Arabic Title",
                  "text",
                  true
                )}
                {this.renderInput("url", "URL", "text", false)}
                {this.renderEditor(
                  "arabicDescription",
                  "Arabic Description",
                  "ar",
                  true
                )}
              </div>
            </div>
            {this.renderButton("Save")}
          </form>
        </div>
      </>
    );
  }
}

export default VacanciesForm;
