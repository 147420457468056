import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import { updatePassword } from "../../services/passwordService";
import { toast } from "react-toastify";

/**
 * Update user password after submitting current password
 * @component
 */
class UpdatePassword extends Form {
  state = {
    data: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    errors: {},
  };

  schema = {
    currentPassword: Joi.string()
      .min(8)
      .max(20)
      .required()
      .label("Old Password"),
    newPassword: Joi.string()
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/)
      .error(() => {
        return {
          message:
            "8 characters with (lowercase, uppercase, number and symbol)",
        };
      })
      .required()
      .label("New Password"),
    confirmPassword: Joi.any()
      .equal(Joi.ref("newPassword"))
      .required()
      .label("Confirm Password")
      .options({ language: { any: { allowOnly: "must match new password" } } }),
  };

  doSubmit = async () => {
    try {
      await updatePassword(
        this.state.data.currentPassword,
        this.state.data.newPassword
      );
      toast.success("Password Changed Successfully");
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast.error("Invalid Old Password");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Something went wrong");
      }
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized");
      }
    }
  };

  render() {
    return (
      <div className="card text-center mx-auto mt-5 px-5 py-3 w-50 rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="currentPassword">Old Password</label>
            <input
              className="form-control text-center"
              type="password"
              name="currentPassword"
              value={this.state.data["currentPassword"]}
              onChange={this.handleChange}
              error={this.state.errors["currentPassword"]}
              id="currentPassword"
            />
            {this.state.errors["currentPassword"] && (
              <p
                className="alert alert-light text-danger text-center"
                style={{ borderRadius: "30px", fontSize: "12px" }}
              >
                {this.state.errors["currentPassword"]}
              </p>
            )}{" "}
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              className="form-control text-center"
              type="password"
              name="newPassword"
              value={this.state.data["newPassword"]}
              onChange={this.handleChange}
              error={this.state.errors["newPassword"]}
              id="newPassword"
            />
            {this.state.errors["newPassword"] && (
              <p
                className="alert alert-light text-danger text-center"
                style={{ borderRadius: "30px", fontSize: "12px" }}
              >
                {this.state.errors["newPassword"]}
              </p>
            )}{" "}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              className="form-control text-center"
              type="password"
              name="confirmPassword"
              value={this.state.data["confirmPassword"]}
              onChange={this.handleChange}
              error={this.state.errors["confirmPassword"]}
              id="confirmPassword"
            />
            {this.state.errors["confirmPassword"] && this.validate() && (
              <p
                className="alert alert-light text-danger text-center"
                style={{ borderRadius: "30px", fontSize: "12px" }}
              >
                {this.state.errors["confirmPassword"]}
              </p>
            )}{" "}
          </div>

          <button
            type="submit"
            className="btn btn-success btn-block"
            disabled={this.validate()}
          >
            Change
          </button>
        </form>
      </div>
    );
  }
}

export default UpdatePassword;
