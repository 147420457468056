import http from "./httpService";
import config from "../config.json";
import { isAdmin } from "../utils/checkRole";
import { getJwt } from "./authService";

const portalUsers = config.globalApi + "/users/portal";
const apiEndPoint = config.globalApi + "/users";

function userUrl(id) {
  return `${apiEndPoint}/${id}`;
}

export function getUsers() {
  return http.get(portalUsers, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getUser(userId) {
  return http.get(userUrl(userId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

function approveUserUrl(id) {
  return `${apiEndPoint}/approve/${id}`;
}

export function saveUser(user) {
  if (user.id) {
    const body = { ...user };
    return http.put(`${apiEndPoint}/${user.id}`, body, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.post(apiEndPoint, user, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
}
export async function approveUser(user) {
  await http.put(
    approveUserUrl(user.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function deleteUser(userId) {
  return http.delete(userUrl(userId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
