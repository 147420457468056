import React, { Component } from "react";
import { toast } from "react-toastify";
import { moneyTransfer, refund } from "../../services/transferService";
import { RefundDialog } from "../common/dialog";
import { getProjects } from "../../services/projectsServices";
import { getHubs } from "../../services/hubServices";
import { getSubhubs } from "../../services/subhubsServices";
import Undo from "@material-ui/icons/Undo";
import Loader from "../common/loader";
import MaterialTable from "material-table";

/**
 * Fetch Transactions from API and Display Transactions table, Refund a Transaction
 * @component
 */
class Transactions extends Component {
  state = {
    transactions: [],
    dialogOpen: false,
    transction: "",
    loading: true,
  };

  async componentDidMount() {
    try {
      let { data: transactions } = await moneyTransfer();
      const { data: projects } = await getProjects();
      const { data: hubs } = await getHubs();
      const { data: subhubs } = await getSubhubs();

      transactions = transactions.filter((trans) => trans.refund === false);
      let transactionArray = [];
      transactions.forEach((transaction) => {
        transactionArray.push({
          project: projects.find((p) => p.id === transaction.projectId).name,
          hub: this.getName(hubs.find((h) => h.id === transaction.hubId)),
          subhub: this.getName(
            subhubs.find((s) => s.id === transaction.subHubId)
          ),
          id: transaction.id,
          amount: transaction.amount,
        });
      });
      this.setState({ transactions: transactionArray, loading: false });
    } catch (error) {
      toast.error("could not fetch transactions");
    }
  }

  getName = (item) => {
    if (item === undefined) {
      return "-";
    } else {
      return item.name;
    }
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleRevert = async (transferId) => {
    const originalTransactions = [...this.state.transactions];
    const transactions = originalTransactions.filter(
      (trans) => trans.id !== transferId
    );
    this.setState({ transactions });
    try {
      await refund(transferId);
      toast.success("refunded successfully");
    } catch (error) {
      toast.error("could not refund");
      this.setState({ transactions: originalTransactions });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <>
        <MaterialTable
          columns={[
            { title: "Project", field: "project" },
            { title: "Hub", field: "hub" },
            { title: "Subhub", field: "subhub" },
            {
              title: "Amount",
              field: "amount",
            },
          ]}
          data={this.state.transactions}
          options={{
            showTitle: false,
            actionsColumnIndex: -1,
            search: true,
            searchFieldStyle: {
              backgroundColor: "#01a553",
              padding: "0 10px",
              borderRadius: "10px",
            },
          }}
          actions={[
            {
              icon: () => <Undo color="secondary" />,
              tooltip: "Reverse Donation",
              onClick: (event, rowData) =>
                this.setState({ dialogOpen: true, transction: rowData }),
            },
          ]}
        />
        <RefundDialog
          dialogOpen={this.state.dialogOpen}
          handleClose={this.handleClose}
          item={this.state.transction}
          handleRevert={() => this.handleRevert(this.state.transction.id)}
        />
      </>
    );
  }
}

export default Transactions;
