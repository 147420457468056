import React, { useState, useEffect } from "react";
import Joi from "joi";
import Input, { ArabicInput } from "../../common/input";
import { toast } from "react-toastify";
import {
  getContactInfoSd,
  getContactsInfoUk,
  updateContactUs,
} from "../../../services/contactUsConfig";
import Map from "./map";
import PropTypes from "prop-types";

/**
 * Render Contact Us Configuration Page
 */

export const urlValidation = Joi.string()
    .trim()
    .regex(
        /(?:http|https):\/\/((?:[\w-]+)(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
    )
    .trim()
    .required()
    .messages({
      "string.pattern.base": `Enter a valid URL`,
    });


export default function ContactUsInfo({ isSudan }) {
  // ******** STATE & SIDE EFFECTS ************ //
  const [state, setState] = useState({
    phone: "",
    email: "",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
    twitter: "",
    website: "",
    englishLocationName: "",
    arabicLocationName: "",
    locationLat: "",
    locationLng: "",
  });
  const [errors, setErrors] = useState({});
  const defaultLat = isSudan ? 15.5946 : 51.509865;
  const defaultLng = isSudan ? 32.537122 : -0.118092;

  const mainSchema = Joi.object({
    twitter: urlValidation,
    facebook: urlValidation,
    website: urlValidation,
    linkedin: urlValidation,
    youtube: urlValidation,
    instagram: urlValidation,
    englishLocationName: Joi.string()
      .pattern(/^[A-Za-z\s\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/`~?]*$/)
      .trim()
      .required()
      .label("Location")
      .messages({
        "string.pattern.base": "english characters only",
      }),
    arabicLocationName: Joi.string()
      .trim()
      .required()
      .label("Arabic Location")
      .regex(/[\u0600-\u06FF]/)
      .message("arabic characters"),
    phone: Joi.string()
      .pattern(/^[0-9]+$/)
      .required()
      .trim()
      .messages({
        "string.pattern.base": `Only numbers are allowed`,
      })
      .label("Phone"),
    email: Joi.string()
      .required()
      .trim()
      .email({ tlds: { allow: false } })
      .label("Email"),
    locationLng: Joi.number()
      .greater(-180)
      .less(180)
      .unsafe()
      .allow("")
      .optional()
      .label("Longitude"),
    locationLat: Joi.number()
      .greater(-90)
      .less(90)
      .unsafe()
      .precision(3)
      .allow("")
      .optional()
      .label("location"),
  });

  const fetchData = async () => {
    try {
      if (isSudan === true) {
        const { data: contactInfo } = await getContactInfoSd();
        setState(mapContacrInfo(contactInfo));
      } else {
        const { data: contactInfo } = await getContactsInfoUk();
        setState(mapContacrInfo(contactInfo));
      }
    } catch (error) {
      // toast.error("Something went wrong");
    }
  };

  const mapContacrInfo = (info) => {
    return {
      phone: info.phone || "",
      email: info.email || "",
      facebook: info.facebook || "",
      linkedin: info.linkedin || "",
      youtube: info.youtube || "",
      instagram: info.instagram || "",
      twitter: info.twitter || "",
      website: info.website || "",
      englishLocationName: info.location.locationTranslation[0].name || "",
      arabicLocationName: info.location.locationTranslation[1].name || "",
      locationLat: info.location.lat,
      locationLng: info.location.lng,
    };
  };

  useEffect(() => {
    fetchData();
  }, []);
  // ******** END OF STATE & SIDE EFFECTS ************ //

  // ********* RENDERING INPUTS ************* //
  const renderInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <Input
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max="9999-12-31"
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <ArabicInput
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max="9999-12-31"
        />
      );
    }
    return (
      <ArabicInput
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderButton = (label) => {
    return (
      <div className="text-center">
        <button
          type="submit"
          disabled={buttonDisabled()}
          className="btn btn-outline-success mt-3"
          style={{ borderRadius: "20px" }}
        >
          {label}
        </button>
      </div>
    );
  };

  // ******** END OF RENDERING INPUTS ********** //

  // ********** EVENT HANDLERS **************** //
  const handleInputChange = ({ currentTarget: input }) => {
    const errs = { ...errors };
    const res = validateProperty(input);

    if (res) errs[input.name] = res;
    else delete errs[input.name];
    const data = { ...state };
    data[input.name] = input.value;
    setState(data);
    setErrors(errs);
  };

  const handleMapChange = (lat, lng) => {
    setState({
      ...state,
      locationLat: lat,
      locationLng: lng,
    });
  };

  const buttonDisabled = () => {
    const { error } = mainSchema.validate(state, {
      convert: true,
      abortEarly: false,
    });
    return error ? true : false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isSudan === true) {
        await updateContactUs(state, "sd");
      } else {
        await updateContactUs(state, "uk");
      }
      toast.success("updated");
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  // ********** END OF EVENT HANDLERS ********** //

  // ********** VALIDATORS AND HELPER FUNCTIONS *********** //
  const validateProperty = ({ name, value }) => {
    let obj = { [name]: value };
    let schema = Joi.object({
      [name]: mainSchema.extract(name),
    });
    let { error } = schema.validate(obj, {
      convert: true,
      errors: { wrap: { label: "" } },
    });
    return error ? error.details[0].message : null;
  };

  // ********** END OF VALIDATORS AND HELPER FUNCTIONS *********** //
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          {renderInput("englishLocationName", "English Location", "en", true)}
          {renderInput("phone", "Phone", "text", true)}
          {renderInput("facebook", "Facebook", "text", true)}
          {renderInput("linkedin", "LinkedIn", "text", true)}
          {renderInput("instagram", "Instagram", "text", true)}
        </div>
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          {renderArabicInput(
            "arabicLocationName",
            "Arabic Location",
            "en",
            true
          )}
          {renderInput("website", "Website", "text", true)}
          {renderInput("email", "Email", "text", true)}
          {renderInput("youtube", "Youtube", "text", true)}
          {renderInput("twitter", "Twitter", "text", true)}
        </div>
      </div>

      <Map
        onMapChange={handleMapChange}
        latlng={[
          parseFloat(state.locationLat) || defaultLat,
          parseFloat(state.locationLng) || defaultLng,
        ]}
      />

      {renderButton("Update")}
    </form>
  );
}

ContactUsInfo.propTypes = {
  /** render sudan or uk version*/
  isSudan: PropTypes.bool,
};
