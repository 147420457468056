import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import SaveIcon from "@material-ui/icons/EditOutlined";
import Done from "@material-ui/icons/CheckBoxOutlined";
import Clear from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import Info from "@material-ui/icons/InfoOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {isAuthorizer, isDataEntry } from "../../utils/checkRole";
import { programImage } from "../../services/imageUrl";

class ProjectsTable extends Component {
  state = {
    project: {},
    dialogOpen: false,
    viewDetails: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };
  handleViewDetails = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ viewDetails: true });
  };
  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    if (this.state.redirect === false && this.state.viewDetails === false) {
      return (
        <React.Fragment>
          <MaterialTable
            title="Programs Table"
            columns={[
              {
                title: "Image",
                sorting: false,
                render: (rowData) => (
                  <img
                    src={programImage(rowData.id)}
                    style={{ width: 100, height: 100 }}
                    alt=""
                  />
                ),
              },
              { title: "Name", field: "name" },
              {
                title: "Arabic Name",
                field: `programTranslations[0].name`,
              },
              { title: "Status", field: "status" },
            ]}
            data={this.props.projects }
            actions={[
              {
                icon: () => <Info />,
                tooltip: "View Program",
                onClick: (event, rowData) => {
                  this.handleViewDetails(rowData);
                },
              },
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit Program",
                onClick: (event, rowData) => {
                  this.handleRoute(rowData);
                },
              },
              {
                hidden: isDataEntry() || isAuthorizer(),
                icon: () => <DeleteIcon color="secondary" />,
                tooltip: "Delete Program",
                onClick: (event, rowData) =>
                  this.setState({ dialogOpen: true, project: rowData }),
              },
              (rowData) => ({
                hidden: isDataEntry(),
                icon: () =>
                  rowData.approvedByAdmin === true ? <Done /> : <Clear />,
                tooltip:
                  rowData.approvedByAdmin === true
                    ? "Unapprove project"
                    : "Approve project",
                onClick: (event, rowData) => {
                  this.props.approveProject(rowData);
                },
              }),
            ]}
            onRowClick={(event, rowData) =>
              this.setState({ id: rowData.id, redirect: true })
            }
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.project}
            handleDelete={this.props.onDelete}
          />
        </React.Fragment>
      );
    }
    if (this.state.redirect) {
      return <Redirect to={`/programs/form/${this.state.id}`} />;
    }
    if (this.state.viewDetails) {
      return <Redirect to={`/programs/${this.state.id}`} />;
    }
  }
}

export default ProjectsTable;
