import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

export function getVideoConfig() {
  return http.get(`${config.globalApi}/video-config`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
      "accept-language": "en",
    },
  });
}

export function setVideoConfig({ arabicVideoTitle, videoTitle, videoUrl }) {
  const updatedVideoConfig = {
    link: videoUrl,
    translation: [
      { text: videoTitle, locale: "en" },
      { text: arabicVideoTitle, locale: "ar" },
    ],
  };

  return http.post(`${config.globalApi}/video-config`, updatedVideoConfig, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
