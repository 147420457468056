import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  addGalleryImage,
  deleteGalleryImage,
  getGalleryImages,
} from "../../../services/galleryService";
import { fileToBase64 } from "../../../utils/filereader";
import Progress from "../../common/progress";
import GalleryGrid from "./GalleryGrid";

/**
 * Render Image Gallery Configuration Page
 */
function Gallery() {
  const [images, setImages] = useState([]);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    const { data: images } = await getGalleryImages();
    setImages(images);
  };

  const handlePercentageChange = (percentage) => {
    setPercentage(percentage);
  };

  const handleImageChange = async ({ target }) => {
    try {
      const file = target.files[0];
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        toast.warn("Please select a png/jpeg image");
        target.type = "text";
        target.type = "file";
        return;
      }
      if (file.size > 500000) {
        toast.warn("Maximum allowed size for images is 500 Kb");
        target.type = "text";
        target.type = "file";
        return;
      } else {
        const base64 = await fileToBase64(file);
        await addGalleryImage(base64, handlePercentageChange);
        fetchImages();
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleImageDelete = async (image) => {
    const imgs = [...images];
    const updatedIamges = imgs.filter((img) => img.id !== image.id);
    setImages(updatedIamges);
    try {
      await deleteGalleryImage(image.id);
      toast.success("deleted successfully");
    } catch (error) {
      toast.error("could not delete image");
      setImages(imgs);
    }
  };

  return (
    <>
      {images.length < 20 ? (
        <form className="gallery-form">
          <label className="gallery_label">
            <input
              className="gallery_input"
              multiple={false}
              type="file"
              accept="image/*"
              onChange={(event) => handleImageChange(event)}
            />
            <span>+</span>
          </label>
          {percentage === 0 || percentage === 100 ? null : (
            <Progress value={parseInt(percentage)} label="Uploading ..." />
          )}
        </form>
      ) : (
        <Typography style={{ textAlign: "center", paddingBottom: 10 }}>
          Maximum 20 Images
        </Typography>
      )}
      <GalleryGrid images={images} onDelete={handleImageDelete} />
    </>
  );
}

export default Gallery;
