import React, { Component } from "react";
import { Link } from "react-router-dom";
import { gethHub } from "../../services/hubServices";
import { toast } from "react-toastify";
import { hubImage } from "../../services/imageUrl";
import NotFound from "../NotFound/NotFound";
import Loader from "../common/loader";
import isEmpty from "../../utils/objectEmpty";

class SingleHub extends Component {
  state = { hub: {}, isLoading: true, arabic: {} };

  async componentDidMount() {
    const hubId = this.props.match.params.id;
    try {
      const { data: hub } = await gethHub(hubId);
      const arabic = hub.hubTranslations.find((trans) => trans.locale === "ar");
      this.setState({ hub, arabic, isLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        this.setState({ isLoading: false });
        toast.error("No Such Hub ");
      }
    }
  }

  render() {
    const { hub, arabic, isLoading } = this.state;
    if (isLoading === true) {
      return <Loader />;
    } else if (isEmpty(hub) && isLoading === false) {
      return <NotFound />;
    } else if (!isEmpty(hub) && isLoading === false) {
      return (
        <div className="container">
          <div className="card-body m-3 shadow p-3 bg-white rounded">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <img
                  className="img-fluid rounded shadow"
                  src={hubImage(hub.id)}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <table className="table table-hover">
                  <caption className="side-top">{hub.name} details</caption>
                  <tbody>
                    <tr>
                      <th className="text-success">Name</th>
                      <td>{hub.name}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Description</th>
                      <td>{hub.description}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Arabic Name</th>
                      <td>{arabic.name}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Arabic Description</th>
                      <td>{arabic.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <Link
                  className="btn btn-outline-secondary btn-block"
                  to="/hubs"
                >
                  Back
                </Link>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <Link
                  className="btn btn-outline-success btn-block"
                  to={`/hubs/form/${hub.id}`}
                >
                  Edit
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SingleHub;
