import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import Info from "@material-ui/icons/InfoOutlined";
import { isAuthorizer, isDataEntry } from "../../utils/checkRole";

class UsersTable extends Component {
  state = {
    user: {},
    dialogOpen: false,
    viewDetails: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };

  handleViewDetails = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ viewDetails: true });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    if (this.state.redirect === false && this.state.viewDetails === false) {
      return (
        <>
          <MaterialTable
            title="Users Table"
            columns={[
              { title: "First Name", field: "firstName" },
              { title: "Last Name", field: "lastName" },
              { title: "Phone Number", field: "phoneNumber" },
              {
                title: "Email",
                field: "userName",
              },
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
            data={this.props.users}
            actions={[
              {
                icon: () => <Info />,
                tooltip: "View User",
                onClick: (event, rowData) => {
                  this.handleViewDetails(rowData);
                },
              },
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit User",
                onClick: (event, rowData) => {
                  this.handleRoute(rowData);
                },
              },
              {
                hidden: isAuthorizer() || isDataEntry(),
                icon: () => <DeleteIcon color="secondary" />,
                tooltip: "Delete User",
                onClick: (event, rowData) => {
                  this.setState({ dialogOpen: true, user: rowData });
                },
              },
            ]}
            onRowClick={(event, rowData) =>
              this.setState({ id: rowData.id, redirect: true })
            }
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.user}
            handleDelete={this.props.onDelete}
          />
        </>
      );
    }
    if (this.state.redirect === true) {
      return <Redirect to={`/users/form/${this.state.id}`} />;
    }
    if (this.state.viewDetails === true) {
      return <Redirect to={`/users/${this.state.id}`} />;
    }
  }
}

export default UsersTable;
