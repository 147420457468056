import React from "react";
import {
  getNew,
  saveNews,
  deleteNewsImages,
  updateNewsImages,
  addNewsVideos,
  deleteNewsVideo,
} from "./../../services/newsService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";
import NotFound from "../NotFound/NotFound";
import LineGrid from "../common/lineGrid";
import Progress from "../common/progress";
import UploadZone, { VideoUpload } from "../common/dropZone";
import VideosContainer from "../common/videoContainer";
import { fileToBase64 } from "../../utils/filereader";

class NewsEditForm extends Form {
  state = {
    newsVideos: [],
    videoFiles: [],
    video: {},
    sendingData: false,
    image: "",
    imageUploadPerecentage: "",
    videoUploadPerecentage: "",
    imagesToDelete: [],
    videosToDelete: [],
    newsImages: [],
    files: [],
    data: {
      id: "",
      englishId: "",
      arabicId: "",
      name: "",
      description: "",
      arabicName: "",
      arabicDescription: "",
      expireDate: "",
      base64Image: "",
      base64Images: [],
    },
    errors: {},
    error: false,
  };

  schema = {
    id: Joi.label("Id"),
    name: Joi.string().trim().required().label("Name"),
    description: Joi.string().required().label("description"),
    arabicName: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicDescription: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    expireDate: Joi.date()
      .required()
      .label("Expire Date")
      .error(() => {
        return {
          message: "Expire date is required",
        };
      }),
    base64Image: Joi.label("Image"),
    base64Images: Joi.label("Images"),
    arabicId: Joi.label("arabicId"),
    englishId: Joi.label("englishId"),
  };

  async componentDidMount() {
    await this.populateNews();
  }

  async populateNews() {
    try {
      const newsId = this.props.match.params.id;
      const { data: news } = await getNew(newsId);
      this.setState({
        data: this.mapToViewModel(news),
        newsImages: news.images,
        newsVideos: news.video,
      });
    } catch (ex) {
      this.setState({ error: true });
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/news");
      toast.warn("No Such News !");
    }
  }

  mapToViewModel(news) {
    const arabic = news.newsTranslations.find(
      (trans) => trans.locale === "ar"
    ) || { name: "", description: "", id: "" };
    const english = news.newsTranslations.find(
      (trans) => trans.locale === "en"
    ) || { name: "", description: "", id: "" };
    return {
      id: news.id,
      englishId: english.id || "",
      arabicId: arabic.id || "",
      name: english.name || "",
      description: english.description || "",
      arabicName: arabic.name || "",
      arabicDescription: arabic.description || "",
      expireDate: news.expireDate || "",
    };
  }

  imageUploadPercentage = (percentage) => {
    this.setState({ imageUploadPerecentage: percentage });
  };

  videoUploadPercentage = (percentage) => {
    this.setState({ videoUploadPerecentage: percentage });
  };

  readFile = async (file) => {
    const data = { ...this.state.data };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    this.setState({ data });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const files = [...this.state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...this.state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "done") {
      this.setState({
        files: updatedFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "removed") {
      this.setState({
        files: files.filter((f) => f !== file),
        data: { ...this.state.data, base64Images: [] },
      });
    }
  };

  deleteImage = (imageId) => {
    const originalImages = [...this.state.newsImages];
    const updatedImages = originalImages.filter((img) => img.id !== imageId);
    const imagesToDelete = [...this.state.imagesToDelete];
    imagesToDelete.push(imageId);
    this.setState({ newsImages: updatedImages, imagesToDelete });
  };

  handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ video: file });
    }
    if (status === "removed") {
      this.setState({ video: {} });
    }
  };

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        const base64 = await fileToBase64(file);
        this.setState({ data: { ...this.state.data, base64Image: base64 } });
      }
    } catch (error) {
      return;
    }
  };

  handleVideoDelete = (videoId) => {
    const originalVideos = [...this.state.newsVideos];
    const updatedVideos = originalVideos.filter((vid) => vid.id !== videoId);
    const videosToDelete = [...this.state.videosToDelete];
    videosToDelete.push(videoId);
    this.setState({ newsVideos: updatedVideos, videosToDelete });
  };

  handleNewsSave = async (data, imagesToDelete) => {
    if (imagesToDelete.length !== 0) {
      await deleteNewsImages(imagesToDelete);
    }
    if (this.state.videosToDelete.length > 0) {
      await deleteNewsVideo(this.state.videosToDelete);
    }
    if (this.state.video instanceof File) {
      await addNewsVideos(
        this.state.video,
        parseInt(this.state.data.id),
        this.videoUploadPercentage
      );
    }
    if (this.state.data.base64Images) {
      await updateNewsImages(
        this.state.data.id,
        this.state.data.base64Images,
        this.imageUploadPercentage
      );
    }
    await saveNews(data);
    this.setState({
      data: { ...data, base64Images: [] },
      imagesToDelete: [],
      videosToDelete: [],
    });
    toast.success("Saved");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  doSubmit = async () => {
    const { files, data, imagesToDelete } = this.state;
    try {
      for (let i = 0; i < files.length; i++) {
        this.readFile(files[i]);
      }
      setTimeout(() => {
        this.handleNewsSave(data, imagesToDelete);
      }, 1000);
    } catch (ex) {
      this.setState({
        data: { ...this.state.data, base64Images: [] },
        sendingData: false,
      });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      }
    }
  };

  render() {
    const { imageUploadPerecentage, videoUploadPerecentage } = this.state;

    if (this.state.error) {
      return <NotFound />;
    }
    return (
      <>
        <div className="card-body m-3 shadow p-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {" "}
                {this.renderInput("name", "Name", "text", true)}
                {this.renderTextArea("description", "Description", true)}
                <label htmlFor="file" style={{ display: "block" }}>
                  Upload Image
                </label>
                <input
                  accept="image/*"
                  ref="file"
                  type="file"
                  name="file"
                  className="upload-file btn btn-success"
                  onChange={this.handleChangeImage}
                  encType="multipart/form-data"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderArabicInput(
                  "arabicName",
                  "Arabic Name",
                  "text",
                  true
                )}
                {this.renderArabicTextArea(
                  "arabicDescription",
                  "Arabic Description",
                  true
                )}
                {this.renderInput("expireDate", "Expiration", "date", true)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <UploadZone
                  onChange={this.handleChangeStatus}
                  placeholder="Add News Images"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <VideoUpload
                  onChange={this.handleVideoChange}
                  placeholder="Upload Video"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <LineGrid
                  images={this.state.newsImages}
                  onDelete={this.deleteImage}
                  type="news"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.state.newsVideos.length > 0 && (
                  <VideosContainer
                    videos={this.state.newsVideos}
                    type="news"
                    onDelete={this.handleVideoDelete}
                  />
                )}
              </div>
            </div>
            {this.renderButton("Save")}
          </form>
        </div>
        {videoUploadPerecentage === "" ||
        videoUploadPerecentage === 100 ? null : (
          <Progress
            value={parseInt(videoUploadPerecentage)}
            label="Uploading Video"
          />
        )}{" "}
        {imageUploadPerecentage === "" ||
        imageUploadPerecentage === 100 ? null : (
          <Progress
            value={parseInt(imageUploadPerecentage)}
            label="Uploading Images"
          />
        )}{" "}
      </>
    );
  }
}

export default NewsEditForm;
