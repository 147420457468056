import React from "react";
import { saveUser } from "../../services/userService";
import { getPortalRoles } from "../../services/roleServices";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";

class UserForm extends Form {
  state = {
    sendingData: false,
    roles: [],
    data: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      phoneNumber: "",
      gender: "",
      baseRoleName: "",
      dateOfBirth: "",
    },
    errors: {},
  };

  async componentDidMount() {
    const { data: rolesJson } = await getPortalRoles();
    const roles = rolesJson.map((role) => ({
      name: role.displayName,
      value: role.name,
    }));
    this.setState({ roles });
  }

  schema = {
    id: Joi.label("Id"),
    firstName: Joi.string().trim().required().label("First Name"),
    lastName: Joi.string().trim().required().label("Last Name"),
    userName: Joi.string()
      .required()
      .trim()
      .email({ minDomainAtoms: 2 })
      .label("Email"),
    password: Joi.string()
      .required()
      .trim()
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/)
      .error(() => {
        return {
          message:
            "8 characters with (lowercase, uppercase, number and symbol)",
        };
      })
      .label("Password"),
    phoneNumber: Joi.string()
      .disallow("-", ".", "e")
      .required()
      .trim()
      .min(10)
      .max(13)
      .label("Phone Number"),
    gender: Joi.string().trim().required().label("Gender"),
    dateOfBirth: Joi.date()
      .max("January 1, 2002 12:00:00")
      .min("January 1, 1950 12:00:00")
      .label("Date Of Birth")
      .error(() => {
        return {
          message: "1950 - 2002",
        };
      }),
    baseRoleName: Joi.string().trim().required().label("Role"),
  };

  doSubmit = async () => {
    try {
      await saveUser(this.state.data);
      toast.success("Saved");
      setTimeout(function () {
        window.location.replace("/users");
      }, 3000);
    } catch (ex) {
      this.setState({ sendingData: false });
      if (ex.response && ex.response.status === 500) {
        toast.error("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("This User Already Exists");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error("Some Inputs Are Invalid !");
      }
    }
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("firstName", "First Name", "text", true)}
              {this.renderInput("lastName", "Last Name", "text", true)}
              {this.renderInput("phoneNumber", "Phone Number", "number", true)}
              {this.renderSelect(
                "baseRoleName",
                "Role",
                this.state.roles,
                true
              )}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("userName", "Email", "text", true)}
              {this.renderInput("password", "Password", "password", true)}
              {this.renderSelect(
                "gender",
                "Gender",
                [
                  { value: "MALE", name: "Male" },
                  { value: "FEMALE", name: "Female" },
                ],
                true
              )}
              {this.renderInput("dateOfBirth", "Date Of Birth", "date", true)}
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default UserForm;
