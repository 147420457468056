import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const unAuthorized = error.response && error.response.status === 401;
  if (unAuthorized) {
    logger.log(error);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    toast.error("Session timeout, please login again", {
      position: "top-center",
    });
    setTimeout(function () {
      window.location.replace("/");
    }, 5500);
  }
  return Promise.reject(error);
});

// function setJwt(jwt) {
//   axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
// }

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  // setJwt: setJwt,
};
