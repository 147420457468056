import React from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Progress from "../../common/progress";
import parse from "html-react-parser";
import Hidden from "@material-ui/core/Hidden"
import { Table } from 'reactstrap';


const Review = ({ data }) => {
  return (
    <>
    <div className="card-body m-3 shadow p-3 bg-white rounded">
      <div className="row">
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
        <Table borderless>
         <tbody>
        <tr>
          <td><b>Name</b></td>
          <td>{data.nameEnglish}</td>
        </tr>
        <tr>
          <td><b>Status</b></td>
          <td>{data.statusEnglish}</td>
        </tr>
        <tr>
          <td><b>Volunteered</b></td>
          <td>{data.subscribed}</td>
        </tr>
          <tr>
          <td><b>location</b></td>
          <td>{data.locationNameEnglish}</td>
        </tr>
        <tr>
          <td><b>Date</b></td>
          <td>{data.startAt}</td>
        </tr>
         <tr>
          <td colSpan="2">
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="description"
              id="description"
            >
              <Typography><b>Description</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              {parse(data.descriptionEnglish)}
            </AccordionDetails>
          </Accordion></td>
        </tr>
      </tbody>
    </Table>
        </div>
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
        <Table borderless>
         <tbody>
        <tr>
          <td><b>Arabic Name</b></td>
          <td>{data.nameArabic}</td>
        </tr>
        <tr>
          <td><b>Arabic Status</b></td>
          <td>{data.statusArabic === "completed" ? "مكتمل" : "قيد العمل"}</td>
        </tr>
        <tr>
          <td><b>Target</b></td>
          <td>{data.target}</td>
        </tr>
        <tr>
          <td><b>Arabic location</b></td>
          <td>{data.locationNameArabic}</td>
        </tr>
        <Hidden xsDown>
        <tr>
          <td colSpan="2"><br/></td>
        </tr>
        </Hidden>
        <tr>
          <td colSpan="2">          
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="description"
              id="description"
            >
              <Typography><b>Arabic Description</b></Typography>
            </AccordionSummary>
            <AccordionDetails dir='rtl'>
              {parse(data.descriptionArabic)}
            </AccordionDetails>
          </Accordion></td>
        </tr>
      </tbody>
      </Table>
      </div>
      </div>
    </div>
    <div className="m-2">
      {data.percentage === 0 || data.percentage === 100 ? null : (
        <Progress value={parseInt(data.percentage)} label="Uploading Video" />
      )}
    </div>
  </>
  );
};

export default Review;
