import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import Slider from "./slider/Slider";
import Gallery from "./Gallery/Gallery";
import Donation from "./Donation/Donation";
import Partners from "./Partners/Partners";
import ContactUsInfo from "./ContactUs/Contact";
import Copyright from "./Copyright/Copyright";
import Video from "./Video/Video";
import About from "./About/About";
import Covers from "./Covers/Covers";
import Magazine from "./Magazine/Magazine";

/**
 * Render a Tab for each Configuration Page
 */
class Configuration extends Component {
  state = {
    currentTabIndex: 0,
  };

  createTabId(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ ...this.state, currentTabIndex: newValue });
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded config_container">
        <div style={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={this.state.currentTabIndex}
              onChange={this.handleTabChange}
              aria-label="configuration tabs"
              indicatorColor="primary"
            >
              <Tab label="Slider" {...this.createTabId(0)} />
              <Tab label="Gallery" {...this.createTabId(1)} />
              <Tab label="Donation" {...this.createTabId(2)} />
              <Tab label="Contact - Sudan" {...this.createTabId(3)} />
              <Tab label="Contact - UK" {...this.createTabId(4)} />
              <Tab label="Partners" {...this.createTabId(5)} />
              <Tab label="Copyright" {...this.createTabId(6)} />
              <Tab label="Video" {...this.createTabId(7)} />
              <Tab label="About Us" {...this.createTabId(8)} />
              <Tab label="Cover Images" {...this.createTabId(9)} />
              <Tab label="Magazine" {...this.createTabId(10)} />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.currentTabIndex} index={0}>
            <Slider />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={1}>
            <Gallery />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={2}>
            <Donation />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={3}>
            <ContactUsInfo isSudan={true} />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={4}>
            <ContactUsInfo isSudan={false} />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={5}>
            <Partners />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={6}>
            <Copyright />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={7}>
            <Video />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={8}>
            <About />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={9}>
            <Covers />
          </TabPanel>
          <TabPanel value={this.state.currentTabIndex} index={10}>
            <Magazine />
          </TabPanel>
        </div>
      </div>
    );
  }
}

export default Configuration;
