import Joi from "joi";

export function getValidationSchema(activeStep) {
  switch (activeStep) {
    case 0:
      return firstStepSchema;
    case 1:
      return secondStepSchema;
    case 2:
      return thirdStepSchema;
    case 3:
      return fourthStepSchema;
    default:
      return firstStepSchema;
  }
}

export function validateCurrentStep(activeStep, data) {
  switch (activeStep) {
    case 0:
      return validateStep(data, firstStepSchema);
    case 1:
      return validateStep(data, secondStepSchema);
    case 2:
      return validateStep(data, thirdStepSchema);
    case 3: 
      return validateStep(data, fourthStepSchema);
    default:
      return validateStep(data, firstStepSchema);
  }
}

const validateStep = (data, schema) => {
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
    convert: true,
  });
  return error;
};

export const validateAllFields = (data) => {
  const schema = Joi.object({
    nameArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .message("Arabic characters only"),
    nameEnglish: Joi.string().trim().required().label("Name"),
    target: Joi.number().positive().required().label("Target"),
    subscribed: Joi.number()
      .required()
      .positive()
      .label("Volunteered")
      .max(Joi.ref("target", { render: true }))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "any.ref":
              err.message = "Less than target!";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    statusArabic: Joi.string().trim().required().label("Arabic Status"),
    statusEnglish: Joi.string().trim().required().label("status"),
    startAt: Joi.date()
      .required()
      .label("Date")
      .max("now")
      .message("less than today"),
    descriptionEnglish: Joi.string().trim().required().label("Description"),
    descriptionArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .message("arabic characters"),
    locationNameEnglish: Joi.string().trim().required().label("Location"),
    locationNameArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Location")
      .regex(/[\u0600-\u06FF]/)
      .message("arabic characters"),
  });
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
    convert: true,
  });
  return error ? true : false;
};

const firstStepSchema = Joi.object({
  nameArabic: Joi.string()
    .trim()
    .required()
    .label("Arabic Name")
    .regex(/[\u0600-\u06FF]/)
    .message("Arabic characters only"),
  nameEnglish: Joi.string().trim().required().label("Name"),
  target: Joi.number().positive().required().label("Target"),
  subscribed: Joi.number()
    .required()
    .min(0)
    .label("Registered Volunteers")
    .max(Joi.ref("target", { render: true }))
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.ref":
            err.message = "Less than target !";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  statusArabic: Joi.string().trim().required().label("Arabic Status"),
  statusEnglish: Joi.string().trim().required().label("status"),
  startAt: Joi.date()
    .required()
    .label("Date")
    .max("now")
    .message("today or earlier !"),
});

const secondStepSchema = Joi.object({
  descriptionEnglish: Joi.string().trim().required().label("Description"),
  descriptionArabic: Joi.string()
    .trim()
    .required()
    .label("Arabic Description")
    .regex(/[\u0600-\u06FF]/)
    .message("Arabic characters"),
});

const thirdStepSchema = Joi.object({});

const fourthStepSchema = Joi.object({
  locationNameEnglish: Joi.string().trim().required().label("Location"),
  locationNameArabic: Joi.string()
    .trim()
    .required()
    .label("Arabic Location")
    .regex(/[\u0600-\u06FF]/)
    .message("Arabic characters"),
});


