import React from "react";
import {
  getEvent,
  saveEvent,
  updateEventImages,
  deleteEventImages,
  addEventVideos,
  deleteEventVideo,
} from "./../../services/eventService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";
import UploadZone, { VideoUpload } from "../common/dropZone";
import Progress from "../common/progress";
import LineGrid from "../common/lineGrid";
import VideosContainer from "../common/videoContainer";
import { fileToBase64 } from "../../utils/filereader";

class EventsEditForm extends Form {
  state = {
    video: {},
    eventVideos: [],
    imageUploadPercentage: "",
    videoUploadPercentage: "",
    imagesToDelete: [],
    videosToDelete: [],
    eventImages: [],
    files: [],
    sendingData: false,
    data: {
      id: "",
      arabicId: "",
      englishId: "",
      arabicDescription: "",
      arabicLocationName: "",
      arabicName: "",
      englishDescription: "",
      englishLocationName: "",
      englishName: "",
      startDate: "",
      endDate: "",
      base64Images: [],
    },
    errors: {},
  };

  schema = {
    id: Joi.label("Id"),
    arabicId: Joi.label("id"),
    englishId: Joi.label("Id"),
    arabicDescription: Joi.string()
      .trim()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicLocationName: Joi.string()
      .trim()
      .label("Arabic Location")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicName: Joi.string()
      .trim()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    englishDescription: Joi.string().trim().label("Description"),
    englishLocationName: Joi.string().trim().label("location Name"),
    englishName: Joi.string().trim().label("Name"),
    startDate: Joi.date()
      .required()
      .label("Start Date")
      .error(() => {
        return {
          message: "Start Date is required",
        };
      }),
    endDate: Joi.date()
      .min(Joi.ref("startDate"))
      .required()
      .label("End Date")
      .options({
        language: {
          date: {
            ref: "",
          },
        },
      }),
    base64Images: Joi.label("Images"),
  };

  async componentDidMount() {
    await this.populateEvents();
  }

  async populateEvents() {
    try {
      const eventId = this.props.match.params.id;
      if (eventId === "new") return;

      const { data: event } = await getEvent(eventId);
      this.setState({
        data: this.mapToViewModel(event),
        eventImages: event.images,
        eventVideos: event.video,
      });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response &&
        ex.response.status <= 500
      )
        this.props.history.replace("/events");
      toast.warn("No Such event !");
    }
  }

  mapToViewModel(event) {
    const arabic = event.eventTranslations.find(
      (trans) => trans.locale === "ar"
    );
    const english = event.eventTranslations.find(
      (trans) => trans.locale === "en"
    );
    return {
      id: event.id,
      arabicId: arabic.id,
      englishId: english.id,
      startDate: event.startDate || "",
      endDate: event.endDate || "",
      arabicDescription: arabic.description || "",
      arabicLocationName: arabic.locationName || "",
      arabicName: arabic.name || "",
      englishDescription: english.description || "",
      englishLocationName: english.locationName || "",
      englishName: english.name || "",
    };
  }

  imageUploadPercentage = (percentage) => {
    this.setState({ imageUploadPercentage: percentage });
  };

  videoUploadPercentage = (percentage) => {
    this.setState({ videoUploadPercentage: percentage });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const files = [...this.state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...this.state.files, file];
    if (status === "error_file_size") {
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "done") {
      this.setState({
        files: updatedFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "removed") {
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
  };

  handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ video: file });
    }
    if (status === "removed") {
      this.setState({ video: {} });
    }
  };

  handleVideoDelete = (videoId) => {
    const originalVideos = [...this.state.eventVideos];
    const updatedVideos = originalVideos.filter((vid) => vid.id !== videoId);
    const videosToDelete = [...this.state.videosToDelete];
    videosToDelete.push(videoId);
    this.setState({ eventVideos: updatedVideos, videosToDelete });
  };

  handleEventSave = async (data, imagesToDelete) => {
    if (imagesToDelete.length !== 0) {
      await deleteEventImages(imagesToDelete);
    }
    if (this.state.videosToDelete.length > 0) {
      await deleteEventVideo(this.state.videosToDelete);
    }
    if (this.state.video instanceof File) {
      await addEventVideos(
        this.state.video,
        parseInt(this.state.data.id),
        this.videoUploadPercentage
      );
    }
    if (this.state.data.base64Images) {
      await updateEventImages(
        this.state.data.id,
        this.state.data.base64Images,
        this.imageUploadPercentage
      );
    }
    await saveEvent(data);
    this.setState({
      data: {
        ...data,
        base64Images: [],
        videosToDelete: [],
        imagesToDelete: [],
      },
    });
    toast.success("Saved");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  deleteImage = (imageId) => {
    const originalImages = [...this.state.eventImages];
    const updatedImages = originalImages.filter((img) => img.id !== imageId);
    const imagesToDelete = [...this.state.imagesToDelete];
    imagesToDelete.push(imageId);
    this.setState({ eventImages: updatedImages, imagesToDelete });
  };

  doSubmit = async () => {
    const { files, data, imagesToDelete } = this.state;
    try {
      for (let i = 0; i < files.length; i++) {
        this.readFile(files[i]);
      }
      setTimeout(() => this.handleEventSave(data, imagesToDelete), 1000);
    } catch (ex) {
      this.setState({ data: { ...this.state.data, base64Images: [] } });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  readFile = async (file) => {
    const data = { ...this.state.data };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    this.setState({ data });
  };

  render() {
    const {
      imageUploadPercentage,
      videoUploadPercentage,
      eventImages,
    } = this.state;
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("englishName", "Name", "text", true)}
              {this.renderTextArea("englishDescription", "Description", true)}
              {this.renderInput(
                "englishLocationName",
                "Location",
                "text",
                true
              )}
              {this.renderInput("startDate", "Start Date", "date", true)}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderArabicInput(
                "arabicName",
                "Arabic Name",
                "text",
                true
              )}
              {this.renderArabicInput(
                "arabicDescription",
                "Arabic Description",
                "text",
                true
              )}
              {this.renderArabicInput(
                "arabicLocationName",
                "Arabic Location",
                "text",
                true
              )}
              {this.renderInput("endDate", "End Date", "date", true)}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <UploadZone
                onChange={this.handleChangeStatus}
                placeholder="Add Event Images"
              />
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <VideoUpload
                onChange={this.handleVideoChange}
                placeholder="Upload Video"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <LineGrid
                images={eventImages}
                onDelete={this.deleteImage}
                type="events"
              />
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.state.eventVideos.length > 0 && (
                <VideosContainer
                  videos={this.state.eventVideos}
                  type="events"
                  onDelete={this.handleVideoDelete}
                />
              )}
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
        {imageUploadPercentage === "" ||
        imageUploadPercentage === 100 ? null : (
          <Progress
            value={parseInt(imageUploadPercentage)}
            label="Uploading Images"
          />
        )}
        {videoUploadPercentage === "" ||
        videoUploadPercentage === 100 ? null : (
          <Progress
            value={parseInt(videoUploadPercentage)}
            label="Uploading Video"
          />
        )}
      </div>
    );
  }
}

export default EventsEditForm;
