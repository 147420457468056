import React from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Card, CardBody } from "reactstrap";

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LineChart = ({ datasets, options, width, height }) => {
  const data = {
    labels,
    datasets,
  };
  return (
    <Card>
      <CardBody>
        <Line options={options} width={width} height={height} data={data} />;
      </CardBody>
    </Card>
  );
};

export const BarChart = ({ datasets, options, width, height }) => {
  const data = {
    labels,
    datasets,
  };
  return (
    <Card>
      <CardBody>
        <Bar options={options} width={width} height={height} data={data} />;
      </CardBody>
    </Card>
  );
};

export const DonughtChart = ({
  datasets,
  options,
  width,
  height,
  label,
  data,
}) => {
  datasets = [
    {
      label,
      backgroundColor: [
        "#01a553",
        "#0D5C63",
        "#3E885B",
        "#88D498",
        "#17BEBB",
        "#2B303A",
        "#D5C67A",
        "#5B4B49",
        "#E0CA3C",
        "#C33C54",
        "#254E70",
        "#8F2D56",
      ],
      data,
    },
  ];

  const chartData = {
    labels,
    datasets,
  };

  return (
    <Card className="mt-4 shadow bg-white rounded">
      <CardBody>
        <Doughnut
          options={options}
          width={width}
          height={height}
          data={chartData}
        />
      </CardBody>
    </Card>
  );
};
