import React from "react";
import { getCurrency, saveCurrency } from "../../services/currencyService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";

class CurrencyForm extends Form {
  state = {
    sendingData: false,
    data: {
      donatable: "",
      englishId: "",
      arabicId: "",
      id: "",
      arabicName: "",
      englishName: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.label("id"),
    englishId: Joi.label("English id"),
    arabicId: Joi.label("Arabic id"),
    donatable: Joi.string()
      .required()
      .trim()
      .error(() => {
        return {
          message: "not allowed to be empty",
        };
      }),
    arabicName: Joi.string()
      .trim()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    englishName: Joi.string().required().trim().label("Name"),
  };

  componentDidMount() {
    this.populateCurrency();
  }

  async populateCurrency() {
    try {
      const currencyId = this.props.match.params.id;
      if (currencyId === "new") return;
      const { data: currency } = await getCurrency(currencyId);
      this.setState({ data: this.mapToViewModel(currency) });
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/currencies");
      toast.warn("No Such currency !");
    }
  }

  mapToViewModel(currency) {
    const arabic = currency.currencyTranslations.find(
      (trans) => trans.locale === "ar"
    );
    const english = currency.currencyTranslations.find(
      (trans) => trans.locale === "en"
    );

    return {
      id: currency.id,
      donatable: `${currency.donatable}`,
      englishId: english.id,
      arabicId: arabic.id,
      arabicName: arabic.name || "",
      englishName: english.name || "",
    };
  }

  handleCurrencySave = async (data) => {
    await saveCurrency(data);
    this.setState({ data: { ...data } });
    toast.success("Saved");
    setTimeout(() => {
      window.location.replace("/currencies");
    }, 3000);
  };

  doSubmit = async () => {
    const { data } = this.state;
    try {
      this.handleCurrencySave(data);
    } catch (ex) {
      this.setState({ data: { ...data } });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("englishName", "Name", "text", true)}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderArabicInput(
                "arabicName",
                "Arabic Name",
                "text",
                true
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderSelect(
                "donatable",
                "Can donate in this currency in Sudan",
                [
                  { name: "Yes", value: "true" },
                  { name: "No", value: "false" },
                ],
                true
              )}
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default CurrencyForm;
