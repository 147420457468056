import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";
import progress from "../utils/progress";

const apiEndpoint = config.globalApi + "/events";

function eventUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function eventApproveUrl(id) {
  return `${apiEndpoint}/approve/${id}`;
}

export function getEvents() {
  return http.get(apiEndpoint, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getEvent(eventId) {
  return http.get(eventUrl(eventId), {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveEvent(event, file, onPercentageChange) {
  const newEvent = {
    startDate: event.startDate,
    endDate: event.endDate,
    base64Images: event.base64Images,
    eventTranslations: [
      {
        locale: "en",
        name: event.englishName,
        description: event.englishDescription,
        locationName: event.englishLocationName,
      },
      {
        locale: "ar",
        name: event.arabicName,
        description: event.arabicDescription,
        locationName: event.arabicLocationName,
      },
    ],
  };

  const details = JSON.stringify(newEvent);
  const blob = new Blob([details], {
    type: "application/json",
  });

  const formData = new FormData();
  formData.append("file", file);
  formData.append("event", blob);

  if (event.id) {
    delete newEvent.base64Images;
    const english = { ...newEvent.eventTranslations[0], id: event.englishId };
    const arabic = { ...newEvent.eventTranslations[1], id: event.arabicId };
    const updatedEvent = { ...newEvent, eventTranslations: [english, arabic] };
    return http.put(eventUrl(event.id), updatedEvent, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
  return http.post(apiEndpoint, formData, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
    onUploadProgress: (progressEvent) =>
      onPercentageChange(progress(progressEvent)),
  });
}

export async function approveEvent(event) {
  await http.put(
    eventApproveUrl(event.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export async function updateEventImages(id, newImages, onPercentageChange) {
  await http.post(
    `${apiEndpoint}/image/add`,
    { id: id, base64Images: newImages },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    }
  );
}

export function addEventVideos(video, id, onPercentageChange) {
  const data = new FormData();
  data.append("file", video);

  return http.post(`${apiEndpoint}/upload-video/${id}`, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
      "Content-Type": "application/json",
    },
    onUploadProgress: (progressEvent) =>
      onPercentageChange(progress(progressEvent)),
  });
}

export function deleteEventImages(images) {
  return http.post(`${apiEndpoint}/image/delete`, images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function deleteEventVideo(videos) {
  return videos.forEach((video) => {
    http.post(`${apiEndpoint}/video/delete`, video, {
      headers: {
        Authorization: "Bearer " + getJwt(),
        "Content-Type": "application/json",
      },
    });
  });
}

export function deleteevent(eventId) {
  return http.delete(eventUrl(eventId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
