import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import DeleteDialog, { EditStepDialog } from "../../common/dialog";
import {
  addStepVideo,
  deleteStepImages,
  deleteStepVideo,
  updateProjectStep,
  updateStepImages,
} from "../../../services/projectsServices";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/EditOutlined";
import Editor from "../../common/editor";
import Joi from "joi";
import { fileToBase64 } from "../../../utils/filereader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  editButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function ProjectSteps(props) {
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [stepToDelete, setStepToDelete] = useState({});
  const [stepToEdit, setStepToEdit] = useState({});
  const [state, setState] = useState({
    englishDecsription: "",
    arabicDescription: "",
    arabicId: "",
    englishId: "",
    stepImages: [],
    stepVideos: [],
    files: [],
    base64Images: [],
    stepVideo: null,
    videosToDelete: [],
    imagesToDelete: [],
  });
  const [errors, setErrors] = useState({});

  const { onDelete, onStepChange, onStepUpdate } = props;
  let steps = props.steps;

  steps = steps.sort(function (a, b) {
    return b.id - a.id;
  });

  const validationSchema = Joi.object({
    arabicDescription: Joi.string()
      .required()
      .trim()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .message("Arabic characters only"),
    englishDecsription: Joi.string().required().trim().label("Description"),
  });

  const renderEditor = (name, label, language, required) => {
    return (
      <Editor
        language={language}
        label={label}
        name={name}
        value={state[name]}
        onChange={handleEditorChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const handleEditorChange = (editorData, name) => {
    const errs = { ...errors };
    const errorMessage = validateEditor(name, editorData);
    if (errorMessage) errs[name] = errorMessage;
    else delete errs[name];
    const updatedState = { ...state };
    updatedState[name] = editorData;
    setState(updatedState);
    setErrors(errs);
  };

  const validateEditor = (name, value) => {
    const obj = { [name]: value };
    const schema = Joi.object({
      [name]: validationSchema.extract(name),
    });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  const handleDeleteClose = () => {
    setStepToDelete({});
    setDeleteOpen(false);
  };

  const handleEditClose = () => {
    setState({
      ...state,
      files: [],
      arabicDescription: "",
      englishDecsription: "",
      stepVideo: null,
      imagesTodDelete: [],
      videosToDelete: [],
    });
    setStepToEdit({});
    setEditOpen(false);
  };

  const handleDropzoneChange = ({ meta, file }, status) => {
    const files = [...state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
    if (status === "done") {
      if (files.includes(file)) {
        return;
      }
      setState({ ...state, files: updatedFiles, base64Images: [] });
    }
    if (status === "removed") {
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
  };

  const handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      setState({ ...state, stepVideo: file });
    }
    if (status === "removed") {
      setState({ ...state, stepVideo: null });
    }
  };

  const handleImageDelete = (imageId) => {
    const originalImages = [...state.stepImages];
    const updatedImages = originalImages.filter((img) => img.id !== imageId);
    const imagesToDelete = [...state.imagesToDelete];
    imagesToDelete.push(imageId);
    setState({ ...state, stepImages: updatedImages, imagesToDelete });
  };

  const handleVideoDelete = (videoId) => {
    const originalVideos = [...state.stepVideos];
    const updatedVideos = originalVideos.filter((vid) => vid.id !== videoId);
    const videosToDelete = [...state.videosToDelete];
    videosToDelete.push(videoId);
    setState({ ...state, stepVideos: updatedVideos, videosToDelete });
  };

  const imageToBase64 = async (file) => {
    const data = { ...state };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    setState(data);
  };

  const handleSubmit = async () => {
    const { files } = state;
    try {
      for (let i = 0; i < files.length; i++) {
        imageToBase64(files[i]);
      }
      setTimeout(() => handleStepUpdate(state), 1000);
    } catch (ex) {
      setState({ ...state, base64Images: [] });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  const handleStepUpdate = async () => {
    const { imagesToDelete, videosToDelete, base64Images, stepVideo } = state;
    const stepId = stepToEdit.id;

    const data = {
      id: stepId,
      englishId: state.englishId,
      englishText: state.englishDecsription,
      arabicId: state.arabicId,
      arabicText: state.arabicDescription,
    };

    try {
      toast.info("updating step data", {
        position: "bottom-right",
        hideProgressBar: true,
        closeButton: false,
        autoClose: 1500,
      });
      if (stepVideo instanceof File) {
        await addStepVideo(stepVideo, stepId);
        onStepUpdate();
      }
      if (base64Images.length > 0) {
        await updateStepImages(base64Images, stepId);
        onStepUpdate();
      }

      await updateProjectStep(data).then((res) => onStepUpdate());
      if (imagesToDelete.length !== 0) {
        await deleteStepImages(imagesToDelete, stepId);
        const currentStep = { ...steps.find((s) => s.id == stepId) };
        const currentStepImages = currentStep.imageData;
        const updatedStepImages = currentStepImages.filter((el) => {
          return !imagesToDelete.includes(el.id);
        });
        currentStep.imageData = updatedStepImages;
        const updatedSteps = steps.filter((step) => step.id !== stepId);
        steps = [...updatedSteps, currentStep];
        onStepChange(steps);
        // onStepUpdate();
      }
      if (videosToDelete.length > 0) {
        await deleteStepVideo(videosToDelete, stepId);
        const currentStep = { ...steps.find((s) => s.id == stepId) };
        const currentStepVideos = currentStep.videoData;
        const updatedStepVideos = currentStepVideos.filter((video) => {
          return !videosToDelete.includes(video.id);
        });
        currentStep.videoData = updatedStepVideos;
        const updatedSteps = steps.filter((step) => step.id !== stepId);
        steps = [...updatedSteps, currentStep];
        onStepChange(steps);
        // onStepUpdate();
      }
      setState({
        ...state,
        imagesToDelete: [],
        videosToDelete: [],
        base64Images: [],
        stepImages: [],
        stepVideo: null,
      });
      toast.success("updated successfully");
    } catch (error) {
      toast.error("something went wrong");
      setState({
        ...state,
        imagesToDelete: [],
        videosToDelete: [],
        base64Images: [],
        stepImages: [],
        stepVideo: null,
      });
    }
  };

  const validateStepToEdit = () => {
    const { error } = validationSchema.validate(
      {
        englishDecsription: state.englishDecsription,
        arabicDescription: state.arabicDescription,
      },
      {
        abortEarly: false,
        allowUnknown: true,
        convert: true,
      }
    );
    return error ? true : false;
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <List>
              {steps.map((step, index) => (
                <ListItem key={step.id}>
                  <ListItemText primary={`Phase ${steps.length - index}`} />
                  <ListItemSecondaryAction>
                    <IconButton
                      className={classes.editButton}
                      aria-label="edit"
                      onClick={() => {
                        setStepToEdit(step);
                        setState({
                          ...state,
                          stepImages: step.imageData,
                          stepVideos: step.videoData,
                          englishId: step.stepTranslation.find(
                            (step) => step.locale === "en"
                          ).id,
                          englishDecsription: step.stepTranslation.find(
                            (step) => step.locale === "en"
                          ).text,
                          arabicId: step.stepTranslation.find(
                            (step) => step.locale === "ar"
                          ).id,
                          arabicDescription: step.stepTranslation.find(
                            (step) => step.locale === "ar"
                          ).text,
                        });
                        setEditOpen(true);
                      }}
                    >
                      <Edit color="primary" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setStepToDelete(step);
                        setDeleteOpen(true);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>

      <DeleteDialog
        dialogOpen={deleteOpen}
        handleClose={handleDeleteClose}
        item={stepToDelete}
        handleDelete={onDelete}
      />

      <EditStepDialog
        renderEditor={renderEditor}
        onClose={handleEditClose}
        onImageChange={handleDropzoneChange}
        onVideoChange={handleVideoChange}
        onVideoDelete={handleVideoDelete}
        onImageDelete={handleImageDelete}
        disableSubmitButton={validateStepToEdit}
        onSubmit={handleSubmit}
        open={editOpen}
        images={state.stepImages}
        videos={state.stepVideos}
        step={stepToEdit}
      />
    </>
  );
}
