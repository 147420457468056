import React from "react";
import PropTypes from "prop-types";

/** A list Container for videos with a delete icon for each video
 *
 * @component
 */
const VideosContainer = ({ videos, onDelete }) => {
  return (
    <div className="video-container">
      <ul className="video-list">
        {videos.map((video) => (
          <div className="video-wrapper" key={video.id || video.name}>
            <li className="video-list-item">
              <p>{video.name}</p>
              <span>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => onDelete(video.id)}
                >
                  Delete
                </button>
              </span>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default VideosContainer;

VideosContainer.propTypes = {
  /** The list of videos to be displayed */
  videos: PropTypes.array.isRequired,
  /** Handler function fired when the user deletes a video */
  onDelete: PropTypes.func.isRequired,
};
