import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import { isDataEntry, isAuthorizer } from "../../utils/checkRole";

class VacanciesTable extends Component {
  state = {
    vacancy: {},
    dialogOpen: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    if (this.state.redirect === false ) {
      return (
        <>
          <MaterialTable
            title="Vacancies Table"
            columns={[
              { title: "Title", field: "englishTitle" },
              { title: "العنوان", field: "arabicTitle" },
              { title: "End Date", field: "endDate" },
            ]}
            data={this.props.vacancies}
            // data={query =>
            //   new Promise((resolve, reject) => {
            //     let url = 'https://reqres.in/api/users?'
            //     url += 'per_page=' + query.pageSize
            //     url += '&page=' + (query.page + 1)
            //     axios.get(url)
            //       .then(result => {
            //         resolve({
            //           data: result.data.data,
            //           page: result.data.page - 1,
            //           totalCount: result.data.total,
            //         })
            //       })
            //   })
            // }
            actions={[
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit vacancy",
                onClick: (event, rowData) => {
                  this.handleRoute(rowData);
                },
              },
              {
                hidden: isDataEntry() || isAuthorizer(),
                icon: () => <DeleteIcon color="secondary" />,
                tooltip: "Delete vacancy",
                onClick: (event, rowData) =>
                  this.setState({ dialogOpen: true, vacancy: rowData }),
              },
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.vacancy}
            handleDelete={this.props.onDelete}
          />
        </>
      );
    }
    if (this.state.redirect === true) {
      return <Redirect to={`/vacancies/form/${this.state.id}`} />;
    }
  }
}

export default VacanciesTable;
