import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { fileToBase64, fileToDataUrl } from "../../../utils/filereader";
import { setCoverImages } from "../../../services/coverImages";
import { toast } from "react-toastify";
import { coverImage } from "../../../services/imageUrl";

/**
 * Render Covers Configuration Page
 */
class Covers extends Form {
  state = {
    data: {
      ABOUT1: "",
      ABOUT2: "",
      HEALTH_SECT: "",
      WATER_SECT: "",
      FEEDING_SECT: "",
      EDUCATION_SECT: "",
      COMPLETE_PROJECT: "",
      PROJECT2: "",
      PROJECT3: "",
      EVENT1: "",
      EVENT2: "",
      VOLUNTEER1: "",
      VOLUNTEER2: "",
      VOLUNTEER3: "",
      CARRIER1: "",
      DONATION: "",
    },
    errors: {},
    images: {},
  };

  schema = {
    ABOUT1: Joi.label("Image"),
    ABOUT2: Joi.label("Image"),
    HEALTH_SECT: Joi.label("Image"),
    WATER_SECT: Joi.label("Image"),
    FEEDING_SECT: Joi.label("Image"),
    EDUCATION_SECT: Joi.label("Image"),
    COMPLETE_PROJECT: Joi.label("Image"),
    PROJECT2: Joi.label("Image"),
    PROJECT3: Joi.label("Image"),
    EVENT1: Joi.label("Image"),
    EVENT2: Joi.label("Image"),
    VOLUNTEER1: Joi.label("Image"),
    VOLUNTEER2: Joi.label("Image"),
    VOLUNTEER3: Joi.label("Image"),
    CARRIER1: Joi.label("Image"),
    DONATION: Joi.label("Image"),
  };

  componentDidUpdate(prevProps, prevState) {
    const images = {};
    let rerender = false;
    Object.keys(this.state.images).forEach((image) => {
      if (this.state.images[image]) {
        images[image] = false;
        rerender = true;
      }
    });
    if (rerender) {
      this.setState({ images });
    }
  }

  handleChangeImage = async (evt, name) => {
    /* calling event.persist to save prevent event pooling => setting the event to null after executing the handler */
    evt.persist();
    try {
      const file = evt.target.files[0];
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        toast.warn("Please select a png/jpeg image");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, [name]: "" } });
        return;
      }

      const base64 = await fileToDataUrl(file);
      const image = new Image();
      image.src = base64;
      image.onload = async () => {
        if (image.width < 1350 || image.height < 440) {
          toast.warn(`image should be at least  ${1350}x${440} pixels`);
          evt.target.type = "text";
          evt.target.type = "file";
          this.setState({ data: { ...this.state.data, [name]: "" } });
        } else {
          const b64 = await fileToBase64(file);
          const data = { ...this.state.data };
          data[name] = b64;
          this.setState({ data });
        }
      };
    } catch (error) {
      return;
    }
  };

  doSubmit = async () => {
    const { data } = this.state;
    try {
      await setCoverImages(data);
      const images = {};
      Object.keys(this.state.data).forEach((image) => {
        if (this.state.data[image]) {
          images[image] = true;
        } else {
          images[image] = false;
        }
      });
      this.setState({
        sendingData: false,
        images,
        data: {
          ABOUT1: "",
          ABOUT2: "",
          HEALTH_SECT: "",
          WATER_SECT: "",
          FEEDING_SECT: "",
          EDUCATION_SECT: "",
          COMPLETE_PROJECT: "",
          PROJECT2: "",
          PROJECT3: "",
          EVENT1: "",
          EVENT2: "",
          VOLUNTEER1: "",
          VOLUNTEER2: "",
          VOLUNTEER3: "",
          CARRIER1: "",
          DONATION: "",
        },
      });
      toast.success("Updated Successfully");
    } catch (ex) {
      this.setState({ sendingData: false });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.url);
      }
    }
  };

  FileInput = ({ name, label }) => {
    return (
      <>
        <label htmlFor={name} style={{ display: "block", marginTop: 5 }}>
          {label}
        </label>
        <input
          accept=".png, .jpg, .jpeg"
          type="file"
          name={name}
          className="upload-file btn btn-success"
          onChange={(e) => this.handleChangeImage(e, name)}
        />
      </>
    );
  };

  Thumbnail = ({ name }) => {
    console.log(this.state.images[name]);
    return (
      <img
        key={this.state.images[name] ? Date.now() : null}
        src={coverImage(name)}
        alt=""
        height="50px"
        width="50px"
        style={{ marginLeft: 10 }}
      />
    );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <this.FileInput name="ABOUT1" label="About Us" />
            <this.Thumbnail name="ABOUT1" />

            <this.FileInput name="HEALTH_SECT" label="Health Sector" />
            <this.Thumbnail name="HEALTH_SECT" />

            <this.FileInput name="EDUCATION_SECT" label="Education Sector" />
            <this.Thumbnail name="EDUCATION_SECT" />

            <this.FileInput
              name="COMPLETE_PROJECT"
              label="Completed Projects"
            />
            <this.Thumbnail name="COMPLETE_PROJECT" />

            <this.FileInput name="PROJECT2" label="Ongoing Projects" />
            <this.Thumbnail name="PROJECT2" />

            <this.FileInput name="EVENT1" label="News" />
            <this.Thumbnail name="EVENT1" />

            <this.FileInput name="VOLUNTEER2" label="Volunteers Form" />
            <this.Thumbnail name="VOLUNTEER2" />

            <this.FileInput name="CARRIER1" label="Carrier" />
            <this.Thumbnail name="CARRIER1" />
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <this.FileInput name="ABOUT2" label="Contact" />
            <this.Thumbnail name="ABOUT2" />

            <this.FileInput name="WATER_SECT" label="Water Sector" />
            <this.Thumbnail name="WATER_SECT" />

            <this.FileInput name="FEEDING_SECT" label="Feeding Sector" />
            <this.Thumbnail name="FEEDING_SECT" />

            <this.FileInput name="PROJECT3" label="Planned Projects" />
            <this.Thumbnail name="PROJECT3" />

            <this.FileInput name="EVENT2" label="Events" />
            <this.Thumbnail name="EVENT2" />

            <this.FileInput name="VOLUNTEER1" label="About Volunteers" />
            <this.Thumbnail name="VOLUNTEER1" />

            <this.FileInput name="VOLUNTEER3" label="Volunteers Programs" />
            <this.Thumbnail name="VOLUNTEER3" />

            <this.FileInput name="DONATION" label="Donation" />
            <this.Thumbnail name="DONATION" />
          </div>
        </div>
        {this.renderButton("Update")}
      </form>
    );
  }
}

export default Covers;
