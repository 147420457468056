import React from "react";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Grid } from "@material-ui/core";
import { galleryImage } from "../../../services/imageUrl";

const GalleryGrid = ({ images, onDelete }) => {
  return (
    <Grid container spacing={2}>
      {images.map((img) => (
        <Grid
          className="gallery-image-col"
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={img.id}
        >
          <img
            className="gallery_image"
            src={galleryImage(img.imageName)}
            alt=""
          />
          <DeleteIcon
            className="gallery_delete_icon"
            color="secondary"
            onClick={() => onDelete(img)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GalleryGrid;
