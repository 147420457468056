import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";
import progress from "../utils/progress";

const apiEndpoint = config.globalApi + "/gallary";

export function getGalleryImages() {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function addGalleryImage(base64, onPercentageChange) {
  return http.post(
    `${apiEndpoint}/add`,
    { base64Image: base64 },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    }
  );
}

export function getGalleryImage(imgName) {
  return http.get(`${apiEndpoint}/${imgName}/image`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteGalleryImage(imgId) {
  return http.delete(`${apiEndpoint}/delete/${imgId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
