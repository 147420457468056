import React, { Component } from "react";
import Joi from "joi-browser";
import Select from "./select";
import TextArea, { ArabicTextArea } from "./textarea";
import Input from "./input";
import CheckBox from "./checkbox";
import { ArabicInput } from "./input";
import { ArabicSelect } from "./select";
import Editor from "./editor";
import PropTypes from "prop-types";

/**
 * Render dashboard's form fields
 * @component
 */
class Form extends Component {
  constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
  }

  state = {
    sendingData: true,
    data: {},
    errors: {},
  };

  static propTypes = {
    /** element name */
    name: PropTypes.string.isRequired,
    /** element label */
    label: PropTypes.string.isRequired,
    /** html attribute input type*/
    type: PropTypes.string.isRequired,
    /** html attribute required */
    required: PropTypes.bool.isRequired,
    /** options for select element*/
    options: PropTypes.array.isRequired,
    /** ckeditor's toolbar */
    toolbar: PropTypes.array.isRequired,
    /** Editor's language*/
    language: PropTypes.string.isRequired,
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    let obj = { [name]: value };
    let schema = { [name]: this.schema[name] };

    let { error } = Joi.validate(obj, schema, { convert: true });
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.setState({ sendingData: true });
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const res = this.validateProperty(input);

    if (res) errors[input.name] = res;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleEditorChange = (editorData, name) => {
    const errors = { ...this.state.errors };
    const response = this.validateEditor(name, editorData);

    if (
      name === "englishFormatedDescription" ||
      name === "arabicFormatedDescription"
    ) {
      if (name === "englishFormatedDescription") {
        if (response) {
          if (response.length === 1) {
            if (response[0].path[0] === "englishFormatedDescription") {
              errors[name] = response[0].message.replace(/[""]/g, "");
            }
            if (response[0].path[0] === "arabicFormatedDescription") {
              delete errors[name];
            }
          }
          if (response.length === 2) {
            if (response[0].path[0] === "englishFormatedDescription") {
              errors[name] = response[0].message.replace(/[""]/g, "");
            }
            if (response[0].path[0] === "arabicFormatedDescription") {
              errors[name] = response[1].message.replace(/[""]/g, "");
            }
          }
        } else {
          delete errors[name];
          delete errors["arabicFormatedDescription"];
        }
        const data = { ...this.state.data };
        data[name] = editorData;
        this.setState({ data, errors });
      } else if (name === "arabicFormatedDescription") {
        if (response) {
          if (response.length === 1) {
            if (response[0].path[0] === "arabicFormatedDescription") {
              errors[name] = response[0].message.replace(/[""]/g, "");
            }
            if (response[0].path[0] === "englishFormatedDescription") {
              delete errors[name];
            }
          }
          if (response.length === 2) {
            if (response[0].path[0] === "arabicFormatedDescription") {
              errors[name] = response[0].message.replace(/[""]/g, "");
            }
            if (response[0].path[0] === "englishFormatedDescription") {
              errors[name] = response[1].message.replace(/[""]/g, "");
            }
          }
        } else {
          delete errors[name];
          delete errors["englishFormatedDescriptions"];
        }
        const data = { ...this.state.data };
        data[name] = editorData;
        this.setState({ data, errors });
      }
    } else {
      if (response) errors[name] = response;
      else delete errors[name];

      const data = { ...this.state.data };
      data[name] = editorData;
      this.setState({ data, errors });
    }
  };

  validateEditor = (name, value) => {
    let obj = { [name]: value };
    let schema = { [name]: this.schema[name] };

    if (name === "englishFormatedDescription") {
      obj = {
        [name]: value,
        arabicFormatedDescription: this.state.data.arabicFormatedDescription,
      };

      schema = {
        [name]: this.schema[name],
        arabicFormatedDescription: this.schema["arabicFormatedDescription"],
      };

      const { error } = Joi.validate(obj, schema, {
        abortEarly: false,
      });
      return error ? error.details : null;
    }

    if (name === "arabicFormatedDescription") {
      obj = {
        [name]: value,
        englishFormatedDescription: this.state.data.englishFormatedDescription,
      };

      schema = {
        [name]: this.schema[name],
        englishFormatedDescription: this.schema["englishFormatedDescription"],
      };

      const { error } = Joi.validate(obj, schema, {
        abortEarly: false,
      });
      return error ? error.details : null;
    }

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  renderButton(label) {
    return (
      <div className="text-center">
        <button
          disabled={
            this.validate() ||
            (this.validate() === null && this.state.sendingData === true)
          }
          className="btn btn-outline-success mt-3"
          style={{ borderRadius: "20px" }}
        >
          {label}
        </button>
      </div>
    );
  }

  renderSelect(name, label, options, required) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
      />
    );
  }

  renderArabicSelect(name, label, options, required) {
    const { data, errors } = this.state;

    return (
      <ArabicSelect
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        required={required}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, type, required) {
    const { data, errors } = this.state;
    if (type === "date") {
      return (
        <Input
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          max="9999-12-31"
          required={required}
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
      />
    );
  }

  renderArabicInput(name, label, type, required) {
    const { data, errors } = this.state;
    if (type === "date") {
      return (
        <ArabicInput
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          max="9999-12-31"
          required={required}
        />
      );
    }
    return (
      <ArabicInput
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
      />
    );
  }

  renderTextArea(name, label, required) {
    const { data, errors } = this.state;

    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        rows="3"
        onChange={this.handleChange}
        required={required}
        error={errors[name]}
      />
    );
  }

  renderArabicTextArea(name, label, required) {
    const { data, errors } = this.state;
    return (
      <ArabicTextArea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        required={required}
        error={errors[name]}
      />
    );
  }

  renderEditor(name, label, language, required, toolbar) {
    const { data, errors } = this.state;
    return (
      <Editor
        language={language}
        label={label}
        name={name}
        value={data[name]}
        onChange={this.handleEditorChange}
        required={required}
        error={errors[name]}
        toolbar={toolbar}
      />
    );
  }
}
/** @component */
export default Form;
