import React from "react";

const FirstStep = ({
  renderInput,
  renderArabicInput,
  renderSelect,
  renderCheckbox,
  subhubOptions,
  currencyOptions,
}) => {
  return (
    <div className="card-body m-3 shadow p-3 bg-white rounded">
      <div className="row">
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          {renderInput("nameEnglish", "Name", "text", true)}
          {renderSelect(
            "statusEnglish",
            "Status",
            [
              { name: "ongoing", value: "ongoing" },
              { name: "completed", value: "completed" },
              { name: "planned", value: "planned" },
            ],
            true
          )}
          {renderInput("raised", "Raised", "number", false)}
          {renderSelect("subHubId", "Sub Hub", subhubOptions, true)}
          {renderSelect("currencyId", "Currency", currencyOptions, true)}
          {renderCheckbox("pinToHome", "Pin To Home", false)}
        </div>
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          {renderArabicInput("nameArabic", "Arabic Name", "text", true)}
          {renderSelect(
            "statusArabic",
            "Arabic Status",
            [
              { name: "قيد العمل", value: "ongoing" },
              { name: "مكتمل", value: "completed" },
              { name: "مخطط", value: "planned" },
            ],
            true
          )}
          {renderInput("goal", "Goal", "number", false)}
          {renderInput("projectProgress", "Project Progress", "number", true)}
          {renderInput("startAt", "Date", "date", true)}
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
