import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/vacancies";

function getVacancyUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function addVacancy(data) {
  const vacancy = {
    endDate: data.endDate,
    url: data.url ? data.url : null,
    vacancieTranslation: [
      {
        id: data.englishId,
        title: data.title,
        description: data.description,
        locale: "en",
      },
      {
        id: data.arabicId,
        title: data.arabicTitle,
        description: data.arabicDescription,
        locale: "ar",
      },
    ],
  };

  if (data.id) {
    return http.post(getVacancyUrl(data.id), vacancy, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
  return http.post(apiEndpoint, vacancy, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getVacancy(id) {
  return http.get(getVacancyUrl(id));
}

export function getAllVacancies() {
  return http.get(`${apiEndpoint}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
    params: {
      page: 0,
      size: 100,
      searchQuery: "",
    },
  });
}

export function deleteVacancy(vacancyId) {
  return http.delete(getVacancyUrl(vacancyId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
