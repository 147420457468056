import config from "../config.json";

export function hubImage(id) {
  return `${config.globalApi}/hubs/${id}/image`;
}

export function subhubImage(id) {
  return `${config.globalApi}/subHubs/${id}/image`;
}

export function projectImage(id) {
  parseInt(id);
  return `${config.globalApi}/projects/${id}/image`;
}

export function programImage(id) {
  parseInt(id);
  return `${config.globalApi}/programs/${id}/image`;
}

export function contactImage(id) {
  return `${config.globalApi}/contactUsInfos/${id}/image`;
}

export function newsImage(id) {
  return `${config.globalApi}/news/${id}/image`;
}

export function newsImages(id) {
  parseInt(id);
  return `${config.globalApi}/news/${id}/image`;
}

export function eventImage(id) {
  parseInt(id);
  return `${config.globalApi}/events/${id}/image`;
}

export function volunteerImages(id) {
  return `${config.globalApi}/voulenter-page/${id}/image`;
}

export function stepImage(id) {
  return `${config.globalApi}/projects/step/${id}/image`;
}

export function sliderImage(imageName) {
  return `${config.globalApi}/slider/${imageName}/image`;
}

export function galleryImage(imageName) {
  return `${config.globalApi}/gallary/${imageName}/image`;
}

export function partnerIamge(imageName) {
  return `${config.globalApi}/partener/${imageName}/image`;
}

export function coverImage(name) {
  return `${config.globalApi}/cover-image/${name}`;
}
