import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/contact-info";

export function getContactInfoSd() {
  return http.get(`${apiEndpoint}/SD`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getContactsInfoUk() {
  return http.get(`${apiEndpoint}/UK`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function updateContactUs(data, type) {
  const contactInfo = {
    email: data.email,
    phone: data.phone,
    facebook: data.facebook,
    linkedin: data.linkedin,
    youtube: data.youtube,
    instagram: data.instagram,
    twitter: data.twitter,
    website: data.website,
    location: {
      lat: data.locationLat,
      lng: data.locationLng,
      locationTranslation: [
        {
          name: data.englishLocationName,
          locale: "en",
        },
        {
          name: data.arabicLocationName,
          locale: "ar",
        },
      ],
    },
  };

  switch (type) {
    case "sd":
      return http.post(`${apiEndpoint}/SD`, contactInfo, {
        headers: {
          Authorization: "Bearer " + getJwt(),
        },
      });
    case "uk":
      return http.post(`${apiEndpoint}/UK`, contactInfo, {
        headers: {
          Authorization: "Bearer " + getJwt(),
        },
      });
    default:
      break;
  }
}
