import React, { Component } from "react";
import DonationCard from "../../common/cards";
import { gethHub, gethHubProjects } from "../../../services/hubServices";
import { DonughtChart } from "../../common/charts";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import Loader from "../../common/loader";
import { hubImage } from "../../../services/imageUrl";
import { sadagaatAccount } from "../../../services/donationService";
import { getProjects } from "../../../services/projectsServices";

class SingleHUbDonation extends Component {
  state = {
    hub: {},
    hubId: "",
    projects: [],
    data: [1, 3, 5, 6, 8, 9, 8, 6, 5, 4, 3, 2],
    loading: true,
  };

  async componentDidMount() {
    const hubId = this.props.match.params.id;
    try {
      let hub;
      let projects;
      if (hubId === "sadagaat") {
        const { data: sadagaat } = await sadagaatAccount();
        const { data: allProjects } = await getProjects();
        hub = { ...sadagaat, available: sadagaat.availableAmount };
        projects = allProjects;
      } else {
        const { data } = await gethHub(hubId);
        const { data: hubprojects } = await gethHubProjects(hubId);
        hub = data;
        projects = hubprojects;
      }
      this.setState({ hub, projects, loading: false, hubId });
    } catch (error) {
      toast.error("something went wrong");
    }
  }

  handleRefund = () => {
    toast.info("call api service ");
  };

  render() {
    const { hub, data, loading, hubId } = this.state;
    if (loading) {
      return <Loader />;
    }

    return (
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <DonughtChart
              data={data}
              height={245}
              options={{ maintainAspectRatio: false }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <DonationCard
              image={hubImage(hub.id)}
              btn_text="Donate To Project"
              amount={hub.available}
              title={hub.name}
              path={`/donations/hub/form/${hubId}`}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SingleHUbDonation;
