import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  projectImage,
  eventImage,
  newsImages,
  volunteerImages,
  stepImage,
  programImage,
} from "../../services/imageUrl";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

/**
 * A component to display an image grid with a delete icon on each image
 * @component
 */
const LineGrid = ({ images, onDelete, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2.5}>
        {images.map((img) => (
          <GridListTile key={img.id}>
            <img
              className={classes.image}
              src={
                type === "events"
                  ? eventImage(img.name)
                  : type === "projects"
                  ? projectImage(img.name)
                  : type === "volunteer"
                  ? volunteerImages(img.name)
                  : type === "steps"
                  ? stepImage(img.name)
                  : type === "programs"
                  ? programImage(img.name)
                  : newsImages(img.name)
              }
              alt=""
            />
            <GridListTileBar
              actionIcon={
                <IconButton
                  aria-label={`star ${img.name}`}
                  onClick={() => onDelete(img.id)}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export default LineGrid;

LineGrid.propTypes = {
  /** list of images to display */
  images: PropTypes.array.isRequired,
  /** Handler functipon for the delete operation */
  onDelete: PropTypes.func.isRequired,
  /** The type of images to be rendered - events,volunteer,steps,programs and projects */
  type: PropTypes.oneOf([
    "events",
    "volunteer",
    "steps",
    "programs",
    "projects",
  ]).isRequired,
};
