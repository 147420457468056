import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import { toast } from "react-toastify";
import { getVideoConfig, setVideoConfig } from "../../../services/videoService";

/**
 * Render Video Configuration Page
 */
class VideoConfig extends Form {
  state = {
    errors: {},
    data: {
      videoTitle: "",
      arabicVideoTitle: "",
      videoUrl: "",
    },
  };

  schema = {
    arabicVideoTitle: Joi.string()
      .trim()
      .required()
      .label("Arabic Video Title")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required, starts in Arabic",
        };
      }),
    videoTitle: Joi.string()
      .trim()
      .required()
      .regex(/^[A-Za-z\s\d!@#‘’$%^&*“”()_+\-=[\]{};':"\\|,.<>…/`~?]*$/)
      .label("Video Title")
      .error(() => {
        return {
          message: "required and english characters only",
        };
      }),
    videoUrl: Joi.string()
      .regex(
        /(?:http|https):\/\/((?:[\w-]+)(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
      )
      .trim()
      .required()
      .label("Video Url")
      .error(() => {
        return {
          message: "please enter a valid url",
        };
      }),
  };

  mapToConfigState({ translation, link }) {
    return {
      videoTitle: translation.length ? translation[0].text : "",
      arabicVideoTitle: translation.length ? translation[1].text : "",
      videoUrl: link || "",
    };
  }

  mapToConfigState = ({ translation, link }) => {
    return {
      videoTitle: translation.find((item) => item.locale === "en")?.text || "",
      arabicVideoTitle:
        translation.find((item) => item.locale === "ar")?.text || "",
      videoUrl: link || "",
    };
  };

  async componentDidMount() {
    try {
      const { data } = await getVideoConfig();
      if (!data) return;
      this.setState({ data: this.mapToConfigState(data) });
    } catch (error) {
      return;
    }
  }

  doSubmit = async () => {
    try {
      await setVideoConfig(this.state.data);
      toast.success("video configuration updated");
    } catch (error) {
      toast.error("something went wrong !");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderInput("videoTitle", "Video Title", "text", true)}
            {this.renderInput("videoUrl", "Video URL", "text", true)}
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderArabicInput(
              "arabicVideoTitle",
              "Arabic Video Title",
              "text",
              true
            )}
          </div>
        </div>
        {this.renderButton("Update")}
      </form>
    );
  }
}

export default VideoConfig;
