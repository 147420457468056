import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import VacanciesTable from "./vacanciesTable";
import { deleteVacancy, getAllVacancies } from "../../services/vacancies";

/**
 * Fetch Vacancies from API and Display Vacancies table, Add new Vacancy
 * @component
 */
const Vacancies = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vacancies, setVacancies] = useState([]);

  const fetchVacancies = async () => {
    try {
      const { data: vacancies } = await getAllVacancies();
      setVacancies(vacancies.data);
      setIsLoading(false);
    } catch (error) {
      toast.error("Could not fetch vacancies from server");
    }
  };

  useEffect(() => {
    fetchVacancies();
  }, []);

  const handleVacancyDelete = async (vacancy) => {
    const originalVacancies = [...vacancies];
    const vs = originalVacancies.filter((v) => v.id !== vacancy.id);
    setVacancies(vs);

    try {
      const res = await deleteVacancy(vacancy.id);
      if (res) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/vacancies");
      setVacancies(originalVacancies);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Link
        to="/vacancies/form/new"
        className="btn btn-sm btn-outline-success m-2"
        style={{ marginBottom: 20 }}
      >
        Add Vacancy
      </Link>
      <VacanciesTable vacancies={vacancies} onDelete={handleVacancyDelete} />
    </div>
  );
};

export default Vacancies;
