import React, { Component } from "react";
import { getHubs } from "../../services/hubServices";
import { getSubhubs } from "../../services/subhubsServices";
import { getProjects } from "../../services/projectsServices";
import { makeDonation } from "../../services/donationService";
import isEmpty from "../../utils/objectEmpty";
import { toast } from "react-toastify";

class SadagaatForm extends Component {
  state = {
    originalHubs: [],
    originaSublhubs: [],
    originalProjects: [],
    hubs: [],
    subhubs: [],
    projects: [],
    hubOptions: [],
    subhubOptions: [],
    projectOptions: [],
    project: {},
    donateToSadagaat: false,
    hubId: "",
    subhubId: "",
    projectId: "",
    amount: "",
  };

  _hub = React.createRef();
  _subHub = React.createRef();
  _project = React.createRef();
  _amount = React.createRef();

  async componentDidMount() {
    try {
      const { data: originalHubs } = await getHubs();
      const hubOptions = this.mapToOptions(originalHubs);
      const { data: originaSublhubs } = await getSubhubs();
      const subhubOptions = this.mapToOptions(originaSublhubs);
      const { data: projects } = await getProjects();
      const originalProjects = projects.filter(
        (project) => project.donationProgress < 100
      );
      const projectOptions = this.mapToOptions(originalProjects);
      this.setState({
        hubOptions,
        subhubOptions,
        projectOptions,
        originaSublhubs,
        originalHubs,
        originalProjects,
        hubs: originalHubs,
        subhubs: originaSublhubs,
        projects: originalProjects,
      });
    } catch (ex) {
      toast.error("Something went wrong");
    }
  }

  mapToOptions = (array) => {
    return array.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
  };

  // Handling Hub Selection
  handleHubChange = async (event) => {
    const hubId = event.target.value;
    if (hubId === "") {
      const subhubs = [...this.state.originaSublhubs];
      const projects = [...this.state.originalProjects];
      const projectOptions = this.mapToOptions(projects);
      const subhubOptions = this.mapToOptions(subhubs);
      this.setState({ subhubOptions, projectOptions, hubId: "" });
    } else {
      const subhubs = [...this.state.subhubs];
      let filteredSubHubs = subhubs.filter((subhub) => subhub.hubId == hubId);
      let subhubOptions = this.mapToOptions(filteredSubHubs);
      let projects = [...this.state.originalProjects];
      projects = projects.filter((project) => project.hubId == hubId);
      const projectOptions = this.mapToOptions(projects);
      this.setState({
        projects,
        subhubOptions,
        hubId,
        projectOptions,
        subhubId: "",
        projectId: "",
      });
    }
  };

  // Handling Subhub Selection
  handleSubhubChange = (event) => {
    const subhubId = event.target.value;
    if (subhubId === "" && this.state.hubId === "") {
      const projects = [...this.state.originalProjects];
      let projectOptions = this.mapToOptions(projects);
      this.setState({ projectOptions });
    } else {
      const projects = [...this.state.originalProjects];
      let filteredProjects = projects.filter(
        (project) => project.subHubId == subhubId
      );
      let projectOptions = this.mapToOptions(filteredProjects);
      this.setState({ subhubId, projectOptions, projectId: "" });
    }
  };

  // Handling Project Selection

  handleProjectChange = (event) => {
    const project = [...this.state.originalProjects].find(
      (project) => project.id == event.target.value
    );
    const projectId = event.target.value;
    if (projectId === "") {
      this.setState({ projectId: "", project: {} });
    } else {
      this.setState({ projectId, project });
    }
  };

  // Handling Amount Selection

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value });
  };

  handleFormClear = () => {
    this._hub.current.value = "";
    this._subHub.current.value = "";
    this._project.current.value = "";
    this._amount.current.value = "";
  };

  // Handling Form Submission

  handleSubmit = async (event) => {
    event.preventDefault();
    const { projectId, subhubId, hubId, amount, donateToSadagaat } = this.state;
    try {
      await makeDonation(amount, hubId, subhubId, projectId, donateToSadagaat);
      this.setState({ amount: "", subhubId: "", hubId: "", projectId: "" });
      this.handleFormClear();
      toast.success("Donation Successful");
    } catch (error) {
      toast.error("Donation Unsuccessful");
    }
  };

  render() {
    const { hubOptions, subhubOptions, projectOptions, project } = this.state;
    const max = isEmpty(project) ? "" : project.goal - project.raised;
    return (
      <div className="card text-center mx-auto mt-5 px-5 py-3 w-50 rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="Hubs">Hub</label>
            <select
              ref={this._hub}
              className="form-control"
              id="Hubs"
              placeholder="Select Hub "
              aria-describedby="Hubs"
              onChange={this.handleHubChange}
            >
              <option value=""></option>
              {hubOptions}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="Subhub">Subhub</label>
            <select
              ref={this._subHub}
              className="form-control"
              id="Subhub"
              onChange={this.handleSubhubChange}
            >
              <option value=""></option>
              {subhubOptions}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="Project">Project</label>
            <select
              ref={this._project}
              className="form-control"
              id="Project"
              onChange={this.handleProjectChange}
            >
              <option value=""></option>
              {projectOptions}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              ref={this._amount}
              type="number"
              className="form-control"
              max={max}
              min="1"
              id="amount"
              required
              onChange={this.handleAmountChange}
            />
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default SadagaatForm;
