import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import FirstStep from "./step1";
import SecondStep from "./step2";
import ThirdStep from "./step3";
import FourthStep from "./step4";
import Review from "../review";
import Editor from "../../common/editor";
import Joi from "joi";
import Input, { ArabicInput } from "../../common/input";
import Select, { ArabicSelect } from "../../common/select";
import { getValidationSchema, validateCurrentStep } from "./validations";
import { toast } from "react-toastify";
import { fileToBase64 } from "../../../utils/filereader";
import { saveProject } from "../../../services/projectsServices";
import { getSubhubs } from "../../../services/subhubsServices";
import { getActiveCurrencies } from "../../../services/currencyService";
import CheckBox from "../../common/checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  navButtons: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

function getSteps() {
  return ["Information", "Description", "Media", "location", "Review"];
}

export default function MultiStepForm() {
  // ******** STATE & SIDE EFFECTS ************ //
  const classes = useStyles();
  const steps = getSteps();
  const [sendingData, setSendingData] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [subhubOptions, setSubhubOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [state, setState] = useState({
    currencyId: "",
    nameArabic: "",
    descriptionArabic: "",
    nameEnglish: "",
    descriptionEnglish: "",
    locationNameArabic: "",
    locationNameEnglish: "",
    pinToHome: false,
    locationLng: "",
    locationLat: "",
    goal: "",
    projectProgress: "",
    statusArabic: "",
    statusEnglish: "",
    raised: "",
    subHubId: "",
    base64Images: [],
    base64Image: "",
    startAt: "",
    percentage: 0,
    file: "",
    files: [],
  });
  const [errors, setErrors] = useState({});
  const fetchSubhub = async () => {
    try {
      const { data: subhubs } = await getSubhubs();
      const subhubOptions = subhubs.map((subhub) => ({
        name: subhub.name,
        value: subhub.id,
      }));
      setSubhubOptions(subhubOptions);
    } catch (error) {
      setSubhubOptions([]);
    }
  };
  const fetchCurrencies = async () => {
    try {
      const { data: currencies } = await getActiveCurrencies();
      const currencyOptions = currencies.map((currency) => ({
        name: currency.currencyTranslations[0].name,
        value: currency.id,
      }));
      setCurrencyOptions(currencyOptions);
    } catch (error) {
      setCurrencyOptions([]);
    }
  };
  useEffect(() => {
    fetchSubhub();
    fetchCurrencies();
  }, []);
  // ******** END OF STATE & SIDE EFFECTS ************ //

  // ********* RENDERING INPUTS ************* //
  const renderEditor = (name, label, language, required) => {
    return (
      <Editor
        language={language}
        label={label}
        name={name}
        value={state[name]}
        onChange={handleEditorChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderCheckbox = (name, label, required) => {
    return (
      <CheckBox
        name={name}
        checked={state[name]}
        label={label}
        onChange={handleCheckboxChange}
        error={errors[name]}
        required={required}
      />
    );
  };

  const renderInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <Input
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max="9999-12-31"
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicInput = (name, label, type, required) => {
    if (type === "date") {
      return (
        <ArabicInput
          type={type}
          name={name}
          value={state[name]}
          label={label}
          onChange={handleInputChange}
          required={required}
          error={errors[name]}
          max="9999-12-31"
        />
      );
    }
    return (
      <ArabicInput
        type={type}
        name={name}
        value={state[name]}
        label={label}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderSelect = (name, label, options, required) => {
    return (
      <Select
        name={name}
        value={state[name]}
        label={label}
        options={options}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  const renderArabicSelect = (name, label, options, required) => {
    return (
      <ArabicSelect
        name={name}
        value={state[name]}
        label={label}
        options={options}
        onChange={handleInputChange}
        required={required}
        error={errors[name]}
      />
    );
  };

  // ******** END OF RENDERING INPUTS ********** //

  // ********** EVENT HANDLERS **************** //
  const handleInputChange = ({ currentTarget: input }) => {
    const errs = { ...errors };
    const res = validateProperty(input);

    if (input.name === "goal") {
      if (res) {
        if (res.length === 1) {
          if (res[0].path[0] === "goal") {
            errs[input.name] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["goal"];
            }
          }
          if (res[0].path[0] === "raised") {
            errs["raised"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["raised"];
            }
            delete errs[input.name];
          }
        }
        if (res.length === 2) {
          if (res[0].path[0] === "goal") {
            errs[input.name] = res[0].message.replace(/[""]/g, "");
            errs["raised"] = res[1].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["raised"];
            }
          }
          if (res[0].path[0] === "raised") {
            errs[input.name] = res[1].message.replace(/[""]/g, "");
            errs["raised"] = res[0].message.replace(/[""]/g, "");
            if (res[0].type === "number.base") {
              delete errs["goal"];
            }
          }
        }
      } else {
        delete errs[input.name];
        delete errs["raised"];
      }
      const data = { ...state };
      data[input.name] = input.value;
      setState(data);
      setErrors(errs);
    }

    if (input.name !== "goal") {
      if (res) errs[input.name] = res;
      else delete errs[input.name];
      const data = { ...state };
      data[input.name] = input.value;
      setState(data);
      setErrors(errs);
    }
  };

  const handleCheckboxChange = ({ target: checkbox }) => {
    const errs = { ...errors };
    const res = validateProperty({
      name: checkbox.name,
      value: checkbox.checked,
    });
    if (res) errors[checkbox.name] = res;
    else delete errors[checkbox.name];
    const data = { ...state };
    data[checkbox.name] = checkbox.checked;
    setState(data);
    setErrors(errs);
  };

  const handleDropzoneChange = ({ meta, file }, status) => {
    const files = [...state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
    if (status === "done") {
      if (files.includes(file)) {
        return;
      }
      setState({ ...state, files: updatedFiles, base64Images: [] });
    }
    if (status === "removed") {
      setState({ ...state, files: filteredFiles, base64Images: [] });
    }
  };

  const handleEditorChange = (editorData, name) => {
    const errs = { ...errors };
    const errorMessage = validateEditor(name, editorData);
    if (errorMessage) errs[name] = errorMessage;
    else delete errs[name];
    const updatedState = { ...state };
    updatedState[name] = editorData;
    setState(updatedState);
    setErrors(errs);
  };

  const handleMapChange = (lat, lng) => {
    setState({
      ...state,
      locationLat: lat,
      locationLng: lng,
    });
  };

  const handleMainImageChange = async (evt) => {
    try {
      let file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
      } else {
        const updatedState = { ...state };
        const base64 = await fileToBase64(file);
        updatedState.base64Image = base64;
        updatedState.file = file;
        setState(updatedState);
      }
    } catch (error) {
      return;
    }
  };

  const handleNext = () => {
    if (activeStep === 4) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
  const handleButtonDisable = () => {
    const errors = validateCurrentStep(activeStep, state);
    if (activeStep === 4) {
      if (sendingData) return true;
      return false;
    }
    if (activeStep !== 4) {
      return errors ? true : false;
    }
  };

  const handlePercentageChange = (percentage) => {
    setState({ ...state, percentage });
  };

  const handleProjectSave = async (data) => {
    await saveProject(data, handlePercentageChange);
    setState({ ...data, base64Images: [] });
    toast.success("Saved");
    setTimeout(() => {
      window.location.replace("/projects");
    }, 3000);
  };

  const handleSubmit = async () => {
    setSendingData(true);
    const { files } = state;
    try {
      for (let i = 0; i < files.length; i++) {
        readFile(files[i]);
      }
      setTimeout(() => handleProjectSave(state), 1000);
    } catch (ex) {
      setSendingData(false);
      this.setState({ data: { ...state, base64Images: [] } });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  // ********** END OF EVENT HANDLERS ********** //

  // ********** VALIDATORS AND HELPER FUNCTIONS *********** //
  const validateProperty = ({ name, value }) => {
    let obj = { [name]: value };
    let schema = Joi.object({
      [name]: getValidationSchema(activeStep).extract(name),
    });
    if (name === "raised") {
      obj = { [name]: value, goal: parseFloat(state.goal) };
      schema = Joi.object({
        [name]: getValidationSchema(activeStep).extract(name),
        goal: getValidationSchema(activeStep).extract("goal"),
      });
    }
    if (name === "goal") {
      obj = { [name]: value, raised: parseFloat(state.raised) };
      schema = Joi.object({
        [name]: getValidationSchema(activeStep).extract(name),
        raised: getValidationSchema(activeStep).extract("raised"),
      });
    }
    if (name === "goal") {
      const { error } = schema.validate(obj, {
        convert: true,
        abortEarly: false,
      });
      return error ? error.details : null;
    }

    let { error } = schema.validate(obj, { convert: true });
    if (error && error.details[0].message === '"Raised" must be a number')
      return "Raised must be a number";
    if (error && error.details[0].message === '"Goal" must be a number')
      return null;
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  const validateEditor = (name, value) => {
    const obj = { [name]: value };
    const schema = Joi.object({
      [name]: getValidationSchema(activeStep).extract(name),
    });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message.replace(/[""]/g, "") : null;
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FirstStep
            renderInput={renderInput}
            renderArabicInput={renderArabicInput}
            renderSelect={renderSelect}
            renderArabicSelect={renderArabicSelect}
            renderCheckbox={renderCheckbox}
            currencyOptions={currencyOptions}
            subhubOptions={subhubOptions}
          />
        );
      case 1:
        return <SecondStep renderEditor={renderEditor} />;
      case 2:
        return (
          <ThirdStep
            handleChangeImage={handleMainImageChange}
            handleDropzoneChange={handleDropzoneChange}
            file={state.file}
            files={state.files}
          />
        );
      case 3:
        return (
          <FourthStep
            renderInput={renderInput}
            renderArabicInput={renderArabicInput}
            onMapChange={handleMapChange}
            latlng={[
              parseFloat(state.locationLat) || 0,
              parseFloat(state.locationLng) || 0,
            ]}
          />
        );
      case 4:
        return <Review data={state} />;
      default:
        return "Unknown step";
    }
  }

  const readFile = async (file) => {
    const data = { ...state };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    setState(data);
  };
  // ********** END OF VALIDATORS AND HELPER FUNCTIONS *********** //

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {getStepContent(activeStep)}
        <div className={classes.navButtons}>
          <Button
            variant="contained"
            disabled={activeStep <= 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={handleButtonDisable()}
            className={classes.button}
          >
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
}
