import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { fileToBase64 } from "../../../utils/filereader";
import { toast } from "react-toastify";
import {
  addPartner,
  deletePartner,
  getPartners,
  updatePartner,
} from "../../../services/partnersConfig";
import MaterialTable from "material-table";
import { isDataEntry, isAuthorizer } from "../../../utils/checkRole";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import DeleteDialog from "../../common/dialog";
import Editdialog from "./EditPartners";
import { partnerIamge } from "../../../services/imageUrl";

/**
 * Render Partners Configuration Page
 */
class Partners extends Form {
  state = {
    partnersData: [],
    dialogOpen: false,
    editOpen: false,
    itemToDelete: { id: "", link: "", imageName: "" },
    itemToEdit: { id: "", link: "", imageName: "" },
    data: {
      id: "",
      link: "",
      base64Image: "",
    },
    errors: {},
  };

  imageRef = React.createRef();

  schema = {
    id: Joi.label("id"),
    link: Joi.string()
      .regex(
        /(?:http|https):\/\/((?:[\w-]+)(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
      )
      .trim()
      .required()
      .label("URL")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.regex.base":
              err.message = "Enter a valid URL";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    base64Image: Joi.string().trim().required().label("Image"),
  };

  async componentDidMount() {
    try {
      const { data: partnersData } = await getPartners();
      this.setState({ partnersData });
    } catch (error) {
      toast.error("Could not retrieve data");
    }
  }

  handleClickOpen = () => {
    this.setState({ ...this.state, dialogOpen: true });
  };

  handleEditOpen = () => {
    this.setState({ ...this.state, editOpen: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, dialogOpen: false });
  };

  handleEditClose = () => {
    this.setState({ ...this.state, editOpen: false });
  };

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        toast.warn("Please select a png/jpeg image");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      }
      if (file.size > 200000) {
        toast.warn("Maximum allowed size for images is 300 Kb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        const base64 = await fileToBase64(file);
        const data = { ...this.state.data };
        data.base64Image = base64;
        this.setState({ data });
      }
    } catch (error) {
      return;
    }
  };

  handlePartnerItemDelete = async (item) => {
    const originalPartnersData = [...this.state.partnersData];
    const partnersData = originalPartnersData.filter((n) => n.id !== item.id);
    this.setState({ partnersData });
    try {
      const res = await deletePartner(item.id);
      if (res) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This item has already been deleted.");
      this.setState({ ...this.state, partnersData: originalPartnersData });
    }
  };

  doSubmit = async () => {
    const { data } = this.state;
    try {
      await addPartner(data);
      toast.success("Partner added successfully");
      const { data: updatedPartners } = await getPartners();
      this.setState({
        partnersData: updatedPartners,
        sendingData: false,
        data: { ...this.state.data, link: "", base64image: "" },
      });
      this.imageRef.current.type = "text";
      this.imageRef.current.type = "file";
    } catch (ex) {
      this.setState({ sendingData: false });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.link);
      }
    }
  };

  handlePartnerItemEdit = async (data) => {
    if (!data.base64Image) {
      data["base64Image"] = null;
    }
    try {
      await updatePartner(data);
      toast.success("Updated Successfully");
      const { data: updatedPartners } = await getPartners();
      this.setState({ partnersData: updatedPartners });
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.link);
      }
    }
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <label htmlFor="file" style={{ display: "block" }}>
                Upload Image <span className="text-danger">*</span>
              </label>
              <input
                accept=".png, .jpg, .jpeg"
                type="file"
                ref={this.imageRef}
                className="upload-file btn btn-success"
                onChange={this.handleChangeImage}
              />
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("link", "URL", "text", true)}
            </div>
          </div>
          {this.renderButton("Add")}
        </form>
        <br />
        <MaterialTable
          title="Partners"
          data={this.state.partnersData}
          columns={[
            {
              title: "Image",
              sorting: false,
              field: "iconUrl",
              render: (rowData) => (
                <img
                  src={partnerIamge(rowData.imageName)}
                  style={{ width: 100, height: 100 }}
                  alt=""
                />
              ),
            },
            { field: "link", title: "URL" },
          ]}
          actions={[
            {
              icon: () => <SaveIcon color="primary" />,
              tooltip: "Edit Data",
              onClick: (event, rowData) =>
                this.setState({
                  itemToEdit: rowData,
                  editOpen: true,
                }),
            },
            {
              hidden: isDataEntry() || isAuthorizer(),
              icon: () => <DeleteIcon color="secondary" />,
              tooltip: "Delete Data",
              onClick: (event, rowData) =>
                this.setState({
                  itemToDelete: rowData,
                  dialogOpen: true,
                }),
            },
          ]}
          onRowClick={(event, rowData) =>
            this.setState({ itemToEdit: rowData, editOpen: true })
          }
          options={{
            headerStyle: {
              backgroundColor: "#01a553",
            },
            grouping: false,
            searchFieldStyle: {
              backgroundColor: "#01a553",
              padding: "0 5px",
              borderRadius: "10px",
            },
          }}
        />
        <DeleteDialog
          dialogOpen={this.state.dialogOpen}
          handleClose={this.handleClose}
          item={this.state.itemToDelete}
          handleDelete={this.handlePartnerItemDelete}
        />
        <Editdialog
          dialogOpen={this.state.editOpen}
          onClose={this.handleEditClose}
          handleEdit={this.handlePartnerItemEdit}
          item={this.state.itemToEdit}
        />
      </>
    );
  }
}

export default Partners;
