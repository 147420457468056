import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getSubhub } from "../../services/subhubsServices";
import { toast } from "react-toastify";
import NotFound from "../NotFound/NotFound";
import Loader from "../common/loader";
import isEmpty from "../../utils/objectEmpty";

class SingleSubhub extends Component {
  state = { subhub: {}, isLoading: true, arabic: {} };

  async componentDidMount() {
    const subhubId = this.props.match.params.id;
    try {
      const { data: subhub } = await getSubhub(subhubId);
      const arabic = subhub.subHubTranslations.find(
        (trans) => trans.locale === "ar"
      );
      this.setState({ subhub, arabic, isLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        this.setState({ isLoading: false });
        toast.error("No Such subhub ");
      }
    }
  }

  render() {
    const { subhub, arabic, isLoading } = this.state;
    if (isLoading === true) {
      return <Loader />;
    } else if (isEmpty(subhub) && isLoading === false) {
      return <NotFound />;
    } else if (!isEmpty(subhub) && isLoading === false) {
      return (
        <div className="container">
          <div className="card-body m-3 shadow p-3 bg-white rounded">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <img
                  className="img-fluid rounded shadow"
                  src={subhub.imageUrl}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <table className="table table-hover">
                  <caption>{subhub.name} details</caption>
                  <tbody>
                    <tr>
                      <th className="text-success">Name</th>
                      <td>{subhub.name}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Description</th>
                      <td>{subhub.description}</td>
                    </tr>
                    <tr dir="rtl">
                      <th className="text-success">Arabic Name</th>
                      <td>{arabic.name}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Arabic Description</th>
                      <td>{arabic.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <Link
                  className="btn btn-outline-secondary btn-block"
                  to="/subhubs"
                >
                  Back
                </Link>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <Link
                  className="btn btn-outline-success btn-block"
                  to={`/subhubs/form/${subhub.id}`}
                >
                  Edit
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SingleSubhub;
