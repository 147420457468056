import React from "react";
import { saveNews } from "./../../services/newsService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";
import UploadZone, { VideoUpload } from "../common/dropZone";
import Progress from "../common/progress";
import { fileToBase64 } from "../../utils/filereader";

class NewsForm extends Form {
  state = {
    sendingData: false,
    percentage: "",
    video: {},
    files: [],
    data: {
      name: "",
      description: "",
      expireDate: "",
      base64Image: "",
      arabicName: "",
      arabicDescription: "",
      base64Images: [],
    },
    errors: {},
  };

  schema = {
    id: Joi.label("Id"),
    name: Joi.string().trim().required().label("Name"),
    description: Joi.string().required().label("description"),
    arabicName: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicDescription: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    expireDate: Joi.date()
      .required()
      .label("Expire Date")
      .error(() => {
        return {
          message: "Expire date is required",
        };
      }),
    base64Image: Joi.string().trim().required().label("Image"),
    base64Images: Joi.label("Images"),
  };

  handleNewsSave = async (data) => {
    await saveNews(data, this.state.video, this.handlePercentageChange);
    await this.setState({
      data: { ...data, base64Images: [] },
      videoFiles: [],
    });
    toast.success("Saved");
    setTimeout(() => {
      window.location.replace("/news");
    }, 3000);
  };

  doSubmit = async () => {
    const { files, data } = this.state;
    try {
      for (let i = 0; i < files.length; i++) {
        this.readFile(files[i]);
      }
      setTimeout(() => this.handleNewsSave(data), 1000);
    } catch (ex) {
      this.setState({
        data: { ...this.state.data, base64Images: [] },
        sendingData: false,
      });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  handlePercentageChange = (percentage) => {
    this.setState({ percentage });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const files = [...this.state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...this.state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "done") {
      this.setState({
        files: updatedFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "removed") {
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
  };

  handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ video: file });
    }
    if (status === "removed") {
      this.setState({ video: {} });
    }
  };

  readFile = async (file) => {
    const data = { ...this.state.data };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    this.setState({ data });
  };

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        const base64 = await fileToBase64(file);
        this.setState({ data: { ...this.state.data, base64Image: base64 } });
      }
    } catch (error) {
      return;
    }
  };

  render() {
    const { percentage } = this.state;
    return (
      <>
        <div className="card-body m-3 shadow p-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {" "}
                {this.renderInput("name", "Name", "text", true)}
                {this.renderTextArea("description", "Description", true)}
                <label htmlFor="file" style={{ display: "block" }}>
                  Upload Image <span className="text-danger">*</span>
                </label>
                <input
                  accept="image/*"
                  ref="file"
                  type="file"
                  name="file"
                  className="upload-file btn btn-success"
                  onChange={this.handleChangeImage}
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderArabicInput(
                  "arabicName",
                  "Arabic Name",
                  "text",
                  true
                )}
                {this.renderArabicTextArea(
                  "arabicDescription",
                  "Arabic Description",
                  true
                )}
                {this.renderInput("expireDate", "Expiration", "date", true)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <UploadZone
                  onChange={this.handleChangeStatus}
                  placeholder="Add News Images"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <VideoUpload
                  onChange={this.handleVideoChange}
                  placeholder="Upload A Video"
                />
              </div>
            </div>

            {this.renderButton("Save")}
          </form>
        </div>
        {percentage === "" || percentage === 100 ? null : (
          <Progress value={parseInt(percentage)} label="Uploading" />
        )}
      </>
    );
  }
}

export default NewsForm;
