import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/donate/portal";
const prjectDonationsApi = config.globalApi + "/moneyTransfer";
const sadagaat = config.globalApi + "/sadagaat-account";

export function makeDonation(amount, hubId, subHubId, projectId, sadagaat) {
  hubId === "" && subHubId === "" && projectId === ""
    ? (sadagaat = true)
    : (sadagaat = false);

  return http.post(
    apiEndpoint,
    { amount, hubId, subHubId, projectId, sadagaat },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export async function DonateToProjects(
  hubId,
  subHubId,
  projectId,
  amount,
  sadagaat
) {
  return http.post(
    prjectDonationsApi,
    { hubId, subHubId, projectId, amount, sadagaat },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function getTotalDonorsNumber(startDate, endDate) {
  if (!startDate || !endDate) {
    return http.get(`${config.globalApi}/total-doner-between`, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.get(
      `${config.globalApi}/total-doner-between?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + getJwt(),
        },
      }
    );
  }
}

export async function sadagaatAccount() {
  return http.get(sadagaat, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getDonationDescription() {
  return http.get(`${config.globalApi}/donation-description`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
      "accept-language": "en",
    },
  });
}

export function updateDonationDescription({ descriptionAr, descriptionEn }) {
  const updatedDescription = { descriptionAr, descriptionEn };

  return http.post(`${config.globalApi}/donation-description`, updatedDescription, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
