import http from "./httpService";
import config from "../config.json";
import progress from "../utils/progress";
import { getJwt } from "./authService";

const apiEndPoint = config.globalApi + "/voulenter-page";

export async function getVolunteerPage() {
  return http.get(apiEndPoint);
}

export async function updateVolunteerPage({
  htmlPageAr,
  htmlPageEn,
  base64Images,
}) {
  return http.put(
    apiEndPoint,
    { htmlPageEn, htmlPageAr, base64Images },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export async function getVolunteer() {
  return http.get(apiEndPoint);
}

export function saveVolunteer(volunteer, file) {
  const volunteerObj = {
    base64Images: volunteer.base64Images,
    htmlPageEn: volunteer.htmlPageEn,
    htmlPageAr: volunteer.htmlPageAr,
  };

  const details = JSON.stringify(volunteerObj);
  const blob = new Blob([details], {
    type: "application/json",
  });

  const formData = new FormData();
  formData.append("file", file);
  formData.append("page", blob);

  return http.post(apiEndPoint, formData, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function updateVolunteer(data) {
  return http.put(apiEndPoint, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function addVolunteerVideo(video, id, onPercentageChange) {
  const data = new FormData();
  data.append("file", video);

  return http.post(`${apiEndPoint}/upload-video`, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
    onUploadProgress: (progressEvent) =>
      onPercentageChange(progress(progressEvent)),
  });
}

export async function deleteVolunteerVideos(videos) {
  return videos.forEach((video) => {
    http.post(`${apiEndPoint}/video/delete`, video, {
      headers: {
        Authorization: "Bearer " + getJwt(),
        "Content-Type": "application/json",
      },
    });
  });
}

export async function updateVolunteerImages(id, newImages, onPercentageChange) {
  return await http.post(
    `${apiEndPoint}/image/add`,
    { id: id, base64Images: newImages },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    }
  );
}

export function deleteVolunteerImages(images) {
  return http.post(`${apiEndPoint}/image/delete`, images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
