import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

/**
 * Render a Tab for Configuration Page
 */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  /** child elements to render in the tab */
  children: PropTypes.any,
  /** index of selected tab */
  value: PropTypes.number,
  /** index for each tab */
  index: PropTypes.number,
};

export default TabPanel;
