import React, { Component } from "react";
import { getMember } from "../../services/memberService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import Loader from "../common/loader";
import isEmpty from "../../utils/objectEmpty";

class SingleMember extends Component {
  state = { member: {}, isLoading: true };

  async componentDidMount() {
    const memberId = this.props.match.params.id;
    try {
      const { data: member } = await getMember(memberId);
      this.setState({ member, isLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        this.setState({ isLoading: false });
        toast.error("No Such Member ");
      }
    }
  }

  render() {
    const { isLoading, member } = this.state;
    if (isLoading === true) {
      return <Loader />;
    } else if (isLoading === false && isEmpty(member)) {
      return <NotFound />;
    } else {
      if (isLoading === false && !isEmpty(member)) {
        return (
          <div className="container">
            <div className="card rounded m-3 shadow p-3 bg-white">
              <table className="table table-sm table-borderd table-hover">
                <caption>{member.name} details</caption>
                <tbody>
                  <tr>
                    <th className="text-success">Name</th>
                    <td>{member.name}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Email</th>
                    <td>{member.email}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Gender</th>
                    <td>{member.gender === "MALE" ? "Male" : "Female"}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Date of birth</th>
                    <td>{member.dateOfBirth && new Date(member.dateOfBirth).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Phone Number</th>
                    <td>{member.phoneNumber}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Whatsapp Phone Number</th>
                    <td>{member.whatsPhone}</td>
                  </tr>

                  <tr>
                    <th className="text-success">Blood Group</th>
                    <td>{member.bloodGroup}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Education Level</th>
                    <td>{member.educationLevel}</td>
                  </tr>
                  <tr>
                    <th className="text-success">University</th>
                    <td>{member.university}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Study Field</th>
                    <td>{member.studyField}</td>
                  </tr>
                  <tr>
                    <th className="text-success">State</th>
                    <td>{member.state}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Are you working currently ?</th>
                    <td>{member.job && (member.job) ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Job Title</th>
                    <td className="text-capitalize">{member.jobTitle && member.jobTitle.split('_').join(" ")}</td>
                  </tr>
                  <tr>
                    <th className="text-success">City</th>
                    <td>{member.city}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Locality</th>
                    <td>{member.locality}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Neighborhood</th>
                    <td>{member.neighborhood}</td>
                  </tr>
                  <tr>
                    <th className="text-success">
                      Did you volunteer in Sadagaat charity organization before
                      ?
                    </th>
                    <td>{member.volunteeredBefore && member.volunteeredBefore ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th className="text-success">
                      From where did you hear about Sadagaat ?
                    </th>
                    <td className="text-capitalize">{member.heardAboutSadagaat?.split('_').join(" ")}</td>
                  </tr>
                  <tr>
                    <th className="text-success">
                      What is the suitable time for you to volunteer in Sadagaat
                      ?
                    </th>
                    <td>
                      {member.volunteerTime === "morning"
                        ? "Morning"
                        : "Evening"}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-success">
                      Does Sadagaat have a community in your country ?
                    </th>
                    <td>{member.hasCommunityInCountry && member.hasCommunityInCountry ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Since when ?</th>
                    <td>{member.volunteeredPeriod}</td>
                  </tr>
                  <tr>
                    <th className="text-success">When was your last contribute in Sadagaat voluntary work?</th>
                    <td className="text-capitalize">{member.lastContribution && member.lastContribution.split('_').join(" ")}</td>
                  </tr>
                  <tr>
                    <th className="text-success">
                      What Project's have you participated in with sadagaat ?
                    </th>
                    <td>{member.volunteeredProjects}</td>
                  </tr>
                  <tr>
                    <th className="text-success">
                      Would you like us to send a newsletter about Sadagaat work
                      in your email ?
                    </th>
                    <td>{member.receiveEmails === true ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                  <Link
                    className="btn btn-outline-secondary btn-block"
                    to="/members"
                  >
                    Back
                  </Link>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                  <Link
                    className="btn btn-outline-success btn-block"
                    to={`/members/form/${member.id}`}
                  >
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default SingleMember;
