import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getNews, deleteNews, approveNews } from "../../services/newsService";
import { paginate } from "../../utils/paginate";
import { toast } from "react-toastify";
import _ from "lodash";
import NewsTable from "./NewsTable";
import Loader from "../common/loader";

/**
 * Fetch News from API and Display News table, Add new News
 * @component
 */
class News extends Component {
  state = {
    news: [],
    currentPage: 1,
    pageSize: 3,
    searchQuery: "",
    selectedStatus: null,
    sortColumn: { path: "name", order: "asc" },
    mounted: true,
    loading: true,
  };
  async componentDidMount() {
    try {
      const { data: news } = await getNews();
      this.setState({ news, loading: false });
    } catch (error) {
      toast.error("Could not fetch news from the server");
    }
  }

  handleNewsApproval = async (item) => {
    const originalNews = this.state.news;
    const news = [...this.state.news];
    const index = news.findIndex((n) => n.id === item.id);
    news[index] = {
      ...news[index],
      approvedByAdmin: !news[index].approvedByAdmin,
    };
    this.setState({ news });
    try {
      await approveNews(item);
      toast.success("Approval Changed");
    } catch (error) {
      toast.error("Could Not Change Approval");
      this.setState({ news: originalNews });
    }
  };

  handleNewsDelete = async (newss) => {
    const originalNews = this.state.news;
    const news = originalNews.filter((n) => n.id !== newss.id);
    this.setState({ news });

    try {
      const res = await deleteNews(newss.id);
      if (res.status === 200) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This news has already been deleted.");

      this.setState({ news: originalNews });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <Link
          to="/news/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{ marginBottom: 20 }}
        >
          Add News
        </Link>
        <NewsTable
          news={this.state.news}
          handleNewsDelete={this.handleNewsDelete}
          approveNews={this.handleNewsApproval}
        />
      </>
    );
  }
}

export default News;
