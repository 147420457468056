import { logout } from "../../services/authService";

/**
 * Log User out
 * @component
 */
const LogOut = () => {
  logout();
};

export default LogOut;
