import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getEvent } from "../../services/eventService";
import { toast } from "react-toastify";
import NotFound from "../NotFound/NotFound";
import Loader from "../common/loader";
import isEmpty from "../../utils/objectEmpty";

class SingleEvent extends Component {
  state = { event: {}, isLoading: true, arabic: {} };

  async componentDidMount() {
    const eventId = this.props.match.params.id;
    try {
      const { data: event } = await getEvent(eventId);
      const arabic = event.eventTranslations.find(
        (trans) => trans.locale === "ar"
      );
      this.setState({ event, arabic, isLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        this.setState({ isLoading: false });
        toast.error("No Such event ");
      }
    }
  }

  render() {
    const { event, arabic, isLoading } = this.state;
    if (isLoading === true) {
      return <Loader />;
    } else if (isEmpty(event) && isLoading === false) {
      return <NotFound />;
    } else if (!isEmpty(event) && isLoading === false) {
      return (
        <div className="main">
          <div className="container">
            <div className="card-body m-3 shadow p-3 bg-white rounded">
              <table className="table table-hover">
                <caption>{event.name} details</caption>
                <tbody>
                  <tr>
                    <th className="text-success">Name</th>
                    <td>{event.name}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Description</th>
                    <td>{event.description}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Location Name</th>
                    <td>{event.locationName}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Arabic Name</th>
                    <td>{arabic.name}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Arabic Description</th>
                    <td>{arabic.description}</td>
                  </tr>
                  <tr>
                    <th className="text-success">Arabic Location Name</th>
                    <td>{arabic.locationName}</td>
                  </tr>
                </tbody>
              </table>
              <div className="row mt-3">
                <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                  <Link
                    className="btn btn-outline-secondary btn-block"
                    to="/events"
                  >
                    Back
                  </Link>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                  <Link
                    className="btn btn-outline-success btn-block"
                    to={`/events/form/${event.id}`}
                  >
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SingleEvent;
