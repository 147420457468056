import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getProjects,
  deleteProject,
  approveProject, putFeaturedOngoingProject,
} from "../../services/projectsServices";
import { toast } from "react-toastify";
import ProjectsTable from "./Table";
import Loader from "../common/loader";

/**
 * Fetch Projects from API and Display Projects table, Add new Project
 * @component
 */
class Projects extends Component {
  state = { projects: [], loading: true };

  async componentDidMount() {
    try {
      const { data: projects } = await getProjects();
      console.log(projects);
      projects.forEach((project) => {
        project.projectTranslations[0] = project.projectTranslations.find(
          (t) => t.locale === "ar"
        );
      });
      this.setState({ projects, loading: false });
    } catch (ex) {
      toast.error("Could not fetch projects from server");
    }

    // const response = await putFeaturedOngoingProject(6571);
    // console.log(response)
  }

  handleProjectApproval = async (project) => {
    const originalProjects = this.state.projects;
    const projects = [...this.state.projects];
    const index = projects.findIndex((n) => n.id === project.id);
    projects[index] = {
      ...projects[index],
      approvedByAdmin: !projects[index].approvedByAdmin,
    };
    this.setState({ projects });
    try {
      await approveProject(project);
      toast.success("Approval Changed");
    } catch (error) {
      toast.error("Could Not Change Approval");
      this.setState({ projects: originalProjects });
    }
  };

  handleProjectDelete = async (project) => {
    const originalProjects = this.state.projects;
    const projects = originalProjects.filter((p) => p.id !== project.id);
    this.setState({ projects });
    try {
      const res = await deleteProject(project.id);
      if (res) toast.success("Deleted Successfully !");
    } catch (ex) {
      toast.error("Could Not Delete !");

      this.setState({ projects: originalProjects });
    }
  };

  handleProjectFeaturedOngoing = async (project) => {
    const originalProjects = this.state.projects;
    try {
      const response = await putFeaturedOngoingProject(project.id);
      if (response)
      {
        toast.success(`${project.name} changed to featured on-going project`);
        const projects = [...this.state.projects];
        const index = projects.findIndex((n) => n.id === project.id);
        projects[index] = {
          ...projects[index],
          featuredOngoing: !projects[index].featuredOngoing
        }
        this.setState({ projects });
      }
    } catch (ex) {
      toast.error(ex.message);
      this.setState({ projects: originalProjects });
    }
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Link
          to="/projects/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{
            marginBottom: 20,
          }}
        >
          New Project
        </Link>
        <ProjectsTable
          projects={this.state.projects}
          onDelete={this.handleProjectDelete}
          approveProject={this.handleProjectApproval}
          featuredOngoing={this.handleProjectFeaturedOngoing}
        />
      </>
    );
  }
}

export default Projects;
