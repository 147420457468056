import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/hubs";

function hubUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function approveHubUrl(id) {
  return `${config.globalApi}/Hubs/approve/${id}`;
}

export function getHubs() {
  return http.get(apiEndpoint, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function gethHub(hubId) {
  return http.get(hubUrl(hubId), {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function gethHubProjects(hubId) {
  return http.get(`${apiEndpoint}/${hubId}/projects`, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function addDocument(hubId, doc) {
  const data = new FormData();
  data.append("file", doc);

  return http.post(`${config.globalApi}/hub/upload-doc/${hubId}`, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteDocument(documentId) {
  return http.delete(`${config.globalApi}/hub/document/${documentId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function savehHub(hub) {
  const newHub = {
    base64Image: hub.base64Image,
    hubTranslations: [
      {
        default: false,
        name: hub.arabicName,
        description: hub.arabicDescription,
        formatedDescription: hub.arabicFormatedDescription,
        locale: "ar",
      },
      {
        default: false,
        name: hub.englishName,
        description: hub.englishDescription,
        formatedDescription: hub.englishFormatedDescription,
        locale: "en",
      },
    ],
  };
  if (hub.id) {
    const english = { ...newHub.hubTranslations[0], id: hub.englishId };
    const arabic = { ...newHub.hubTranslations[1], id: hub.arabicId };
    const updatedHub = { ...newHub, hubTranslations: [english, arabic] };
    return http.put(hubUrl(hub.id), updatedHub, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    delete newHub.id;
    return http.post(apiEndpoint, newHub, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
}

export async function approveHub(hub) {
  await http.put(
    approveHubUrl(hub.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function deleteHub(hubId) {
  return http.delete(hubUrl(hubId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
