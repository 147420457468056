import React, { Component } from "react";
import { forgetPassword } from "../../services/passwordService";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import logo from "../images/logo.jpg";
import wave from "../images/wave.png";
import "../styles/login.css";

/**
 * Forgot Password Form, to enable user to reaccess dashboard after password loss
 * @component
 */
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.userName = React.createRef();
    this.form = React.createRef();
    this.state = {
      userName: "",
    };
  }

  handleUserNameChange = (event) => {
    this.setState({ userName: event.target.value });
  };

  handleFocus = (event) => {
    event.target.parentNode.parentNode.classList.add("focus");
  };

  handleFocusRemoval = (event) => {
    if (event.target.value === "") {
      event.target.parentNode.parentNode.classList.remove("focus");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { userName } = this.state;
      await forgetPassword(userName);
      toast.info("password sent to your email", { position: "top-center" });
      setTimeout(function () {
        window.location.replace("/");
      }, 5000);
    } catch (ex) {
      toast.error(ex.response ? ex.response.data : "Something went wrong", {
        position: "top-center",
      });
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/dashboard" />;

    return (
      <>
        <img className="wave" src={wave} alt="" />
        <div className="login-container">
          <div className="img"></div>
          <div className="login-content">
            <form
              className="login-form"
              ref={this.form}
              onSubmit={this.handleSubmit}
            >
              <img src={logo} alt="" />
              <div className="input-div one">
                <div className="i">
                  <i className="fas fa-user"></i>
                </div>
                <div className="div">
                  <h5>Username</h5>
                  <input
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocusRemoval}
                    onChange={this.handleUserNameChange}
                    type="email"
                    required
                    ref={this.userName}
                    className="input"
                  />
                </div>
              </div>
              <input type="submit" className="submit-button" value="Send" />
            </form>
          </div>
        </div>
      </>
    );
  }
}

LoginForm.displayName = "ForgotPassword";
export default LoginForm;
