import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import Info from "@material-ui/icons/InfoOutlined";
import { isDataEntry, isAuthorizer } from "../../utils/checkRole";

class MembersTable extends Component {
  state = {
    member: {},
    dialogOpen: false,
    viewDetails: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };

  handleViewDetails = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ viewDetails: true });
  };
  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    if (this.state.redirect === false && this.state.viewDetails === false) {
      return (
        <>
          <MaterialTable
            title="Members Table"
            columns={[
              { title: "Name", field: "name" },
              { title: "Email", field: "email" },
              { title: "Phone Number", field: "phoneNumber" },
              {
                title: "Gender",
                field: "gender",
              },
            ]}
            data={this.props.members}
            actions={[
              {
                icon: () => <Info />,
                tooltip: "View Member",
                onClick: (event, rowData) => {
                  this.handleViewDetails(rowData);
                },
              },
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit Member",
                onClick: (event, rowData) => {
                  this.handleRoute(rowData);
                },
              },
              {
                hidden: isDataEntry() || isAuthorizer(),
                icon: () => <DeleteIcon color="secondary" />,
                tooltip: "Delete Member",
                onClick: (event, rowData) =>
                  this.setState({ dialogOpen: true, member: rowData }),
              },
            ]}
            onRowClick={(event, rowData) =>
              this.setState({ id: rowData.id, redirect: true })
            }
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.member}
            handleDelete={this.props.onDelete}
          />
        </>
      );
    }
    if (this.state.redirect === true) {
      return <Redirect to={`/members/form/${this.state.id}`}></Redirect>;
    }
    if (this.state.viewDetails === true) {
      return <Redirect to={`/members/${this.state.id}`}></Redirect>;
    }
  }
}

export default MembersTable;
