import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { fileToBase64, fileToDataUrl } from "../../../utils/filereader";
import { toast } from "react-toastify";
import {
  deleteSliderItem,
  getSliderImages,
  saveSliderData,
} from "../../../services/sliderService";
import MaterialTable from "material-table";
import { sliderImage } from "../../../services/imageUrl";
import { isDataEntry, isAuthorizer } from "../../../utils/checkRole";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import DeleteDialog from "../../common/dialog";
import Editdialog from "./EditSliderItem";

/**
 * Render Slider Configuration Page
 */
class Slider extends Form {
  state = {
    sliderData: [],
    dialogOpen: false,
    editOpen: false,
    itemToDelete: { id: "", link: "", imageName: "" },
    itemToEdit: { id: "", link: "", imageName: "" },
    data: {
      id: "",
      link: undefined,
      base64Image: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.label("id"),
    link: Joi.string()
      .regex(
        /(?:http|https):\/\/((?:[\w-]+)(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
      )
      .trim()
      .label("URL")
      .allow('')
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.regex.base":
              err.message = "Enter a valid URL";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    base64Image: Joi.string().trim().required().label("Image"),
  };

  async componentDidMount() {
    try {
      const { data: sliderData } = await getSliderImages();
      this.setState({ sliderData });
    } catch (error) {
      toast.error("Could not retrieve data");
    }
  }

  handleClickOpen = () => {
    this.setState({ ...this.state, dialogOpen: true });
  };

  handleEditOpen = () => {
    this.setState({ ...this.state, editOpen: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, dialogOpen: false });
  };

  handleEditClose = () => {
    this.setState({ ...this.state, editOpen: false });
  };

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        toast.warn("Please select a png/jpeg image");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
        return;
      }

      const base64 = await fileToDataUrl(file);
      const image = new Image();
      image.src = base64;
      image.onload = async () => {
        if (image.width < 1350 || image.height < 440) {
          toast.warn(`image should be at least  ${1350}x${440} pexels`);
          this.setState({ data: { ...this.state.data, base64Image: "" } });
        } else {
          const b64 = await fileToBase64(file);
          const data = { ...this.state.data };
          data.base64Image = b64;
          this.setState({ data });
        }
      };
    } catch (error) {
      return;
    }
  };

  handleSliderItemDelete = async (item) => {
    const originalSliderData = [...this.state.sliderData];
    const sliderData = originalSliderData.filter((n) => n.id !== item.id);
    this.setState({ sliderData });
    try {
      const res = await deleteSliderItem(item.id);
      if (res) toast.success("Deleted Successfully !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This contact has already been deleted.");
      this.setState({ ...this.state, sliderData: originalSliderData });
    }
  };

  doSubmit = async () => {
    const { data } = this.state;
    try {
      await saveSliderData(data);
      toast.success("Slider image added successfully");
      const { data: sliderData } = await getSliderImages();
      this.setState({ sliderData });
    } catch (ex) {
      this.setState({ sendingData: false });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.url);
      }
    }
  };

  handleSliderItemEdit = async (data) => {
    if (!data.base64Image) {
      data["base64Image"] = null;
    }
    try {
      await saveSliderData(data);
      toast.success("Updated Successfully");
      const { data: sliderData } = await getSliderImages();
      this.setState({ sliderData });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <label htmlFor="file" style={{ display: "block" }}>
                Upload Image <span className="text-danger">*</span>
              </label>
              <input
                accept=".png, .jpg, .jpeg"
                type="file"
                name="file"
                className="upload-file btn btn-success"
                onChange={this.handleChangeImage}
              />
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("link", "URL", "text", false)}
            </div>
          </div>
          {this.renderButton("Add")}
        </form>
        <br />
        <MaterialTable
          title="Slider Data"
          data={this.state.sliderData}
          columns={[
            {
              title: "Image",
              sorting: false,
              field: "iconUrl",
              render: (rowData) => (
                <img
                  src={sliderImage(rowData.imageName)}
                  style={{ width: 100, height: 100 }}
                  alt=""
                />
              ),
            },
            { field: "link", title: "URL" },
          ]}
          actions={[
            {
              icon: () => <SaveIcon color="primary" />,
              tooltip: "Edit Data",
              onClick: (event, rowData) =>
                this.setState({
                  itemToEdit: rowData,
                  editOpen: true,
                }),
            },
            {
              hidden: isDataEntry() || isAuthorizer(),
              icon: () => <DeleteIcon color="secondary" />,
              tooltip: "Delete Data",
              onClick: (event, rowData) =>
                this.setState({
                  itemToDelete: rowData,
                  dialogOpen: true,
                }),
            },
          ]}
          onRowClick={(event, rowData) =>
            this.setState({ itemToEdit: rowData, editOpen: true })
          }
          options={{
            headerStyle: {
              backgroundColor: "#01a553",
            },
            grouping: false,
            searchFieldStyle: {
              backgroundColor: "#01a553",
              padding: "0 5px",
              borderRadius: "10px",
            },
          }}
        />
        <DeleteDialog
          dialogOpen={this.state.dialogOpen}
          handleClose={this.handleClose}
          item={this.state.itemToDelete}
          handleDelete={this.handleSliderItemDelete}
        />
        <Editdialog
          dialogOpen={this.state.editOpen}
          onClose={this.handleEditClose}
          handleEdit={this.handleSliderItemEdit}
          item={this.state.itemToEdit}
        />
      </>
    );
  }
}

export default Slider;
