import Joi from "joi";

export function getValidationSchema(activeStep) {
  switch (activeStep) {
    case 0:
      return firstStepSchema;
    case 1:
      return secondStepSchema;
    case 2:
      return thirdStepSchema;
    case 3:
      return fourthStepSchema;
    case 4:
      return fifthStepSchema;
    default:
      return firstStepSchema;
  }
}

export function validateCurrentStep(activeStep, data) {
  switch (activeStep) {
    case 0:
      return validateStep(data, firstStepSchema);
    case 1:
      return validateStep(data, secondStepSchema);
    case 2:
      return validateStep(data, thirdStepSchema);
    case 3:
      return validateStep(data, fourthStepSchema);
    case 4:
      return validateStep(data, fifthStepSchema);
    default:
      return validateStep(data, firstStepSchema);
  }
}

const validateStep = (data, schema) => {
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
    convert: true,
  });
  return error;
};

export const validateAllFields = (data) => {
  const schema = Joi.object({
    nameArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .message("Arabic characters only"),
    nameEnglish: Joi.string().trim().required().label("Name"),
    goal: Joi.number().positive().allow("").label("Goal"),
    projectProgress: Joi.number()
      .required()
      .integer()
      .min(0)
      .max(100)
      .label("Project Progress"),
    raised: Joi.number().positive().allow("").label("Raised"),

    // raised: Joi.number()
    //   .required()
    //   .positive()
    //   .label("Raised")
    //   .max(Joi.ref("goal", { render: true }))
    //   .error((errors) => {
    //     errors.forEach((err) => {
    //       switch (err.code) {
    //         case "any.ref":
    //           err.message = "Less than goal!";
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //     return errors;
    //   }),

    statusArabic: Joi.string().trim().required().label("Arabic Status"),
    statusEnglish: Joi.string().trim().required().label("status"),
    subHubId: Joi.string().trim().required().label("Sub Hub"),
    currencyId: Joi.string().trim().required().label("Currency"),
    startAt: Joi.date()
      .required()
      .label("Date")
      .max("now")
      .message("less than today"),
    descriptionEnglish: Joi.string().trim().required().label("Description"),
    descriptionArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .message("arabic characters"),
    locationNameEnglish: Joi.string().trim().required().label("Location"),
    locationNameArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Location")
      .regex(/[\u0600-\u06FF]/)
      .message("arabic characters"),
  });
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
    convert: true,
  });
  return error ? true : false;
};

const firstStepSchema = Joi.object({
  nameArabic: Joi.string()
    .trim()
    .required()
    .label("Arabic Name")
    .regex(/[\u0600-\u06FF]/)
    .message("Arabic characters only"),
  nameEnglish: Joi.string().trim().required().label("Name"),
  goal: Joi.number().positive().allow("").label("Goal"),
  projectProgress: Joi.number()
    .required()
    .integer()
    .min(0)
    .max(100)
    .label("Project Progress"),
  raised: Joi.number().positive().allow("").label("Raised"),
  // .max(Joi.ref("goal", { render: true }))
  // .error((errors) => {
  //   errors.forEach((err) => {
  //     switch (err.code) {
  //       case "any.ref":
  //         err.message = "Less than goal!";
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   return errors;
  // }),
  statusArabic: Joi.string().trim().required().label("Arabic Status"),
  statusEnglish: Joi.string().trim().required().label("status"),
  subHubId: Joi.string().trim().required().label("Sub Hub"),
  currencyId: Joi.string().trim().required().label("Currency"),
  startAt: Joi.date()
    .required()
    .label("Date")
    .max("now")
    .message("less than today"),
  pinToHome: Joi.bool().optional(),
});

const secondStepSchema = Joi.object({
  descriptionEnglish: Joi.string().trim().required().label("Description"),
  descriptionArabic: Joi.string()
    .trim()
    .required()
    .label("Arabic Description")
    .regex(/[\u0600-\u06FF]/)
    .message("arabic characters"),
});

const thirdStepSchema = Joi.object({});

const fourthStepSchema = Joi.object({
  locationNameEnglish: Joi.string().trim().required().label("Location"),
  locationNameArabic: Joi.string()
    .trim()
    .required()
    .label("Arabic Location")
    .regex(/[\u0600-\u06FF]/)
    .message("arabic characters"),
  locationLng: Joi.number()
    .greater(-180)
    .less(180)
    .unsafe()
    .allow("")
    .optional()
    .label("Longitude"),
  locationLat: Joi.number()
    .greater(-90)
    .less(90)
    .unsafe()
    .precision(3)
    .allow("")
    .optional()
    .label("Latitude"),
});

const fifthStepSchema = Joi.object({
  stepArabicDescription: Joi.string()
    .required()
    .trim()
    .label("Arabic Description")
    .regex(/[\u0600-\u06FF]/)
    .message("Arabic characters only"),
  stepEnglishDecsription: Joi.string().required().trim().label("Description"),
});
