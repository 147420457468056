import http from "./httpService";
import config from "../config.json";
import {getJwt} from "./authService";

const apiEndpoint = `${config.globalApi}/magazine`;

export function getMagazineLinks() {
    return http.get(apiEndpoint);
}

export function postMagazineLinks({ allMagazineLink, currentMagazineLink }) {
    return http.post(apiEndpoint, { allMagazineLink, currentMagazineLink }, {
        headers: {
            Authorization: `Bearer ${getJwt()}`,
        }
    });
}
