import config from '../config.json';
import http from './httpService';
import { getJwt } from "./authService";

const apiEndPoint = `${config.globalApi}/cisco-subscriber/`;

export function getCandidates(page, size) {
    return http.get(apiEndPoint, {
        params: {
            page,
            size
        },
        headers: {
            Authorization: "Bearer " + getJwt(),
        },
    });
}