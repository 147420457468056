import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/navigation.css";
import { isAdmin } from "../../utils/checkRole";

/**
 * Render Navigation Side Bar
 */
const Navigation = () => {
  return (
    <div id="left-sidebar">
      <ul className="sidebar-navigation">
        <li>
          <NavLink activeClassName="active-link" to="/dashboard">
            <span>
              {" "}
              <i className="fas fa-tachometer-alt"></i>
            </span>{" "}
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/hubs">
            <span>
              {" "}
              <i className="fas fa-archive"></i>
            </span>{" "}
            Hubs
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/subhubs">
            <span>
              {" "}
              <i className="fas fa-folder-open"></i>
            </span>{" "}
            Subhubs
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/projects">
            <span>
              {" "}
              <i className="fas fa-lightbulb"></i>
            </span>{" "}
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/programs">
            <span>
              {" "}
              <i className="fas fa-child"></i>
            </span>{" "}
            Volunteer Programs
          </NavLink>
        </li>
        {isAdmin() && (
          <li>
            <NavLink activeClassName="active-link" to="/users">
              <span>
                {" "}
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Users
            </NavLink>
          </li>
        )}
        {isAdmin() && (
          <li>
            {" "}
            <NavLink activeClassName="active-link" to="/candidates">
              <span>
                {" "}
                <i className="fa fa-user-friends" aria-hidden="true"></i>{" "}
              </span>{" "}
              Candidates
            </NavLink>
          </li>
        )}
        <li>
          <NavLink activeClassName="active-link" to="/members">
            <span>
              {" "}
              <i className="fas fa-child"></i>
            </span>{" "}
            Members
          </NavLink>
        </li>
        <li>
          {" "}
          <NavLink activeClassName="active-link" to="/events">
            <span>
              <i className="fas fa-calendar"></i>
            </span>{" "}
            Events
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/News">
            <span>
              {" "}
              <i className="fas fa-newspaper"></i>
            </span>{" "}
            News
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/volunteers">
            <span>
              {" "}
              <i className="fas fa-handshake"></i>
            </span>{" "}
            Volunteers
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/vacancies">
            <span>
              {" "}
              <i className="fas fa-handshake"></i>
            </span>{" "}
            Vacancies
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to="/currencies">
            <span>
              {" "}
              <i className="fas fa-money"></i>
            </span>{" "}
            Currencies
          </NavLink>
        </li>
        {isAdmin() && (
          <li>
            {" "}
            <NavLink activeClassName="active-link" to="/donations">
              <span>
                <i className="fas fa-hand-holding-usd"></i>
              </span>{" "}
              Donations
            </NavLink>
          </li>
        )}
        {isAdmin() && (
          <li>
            {" "}
            <NavLink activeClassName="active-link" to="/transactions">
              <span>
                {" "}
                <i className="fas fa-exchange-alt"></i>{" "}
              </span>{" "}
              Transaction
            </NavLink>
          </li>
        )}{" "}
        {isAdmin() && (
          <li>
            {" "}
            <NavLink activeClassName="active-link" to="/configuration">
              <span>
                {" "}
                <i className="fa fa-cog" aria-hidden="true"></i>{" "}
              </span>{" "}
              Website Configuration
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navigation;
