import React from "react";
import { saveSubhub } from "../../services/subhubsServices";
import { getHubs } from "../../services/hubServices";
import { toast } from "react-toastify";
import { fileToBase64 } from "../../utils/filereader";
import Joi from "joi-browser";
import Form from "../common/form";

class SubhubsForm extends Form {
  state = {
    sendingData: false,
    hubOptions: [],
    data: {
      id: "",
      nameArabic: "",
      descriptionArabic: "",
      nameEnglish: "",
      descriptionEnglish: "",
      hubId: "",
      base64Image: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.label("Id"),
    nameArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    descriptionArabic: Joi.string()
      .trim()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    nameEnglish: Joi.string().trim().required().label("name"),
    descriptionEnglish: Joi.string().trim().required().label("description"),
    hubId: Joi.string().required().label("Hub"),
    base64Image: Joi.string().trim().required().label("Image"),
  };

  async componentDidMount() {
    let { data: hubs } = await getHubs();
    const hubOptions = hubs.map((hub) => ({ name: hub.name, value: hub.id }));
    this.setState({ hubOptions });
  }

  handleChangeImage = async (evt) => {
    try {
      const file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        const base64 = await fileToBase64(file);
        this.setState({ data: { ...this.state.data, base64Image: base64 } });
      }
    } catch (error) {
      return;
    }
  };

  doSubmit = async () => {
    try {
      await saveSubhub(this.state.data);
      toast.success("Saved");
      setTimeout(function () {
        window.location.replace("/subhubs");
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      }
    }
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("nameEnglish", "Name", "text", true)}
              {this.renderTextArea(
                "descriptionEnglish",
                "Description",
                "text",
                true
              )}
              {this.renderSelect("hubId", "Hub", this.state.hubOptions, true)}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderArabicInput(
                "nameArabic",
                "Arabic Name",
                "text",
                true
              )}
              {this.renderArabicTextArea(
                "descriptionArabic",
                "Arabic Description",
                true
              )}
              <label htmlFor="file" style={{ display: "block" }}>
                Upload Image <span className="text-danger">*</span>
              </label>
              <input
                accept="image/*"
                ref="file"
                type="file"
                name="file"
                className="upload-file btn btn-success"
                onChange={this.handleChangeImage}
                encType="multipart/form-data"
              />
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default SubhubsForm;
