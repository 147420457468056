import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getProjects,
  deleteProject,
  approveProject,
} from "../../services/volunteerProjects";
import { toast } from "react-toastify";
import ProjectsTable from "./table";
import Loader from "../common/loader";

/**
 * Fetch Vlounteer Projects from API and Display Vlounteer Projects table, Add new Vlounteer Project
 * @component
 */
class VolunteerProjects extends Component {
  state = { projects: [], loading: true };

  async componentDidMount() {
    try {
      const { data } = await getProjects();
      const projects = data.data;
      projects.forEach((project) => {
        project.programTranslations[0] = project.programTranslations.find(
          (t) => t.locale === "ar"
        );
      });
      this.setState({ projects, loading: false });
    } catch (ex) {
      toast.error("Could not fetch programs from server");
    }
  }

  handleProjectApproval = async (project) => {
    const originalProjects = this.state.projects;
    const projects = [...this.state.projects];
    const index = projects.findIndex((n) => n.id === project.id);
    projects[index] = {
      ...projects[index],
      approvedByAdmin: !projects[index].approvedByAdmin,
    };
    this.setState({ projects });
    try {
      await approveProject(project);
      toast.success("Approval Changed");
    } catch (error) {
      toast.error("Could Not Change Approval");
      this.setState({ projects: originalProjects });
    }
  };

  handleProjectDelete = async (project) => {
    const originalProjects = this.state.projects;
    const projects = originalProjects.filter((p) => p.id !== project.id);
    this.setState({ projects });
    try {
      const res = await deleteProject(project.id);
      if (res) toast.success("Deleted Successfully !");
    } catch (ex) {
      toast.error("Could Not Delete !");

      this.setState({ projects: originalProjects });
    }
  };
  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Link
          to="/programs/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{
            marginBottom: 20,
          }}
        >
          New Program
        </Link>
        <ProjectsTable
          projects={this.state.projects}
          onDelete={this.handleProjectDelete}
          approveProject={this.handleProjectApproval}
        />
      </>
    );
  }
}

export default VolunteerProjects;
