import React, { Component } from "react";
import { getUser } from "../../services/userService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import Loader from "../common/loader";
import isEmpty from "../../utils/objectEmpty";

class SingleUser extends Component {
  state = { user: {}, isLoading: true };

  async componentDidMount() {
    const userId = this.props.match.params.id;
    try {
      const { data: user } = await getUser(userId);
      this.setState({ user, isLoading: false });
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        this.setState({ isLoading: false });
        toast.error("No Such user ");
      }
    }
  }

  render() {
    const { isLoading, user } = this.state;
    if (isLoading === true) {
      return <Loader />;
    } else if (isLoading === false && isEmpty(user)) {
      return <NotFound />;
    } else {
      if (isLoading === false && !isEmpty(user)) {
        return (
          <div className="main">
            <div className="container">
              <div className="card rounded m-3 shadow p-3 bg-white">
                <table className="table table-sm table-borderd table-hover">
                  <caption>{user.firstName} details</caption>
                  <tbody>
                    <tr>
                      <th className="text-success">First Name</th>
                      <td>{user.firstName}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Last Name</th>
                      <td>{user.lastName}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Role</th>
                      <td>{user.roles[0].displayName}</td>
                    </tr>
                    <tr>
                      <th className="text-success">User Name</th>
                      <td>{user.userName}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Gender</th>
                      <td>{user.gender === "MALE" ? "Male" : "Female"}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Date Of Birth</th>
                      <td>{user.dateOfBirth}</td>
                    </tr>
                    <tr>
                      <th className="text-success">Phone Number</th>
                      <td>{user.phoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                    <Link
                      className="btn btn-outline-secondary btn-block"
                      to="/users"
                    >
                      Back
                    </Link>
                  </div>
                  <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                    <Link
                      className="btn btn-outline-success btn-block"
                      to={`/users/form/${user.id}`}
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default SingleUser;
