import React from "react";
import { saveEvent } from "./../../services/eventService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";
import UploadZone, { VideoUpload } from "../common/dropZone";
import Progress from "../common/progress";
import { fileToBase64 } from "../../utils/filereader";

class EventsForm extends Form {
  state = {
    percentage: "",
    video: {},
    files: [],
    sendingData: false,
    data: {
      id: "",
      arabicDescription: "",
      arabicLocationName: "",
      arabicName: "",
      englishDescription: "",
      englishLocationName: "",
      englishName: "",
      startDate: "",
      endDate: "",
      base64Images: [],
    },
    errors: {},
  };

  schema = {
    id: Joi.label("Id"),
    arabicDescription: Joi.string()
      .trim()
      .required()
      .label("Arabic Description")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicLocationName: Joi.string()
      .trim()
      .label("Arabic Location")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    arabicName: Joi.string()
      .trim()
      .label("Arabic Name")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    englishDescription: Joi.string().trim().label("Description"),
    englishLocationName: Joi.string().trim().label("location Name"),
    englishName: Joi.string().trim().label("Name"),
    startDate: Joi.date()
      .required()
      .label("Start Date")
      .error(() => {
        return {
          message: "Start Date is required",
        };
      }),
    endDate: Joi.date().min(Joi.ref("startDate")).required().label("End Date"),
    base64Images: Joi.label("Images"),
  };

  mapToViewModel(event) {
    const arabic = event.eventTranslations.find(
      (trans) => trans.locale === "ar"
    );
    const english = event.eventTranslations.find(
      (trans) => trans.locale === "en"
    );
    return {
      id: event.id,
      startDate: event.startDate || "",
      endDate: event.endDate || "",
      arabicDescription: arabic.description || "",
      arabicLocationName: arabic.locationName || "",
      arabicName: arabic.name || "",
      englishDescription: english.description || "",
      englishLocationName: english.locationName || "",
      englishName: english.name || "",
    };
  }

  handlePercentageChange = (percentage) => {
    this.setState({ percentage });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const files = [...this.state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...this.state.files, file];
    if (status === "error_file_size") {
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "done") {
      this.setState({
        files: updatedFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "removed") {
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
  };

  handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ video: file });
    }
    if (status === "removed") {
      this.setState({ video: {} });
    }
  };

  handleEventSave = async (data) => {
    await saveEvent(data, this.state.video, this.handlePercentageChange);
    this.setState({ data: { ...data, base64Images: [] } });
    toast.success("Saved");
    setTimeout(() => {
      window.location.replace("/events");
    }, 3000);
  };

  doSubmit = async () => {
    const { files, data } = this.state;
    try {
      for (let i = 0; i < files.length; i++) {
        this.readFile(files[i]);
      }
      setTimeout(() => this.handleEventSave(data), 1000);
    } catch (ex) {
      this.setState({ data: { ...this.state.data, base64Images: [] } });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      } else {
        toast.error("Something went wrong !");
      }
    }
  };

  readFile = async (file) => {
    const data = { ...this.state.data };
    const base64 = await fileToBase64(file);
    data.base64Images.push(base64);
    this.setState({ data });
  };

  render() {
    const { percentage } = this.state;
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("englishName", "Name", "text", true)}
              {this.renderTextArea("englishDescription", "Description", true)}
              {this.renderInput(
                "englishLocationName",
                "Location",
                "text",
                true
              )}
              {this.renderInput("startDate", "Start Date", "date", true)}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderArabicInput(
                "arabicName",
                "Arabic Name",
                "text",
                true
              )}
              {this.renderArabicInput(
                "arabicDescription",
                "Arabic Description",
                "text",
                true
              )}
              {this.renderArabicInput(
                "arabicLocationName",
                "Arabic Location",
                "text",
                true
              )}
              {this.renderInput("endDate", "End Date", "date", true)}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <UploadZone
                onChange={this.handleChangeStatus}
                placeholder="Add Event Images"
              />
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              <VideoUpload
                onChange={this.handleVideoChange}
                placeholder="Upload A Video"
              />
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
        {percentage === "" || percentage === 100 ? null : (
          <Progress value={parseInt(percentage)} label="Saving Event" />
        )}
      </div>
    );
  }
}

export default EventsForm;
