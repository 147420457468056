import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { toast } from "react-toastify";
import { getUser, saveUser } from "../../services/userService";
import { getPortalRoles } from "../../services/roleServices";
import NotFound from "../NotFound/NotFound";

class UserEditForm extends Form {
  state = {
    sendingData: false,
    roles: [],
    data: {
      id: "",
      firstName: "",
      lastName: "",
      userName: "",
      phoneNumber: "",
      gender: "",
      baseRoleName: "",
      dateOfBirth: "",
    },
    errors: {},
  };

  schema = {
    id: Joi.label("Id"),
    firstName: Joi.string().trim().required().label("First Name"),
    lastName: Joi.string().trim().required().label("Last Name"),
    userName: Joi.string()
      .trim()
      .email({ minDomainSegments: 2 })
      .required()
      .label("Email"),
    phoneNumber: Joi.string()
      .required()
      .trim()
      .min(10)
      .max(13)
      .label("Phone Number"),
    gender: Joi.string().trim().required().label("Gender"),
    dateOfBirth: Joi.date()
      .max("January 1, 2002 12:00:00")
      .min("January 1, 1950 12:00:00")
      .label("Date Of Birth")
      .error(() => {
        return {
          message: "2002 or earlier",
        };
      }),
    baseRoleName: Joi.string().trim().required().label("Role"),
  };

  async populateUser() {
    try {
      const userId = this.props.match.params.id;
      const { data: user } = await getUser(userId);
      this.setState({ data: this.mapToViewModel(user) });
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        return <NotFound />;
    }
  }

  async componentDidMount() {
    const { data: rolesJson } = await getPortalRoles();
    const roles = rolesJson.map((role) => ({
      name: role.displayName,
      value: role.name,
    }));
    this.setState({ roles });
    await this.populateUser();
  }

  mapToViewModel(user) {
    return {
      id: user.id,
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      userName: user.userName || "",
      phoneNumber: user.phoneNumber || "",
      gender: user.gender || "",
      baseRoleName: user.baseRoleName || "",
      dateOfBirth: user.dateOfBirth || "",
    };
  }

  doSubmit = async () => {
    try {
      await saveUser(this.state.data);
      toast.success("Saved");
      setTimeout(function () {
        window.location.replace("/users");
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      }
    }
  };

  render() {
    return (
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("firstName", "First Name", "text", true)}
              {this.renderInput("lastName", "Last Name", "text", true)}
              {this.renderInput("phoneNumber", "Phone Number", "number", true)}
              {this.renderSelect(
                "baseRoleName",
                "Role",
                this.state.roles,
                true
              )}
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
              {this.renderInput("userName", "Email", "text", true)}
              {this.renderSelect(
                "gender",
                "Gender",
                [
                  { value: "MALE", name: "Male" },
                  { value: "FEMALE", name: "Female" },
                ],
                true
              )}
              {this.renderInput("dateOfBirth", "Date Of Birth", "date", true)}
            </div>
          </div>
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default UserEditForm;
