import React from "react";

const FourthStep = ({ renderInput, renderArabicInput }) => {
  return (
    <>
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {renderInput("locationNameEnglish", "Location", "text", true)}
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {renderArabicInput(
              "locationNameArabic",
              "Arabic Location",
              "text",
              true
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthStep;
