import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import logo from "../images/logo.jpg";
import wave from "../images/wave.png";
import "../styles/login.css";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.userName = React.createRef();
    this.password = React.createRef();
    this.form = React.createRef();
    this.state = {
      userName: "",
      password: "",
    };
  }

  handleUserNameChange = (event) => {
    this.setState({ userName: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleFocus = (event) => {
    event.target.parentNode.parentNode.classList.add("focus");
  };

  handleFocusRemoval = (event) => {
    if (event.target.value === "") {
      event.target.parentNode.parentNode.classList.remove("focus");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { userName, password } = this.state;
      await auth.login(userName, password);
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/dashboard";
    } catch (ex) {
      toast.error(
        ex.response
          ? ex.response.data.message
          : "Check your internet connection",
        {
          position: "top-center",
        }
      );
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/dashboard" />;
    return (
      <>
        <img className="wave" src={wave} alt="" />
        <div className="login-container">
          <div className="img">{/* <img src={background} alt="" /> */}</div>
          <div className="login-content">
            <form
              className="login-form"
              ref={this.form}
              onSubmit={this.handleSubmit}
            >
              <img src={logo} alt="" />
              <div className="input-div one">
                <div className="i">
                  <i className="fas fa-user"></i>
                </div>
                <div className="div">
                  <h5>Username</h5>
                  <input
                    onChange={this.handleUserNameChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocusRemoval}
                    type="email"
                    required
                    ref={this.userName}
                    className="input"
                  />
                </div>
              </div>
              <div className="input-div pass">
                <div className="i">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="div">
                  <h5>Password</h5>
                  <input
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocusRemoval}
                    onChange={this.handlePasswordChange}
                    type="password"
                    ref={this.password}
                    required
                    minLength="8"
                    className="input"
                  />
                </div>
              </div>
              <div>
                <Link className="forget-password-link" to="/forget-password">
                  Forgot Password?
                </Link>
              </div>

              <input type="submit" className="submit-button" value="Login" />
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default LoginForm;
