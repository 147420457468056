import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { isDataEntry } from "../../utils/checkRole";
import { subhubImage } from "../../services/imageUrl";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import SaveIcon from "@material-ui/icons/EditOutlined";
import Done from "@material-ui/icons/CheckBoxOutlined";
import Clear from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import Info from "@material-ui/icons/InfoOutlined";

class SubhubTable extends Component {
  state = {
    subhub: {},
    dialogOpen: false,
    viewDetails: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };
  handleViewDetails = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ viewDetails: true });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    if (this.state.redirect === false && this.state.viewDetails === false) {
      return (
        <>
          <MaterialTable
            title="Subhubs"
            columns={[
              {
                title: "Image",
                filtering: false,
                sorting: false,
                field: "imageUrl",
                render: (rowData) => (
                  <img
                    src={subhubImage(rowData.id)}
                    style={{ width: 100, height: 100 }}
                    alt=""
                  />
                ),
              },
              { title: "Name", field: "name" },
              { title: "Arabic Name", field: "arabicName" },
              { title: "Hub", field: "hub" },
            ]}
            data={this.props.subhubs}
            actions={[
              {
                icon: () => <Info />,
                tooltip: "View Subhub",
                onClick: (event, rowData) => {
                  this.handleViewDetails(rowData);
                },
              },
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit Subhub",
                onClick: (event, rowData) => {
                  this.handleRoute(rowData);
                },
              },
              // {
              //   hidden: isDataEntry() || isAuthorizer(),
              //   icon: () => <DeleteIcon color="secondary" />,
              //   tooltip: "Delete Subhub",
              //   onClick: (event, rowData) => {
              //     this.setState({ dialogOpen: true, subhub: rowData });
              //   },
              // },
              (rowData) => ({
                hidden: isDataEntry(),
                icon: () =>
                  rowData.approvedByAdmin === true ? <Done /> : <Clear />,
                tooltip:
                  rowData.approvedByAdmin === true
                    ? "Unapprove subhub"
                    : "Approve subhub",
                onClick: (event, rowData) => this.props.approveSubHub(rowData),
              }),
            ]}
            onRowClick={(event, rowData) =>
              this.setState({ id: rowData.id, redirect: true })
            }
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.subhub}
            handleDelete={this.props.onDelete}
          />
        </>
      );
    }
    if (this.state.redirect === true) {
      return <Redirect to={`/subhubs/form/${this.state.id}`} />;
    }
    if (this.state.viewDetails === true) {
      return <Redirect to={`/subhubs/${this.state.id}`} />;
    }
  }
}

export default SubhubTable;
