import React, { Component } from "react";
import { DonughtChart } from "../common/charts";
import { getHubs, gethHub } from "../../services/hubServices";
import { getSubhubs } from "../../services/subhubsServices";
import { toast } from "react-toastify";
import { paginate } from "../../utils/paginate";
import { Card } from "reactstrap";
import { Grid } from "@material-ui/core";
import Cards from "../common/cardsLayout";
import DonationCard from "../common/cards";
import SearchBox from "../common/SearchBox";
import Pagination from "../common/pagination";
import donationImage from "../images/donation.svg";
import _ from "lodash";
import { sadagaatAccount } from "../../services/donationService";

/**
 * Render donation cards for hubs and subhubs
 */
class Donations extends Component {
  state = {
    data: [50, 3, 23, 2, 30, 7, 23, 8, 17, 12, 40, 19],
    hubs: [],
    pagedHubs: [],
    subhubs: [],
    pagedSubhubs: [],
    sadagaat: {},
    currentPage: 1,
    hubsCurrentPage: 1,
    hubSearchQuery: "",
    pageSize: 3,
    searchQuery: "",
    selectedStatus: null,
    hubSelectedStatus: null,
    sortColumn: { path: "name", order: "asc" },
  };

  /**
   * Fetch api to get hubs and subhubs
   */
  async componentDidMount() {
    try {
      const { data: hubs } = await getHubs();
      const { data: subhubs } = await getSubhubs();
      const { data: sadagaat } = await sadagaatAccount();
      this.setState({ hubs, subhubs, sadagaat });
    } catch (error) {
      toast.error("Something went wrong");
    }
  }
  /**
   *
   * Pagination
   */
  getPagedSubhubs = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      subhubs: allSubhubs,
    } = this.state;

    let filteredSubhubs = allSubhubs;
    if (searchQuery)
      filteredSubhubs = allSubhubs.filter((m) =>
        m.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(
      filteredSubhubs,
      [sortColumn.path],
      [sortColumn.order]
    );

    const subhubs = paginate(sorted, currentPage, pageSize);
    return { subhubsTotalCount: filteredSubhubs.length, data: subhubs };
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedStatus: null, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedHubs = () => {
    const {
      pageSize,
      hubsCurrentPage,
      sortColumn,
      hubSearchQuery,
      hubs: allHubs,
    } = this.state;

    let filteredHubs = allHubs;
    if (hubSearchQuery)
      filteredHubs = allHubs.filter((m) =>
        m.name.toLowerCase().includes(hubSearchQuery.toLowerCase())
      );

    const sorted = _.orderBy(
      filteredHubs,
      [sortColumn.path],
      [sortColumn.order]
    );

    const hubs = paginate(sorted, hubsCurrentPage, pageSize);
    return { hubsTotalCount: filteredHubs.length, data: hubs };
  };

  handleHubPageChange = (page) => {
    this.setState({ hubsCurrentPage: page });
  };

  handleHubSearch = (query) => {
    this.setState({
      hubSearchQuery: query,
      hubSelectedStatus: null,
      hubsCurrentPage: 1,
    });
  };

  handleHubSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { subhubsTotalCount, data: pagedSubhubs } = this.getPagedSubhubs();
    const { hubsTotalCount, data: pagedHubs } = this.getPagedHubs();
    const { availableAmount } = this.state.sadagaat;
    return (
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <DonughtChart
              data={this.state.data}
              height={245}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <DonationCard
              image={donationImage}
              btn_text="Donate"
              title="Deposit Donation"
              path="/donations/donate"
              amount={availableAmount || 0}
            />
          </Grid>
        </Grid>

        <div className="row mt-3">
          <div className="col">
            <Card className="px-2">
              <DonationCard
                image={donationImage}
                btn_text="Donate to project"
                title="Sadagaat"
                path="/donations/hub/form/sadagaat"
                amount={availableAmount || 0}
              />
              <SearchBox
                onChange={this.handleHubSearch}
                value={this.state.hubSearchQuery}
                placeholder="Searh Hubs ..."
              />
              <Cards data={pagedHubs} path="/donations/hub/form" hubs />
              <Pagination
                pageSize={this.state.pageSize}
                currentPage={this.state.hubsCurrentPage}
                onPageChange={this.handleHubPageChange}
                itemsCount={hubsTotalCount}
              />
            </Card>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <Card className="px-2">
              <SearchBox
                onChange={this.handleSearch}
                value={this.state.searchQuery}
                placeholder="Searh Subhubs ..."
              />
              <Cards data={pagedSubhubs} path="/donations/subhub/form" />
              <Pagination
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                onPageChange={this.handlePageChange}
                itemsCount={subhubsTotalCount}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default Donations;
