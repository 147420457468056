import React, {Component} from "react";
import {Link} from "react-router-dom";
import {approveSubHub, deleteSubhub, getSubhubs,} from "../../services/subhubsServices";
import {getHubs} from "../../services/hubServices";
import {paginate} from "../../utils/paginate";
import {toast} from "react-toastify";
import _ from "lodash";
import SubhubTable from "./table";
import Loader from "../common/loader";

/**
 * Fetch SubHubs from API and Display SubHubs table, Add new SubHub
 * @component
 */
class Subhubs extends Component {
  state = {
    subhubs: [],
    pagedSubhubs: [],
    currentPage: 1,
    pageSize: 5,
    searchQuery: "",
    selectedStatus: null,
    sortColumn: { path: "name", order: "asc" },
    loading: true,
  };

  async componentDidMount() {
    try {
      const { data: subhubs } = await getSubhubs();
      const { data: hubs } = await getHubs();
      const tableData = this.populateTableDate(subhubs, hubs);
      this.setState({ subhubs: tableData, loading: false });
    } catch (error) {
      toast.error("Could not fetch subhubs from server");
      console.log(error);
    }
  }

  handleSubhubApproval = async (subhub) => {
    const originalSubhubs = this.state.subhubs;
    const subhubs = [...this.state.subhubs];
    const index = subhubs.findIndex((n) => n.id === subhub.id);
    subhubs[index] = {
      ...subhubs[index],
      approvedByAdmin: !subhubs[index].approvedByAdmin,
    };
    this.setState({ subhubs });
    try {
      await approveSubHub(subhub);
      toast.success("Approval Changed");
    } catch (error) {
      toast.error("Could Not Change Approval");
      this.setState({ subhubs: originalSubhubs });
    }
  };

  populateTableDate = (subhubs, hubs) => {
    return subhubs.map((s) => {
      return {
        id: s.id,
        name: s.name,
        arabicName: s.subHubTranslations.find((sub) => sub.locale === "ar").name,
        description: s.description,
        approvedByAdmin: s.approvedByAdmin,
        hub: hubs?.find((h) => h.id === s.hubId)?.name,
      }
    });
  };

  handleSubhubDelete = async (subhub) => {
    const originalSubhubs = this.state.subhubs;
    const subhubs = originalSubhubs.filter((s) => s.id !== subhub.id);
    this.setState({ subhubs });

    try {
      const res = await deleteSubhub(subhub.id);
      if (res) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This subhub has already been deleted.");
      this.setState({ subhubs: originalSubhubs });
    }
  };

  getPagedSubhubs = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedStatus,
      searchQuery,
      subhubs: allSubhubs,
    } = this.state;

    let filteredSubhubs = allSubhubs;
    if (searchQuery)
      filteredSubhubs = allSubhubs.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedStatus && selectedStatus.id)
      filteredSubhubs = allSubhubs.filter(
        (m) => m.genre.id === selectedStatus.id
      );

    const sorted = _.orderBy(
      filteredSubhubs,
      [sortColumn.path],
      [sortColumn.order]
    );

    const subhubs = paginate(sorted, currentPage, pageSize);
    return { subhubsTotalCount: filteredSubhubs.length, data: subhubs };
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedStatus: null, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };
  render() {
    const { loading, subhubs } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Link
          to="/subHubs/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{ marginBottom: 20 }}
        >
          New Subhub
        </Link>

        <SubhubTable
          subhubs={subhubs}
          onDelete={this.handleSubhubDelete}
          approveSubHub={this.handleSubhubApproval}
        />
      </>
    );
  }
}

export default Subhubs;
