import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import Done from "@material-ui/icons/CheckBoxOutlined";
import Clear from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { isDataEntry, isAuthorizer } from "../../utils/checkRole";
import { newsImage } from "../../services/imageUrl";

class NewsTable extends Component {
  state = {
    news: {},
    dialogOpen: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { news, handleNewsDelete } = this.props;
    if (this.state.redirect === false) {
      return (
        <>
          <MaterialTable
            title="News Table"
            columns={[
              {
                title: "Image",
                sorting: false,
                field: "imageUrl",
                render: (rowData) => (
                  <img
                    src={newsImage(rowData.id)}
                    style={{ width: 100, height: 100 }}
                    alt="News Img"
                  />
                ),
              },
              { title: "Name", field: "name" },
              { title: "Descrition", field: "description" },
              {
                title: "Expire Date",
                field: "expireDate",
              },
            ]}
            data={news}
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
            actions={[
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit News",
                onClick: (event, rowData) => this.handleRoute(rowData),
              },
              (rowData) => ({
                hidden: isDataEntry() || isAuthorizer(),
                icon: () => <DeleteIcon color="secondary" />,
                tooltip: "Delete News",
                onClick: (event, rowData) =>
                  this.setState({ dialogOpen: true, news: rowData }),
              }),
              (rowData) => ({
                hidden: isDataEntry(),
                icon: () =>
                  rowData.approvedByAdmin === true ? <Done /> : <Clear />,
                tooltip:
                  rowData.approvedByAdmin === true
                    ? "Unapprove News"
                    : "Approve News",
                onClick: (event, rowData) => this.props.approveNews(rowData),
              }),
            ]}
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.news}
            handleDelete={handleNewsDelete}
          />
        </>
      );
    } else {
      return <Redirect to={`/news/form/${this.state.id}`} />;
    }
  }
}

export default NewsTable;
