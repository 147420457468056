import React from "react";

const FirstStep = ({
  renderInput,
  renderArabicInput,
  renderSelect,
}) => {
  return (
    <>
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {renderInput("nameEnglish", "Name", "text", true)}
            {renderSelect(
              "statusEnglish",
              "Status",
              [
                { name: "ongoing", value: "ongoing" },
                { name: "completed", value: "completed" },
              ],
              true
            )}
            {renderInput("subscribed", "Registered Volunteers", "number", true)}
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {renderArabicInput("nameArabic", "Arabic Name", "text", true)}
            {renderSelect(
              "statusArabic",
              "Arabic Status",
              [
                { name: "قيد العمل", value: "ongoing" },
                { name: "مكتمل", value: "completed" },
              ],
              true
            )}
            {renderInput("target", "Target", "number", true)}
            {renderInput("startAt", "Date", "date", true)}
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstStep;
