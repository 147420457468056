import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

export function refund(transId) {
  return http.post(
    `${config.globalApi}/refund`,
    { transferId: transId },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function moneyTransfer() {
  return http.get(`${config.globalApi}/moneyTransfer`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function getTransferId(projectId) {
  await http.get(`${config.globalApi}/${projectId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
