import React from "react";
import { Link } from "react-router-dom";

/**
 * Display 404 NotFound Page with link to the Dashboard's Home Page
 * @component
 */
export default function NotFound() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <div></div>
          <h1>404</h1>
        </div>
        <h2>not found</h2>
        <p>
          What you are looking for might have been removed, had its name changed
          or is temporarily unavailable.
        </p>
        <Link to="/dashboard">home</Link>
      </div>
    </div>
  );
}
