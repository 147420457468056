import React from "react";
import PropTypes from "prop-types";

const TextArea = ({ name, label, error, required, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <textarea
        className="form-control"
        style={{
          borderRadius: "20px",
          border: "solid #029746",
          overflowY: "hidden",
        }}
        rows="1"
        {...rest}
        name={name}
        id={name}
      />
      {error && (
        <p
          className="alert alert-light text-danger text-center"
          style={{ borderRadius: "25px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

/**
 * Render TextArea Element
 */
export const ArabicTextArea = ({ name, label, error, required, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <textarea
        dir="rtl"
        className="form-control"
        style={{
          borderRadius: "20px",
          border: "solid #029746",
          overflowY: "hidden",
        }}
        rows="1"
        {...rest}
        name={name}
        id={name}
      />
      {error && (
        <p
          className="alert alert-light text-danger text-center"
          style={{ borderRadius: "25px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

ArabicTextArea.propTypes = {
  /** element name */
  name: PropTypes.string.isRequired,
  /** element label */
  label: PropTypes.string.isRequired,
  /** html attribute required */
  required: PropTypes.bool.isRequired,
  /** error message*/
  error: PropTypes.any,
};

export default TextArea;
