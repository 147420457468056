import jwtDecode from "jwt-decode";
import http from "./httpService";
import config from "../config.json";

const apiEndpoint = config.globalApi + "/users/authenticate";
const tokenKey = "token";
const role = "role";
const userId = "id";

// http.setJwt(getJwt());

export async function login(email, password) {
  const parameters = { userName: email, password: password };
  const { data: jwt } = await http.post(apiEndpoint, parameters);
  localStorage.setItem(userId, jwt.id);
  localStorage.setItem(tokenKey, jwt.token);
  localStorage.setItem(role, jwt.role);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(role);
  localStorage.removeItem(userId);
  window.location.replace("/");
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getUserRole() {
  const token = localStorage.getItem(tokenKey);
  const userRole = localStorage.getItem(role);
  return { token, userRole };
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
