import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/members";

function memberUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function approveMemberUrl(id) {
  return `${apiEndpoint}/approve/${id}`;
}

export function getTotalMembersNumber(startDate, endDate) {
  if (!startDate || !endDate) {
    return http.get(`${apiEndpoint}/total-member-between`, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.get(
      `${apiEndpoint}/total-member-between?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + getJwt(),
        },
      }
    );
  }
}

export function getMembers() {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getPaginatedMembers(page, itemsPerPage, searchQuery) {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
    params: {
      page,
      itemsPerPage,
      searchQuery,
    },
  });
}

export function getMember(memberId) {
  return http.get(memberUrl(memberId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveMember(member) {
  if (member.volunteeredBefore === "No" || member.volunteeredBefore === "") {
    member.volunteeredProjects = "";
  }
  if (member.id) {
    const body = { ...member };
    delete body.id;
    return http.put(memberUrl(member.id), body, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    delete member.id;
    return http.post(apiEndpoint, member, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
}

export async function approveMember(member) {
  await http.put(
    approveMemberUrl(member.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function deleteMember(memberId) {
  return http.delete(memberUrl(memberId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
