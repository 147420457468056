import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";
import progress from "../utils/progress";

const apiEndpoint = config.globalApi + "/programs";

function programUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function approveProgramUrl(id) {
  return `${apiEndpoint}/approve/${id}`;
}

export function getProjects() {
  return http.get(apiEndpoint, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
    params: {
      page: 0,
      size: 100,
      searchQuery: "",
    },
  });
}

export function getProject(projectId) {
  return http.get(programUrl(projectId), {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveProject(project, onPercentageChange) {
  const newProject = {
    target: parseInt(project.target),
    subscribed: parseInt(project.subscribed),
    startAt: project.startAt,
    base64Images: project.base64Images,
    base64Image: project.base64Image,
    programTranslations: [
      {
        locale: "en",
        name: project.nameEnglish,
        description: project.descriptionEnglish,
        locationName: project.locationNameEnglish,
        status: project.statusEnglish,
        default: false,
      },
      {
        locale: "ar",
        name: project.nameArabic,
        description: project.descriptionArabic,
        locationName: project.locationNameArabic,
        status: project.statusArabic,
        default: false,
      },
    ],
    locale: "en",
    name: project.nameEnglish,
    description: project.descriptionEnglish,
    status: project.statusEnglish,
    locationName: project.locationNameEnglish,
  };
  if (project.id) {
    if (!project.base64Image) {
      delete newProject["base64Image"];
    }
    delete newProject["base64Images"];
    const updatedProject = {
      ...newProject,
      programTranslations: [
        { ...newProject.programTranslations[0], id: project.englishId },
        { ...newProject.programTranslations[1], id: project.arabicId },
      ],
    };
    return http.put(programUrl(project.id), updatedProject, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.post(apiEndpoint, newProject, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    });
  }
}

export async function approveProject(project) {
  await http.post(
    approveProgramUrl(project.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export function addProgramVideo(video, id, onPercentageChange) {
  const data = new FormData();
  data.append("file", video);

  return http.post(`${apiEndpoint}/video/add/${id}`, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
    onUploadProgress: (progressEvent) =>
      onPercentageChange(progress(progressEvent)),
  });
}

export async function deleteProgramVideo(videos, id) {
  return videos.forEach((video) => {
    http.post(`${apiEndpoint}/video/delete/${id}`, video, {
      headers: {
        Authorization: "Bearer " + getJwt(),
        "Content-Type": "application/json",
      },
    });
  });
}

export async function updateProjectImages(id, newImages) {
  await http.post(`${apiEndpoint}/image/add/${id}`, newImages, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteProjectImages(images, id) {
  return http.post(`${apiEndpoint}/image/delete/${id}`, images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteProject(projectId) {
  return http.delete(programUrl(projectId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
