import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteDialog from "../common/dialog";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/EditOutlined";
import Done from "@material-ui/icons/CheckBoxOutlined";
import Clear from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import Info from "@material-ui/icons/InfoOutlined";
import { isDataEntry, isAuthorizer } from "../../utils/checkRole";

class EventsTable extends Component {
  state = {
    event: {},
    dialogOpen: false,
    viewDetails: false,
    redirect: false,
    id: "",
  };

  handleRoute = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ redirect: true });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleViewDetails = (rowData) => {
    const id = rowData.id;
    this.setState({ id });
    this.setState({ viewDetails: true });
  };

  render() {
    if (this.state.redirect === false && this.state.viewDetails === false) {
      return (
        <>
          <MaterialTable
            title="Events Table"
            columns={[
              { title: "Name", field: "name" },
              { title: "Start", field: "startDate" },
              { title: "End", field: "endDate" },
            ]}
            data={this.props.events}
            actions={[
              {
                icon: () => <Info />,
                tooltip: "View event",
                onClick: (event, rowData) => {
                  this.handleViewDetails(rowData);
                },
              },
              {
                icon: () => <SaveIcon color="primary" />,
                tooltip: "Edit event",
                onClick: (event, rowData) => {
                  this.handleRoute(rowData);
                },
              },
              {
                hidden: isDataEntry() || isAuthorizer(),
                icon: () => <DeleteIcon color="secondary" />,
                tooltip: "Delete event",
                onClick: (event, rowData) =>
                  this.setState({ dialogOpen: true, event: rowData }),
              },
              (rowData) => ({
                hidden: isDataEntry(),
                icon: () =>
                  rowData.approvedByAdmin === true ? <Done /> : <Clear />,
                tooltip:
                  rowData.approvedByAdmin === true
                    ? "Unapprove event"
                    : "Approve event",
                onClick: (event, rowData) => {
                  this.props.approveEvent(rowData);
                },
              }),
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#01a553",
              },
              grouping: true,
              searchFieldStyle: {
                backgroundColor: "#01a553",
                padding: "0 5px",
                borderRadius: "10px",
              },
            }}
          />
          <DeleteDialog
            dialogOpen={this.state.dialogOpen}
            handleClose={this.handleClose}
            item={this.state.event}
            handleDelete={this.props.onDelete}
          />
        </>
      );
    }
    if (this.state.redirect === true) {
      return <Redirect to={`/events/form/${this.state.id}`} />;
    }
    if (this.state.viewDetails === true) {
      return <Redirect to={`/events/${this.state.id}`} />;
    }
  }
}

export default EventsTable;
