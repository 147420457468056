import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getUsers, deleteUser } from "../../services/userService";
import { toast } from "react-toastify";
import UsersTable from "./usersTable";
import Loader from "../common/loader";

/**
 * Fetch Users from API and Display Users table, Add new User
 * @component
 */
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [], loading: true };
  }

  async componentDidMount() {
    try {
      const { data: users } = await getUsers();
      this.setState({ users, loading: false });
    } catch (error) {
      toast.error("Could not fetch users from server");
    }
  }

  handleUsersDelete = async (user) => {
    const originalUsers = [...this.state.users];
    const users = originalUsers.filter((p) => p.id !== user.id);
    this.setState({ users });

    try {
      const res = await deleteUser(user.id);
      if (res) toast.success("Deleted Successfully !");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This user has already been deleted.");
      this.setState({ users: originalUsers });
    }
  };

  render() {
    const { loading, users } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Link
          to="/users/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{
            marginBottom: 20,
          }}
        >
          New User
        </Link>
        <UsersTable users={users} onDelete={this.handleUsersDelete} />
      </>
    );
  }
}

export default Users;
