import React, { Component } from "react";
import DonationCard from "../../common/cards";
import {
  getSubhub,
  getSubhubProjects,
} from "../../../services/subhubsServices";
import { DonughtChart } from "../../common/charts";
import { toast } from "react-toastify";
import Loader from "../../common/loader";
import { getTransferId, refund } from "../../../services/transferService";
import { Grid } from "@material-ui/core";
import { subhubImage } from "../../../services/imageUrl";

class SingleSubhubDonation extends Component {
  state = {
    subhub: {},
    projects: [],
    data: [1, 3, 5, 6, 8, 9, 8, 6, 5, 4, 3, 2],
    isLoadung: true,
  };

  async componentDidMount() {
    const subhubId = this.props.match.params.id;
    try {
      const { data: subhub } = await getSubhub(subhubId);
      const { data: projects } = await getSubhubProjects(subhubId);
      this.setState({ subhub, projects, isLoadung: false });
    } catch (error) {
      toast.error("something went wrong");
    }
  }

  handleRevert = async (project) => {
    try {
      const transId = await getTransferId(project.id);
      refund(transId);

      toast.success("money refunded successfully");
    } catch (error) {}
  };

  render() {
    const { subhub, data, isLoadung } = this.state;
    if (isLoadung) {
      return <Loader />;
    }

    return (
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <DonughtChart
              data={data}
              height={245}
              options={{ maintainAspectRatio: false }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <DonationCard
              image={subhubImage(subhub.id)}
              btn_text="Donate To Project"
              amount={subhub.total_donation}
              title={subhub.name}
              path={`/donations/subhub/form/${subhub.id}`}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SingleSubhubDonation;
