import React, { Component } from "react";
import { DonateToProjects } from "../../../services/donationService";
import { getSubhubProjects } from "../../../services/subhubsServices";
import { getProjects } from "../../../services/projectsServices";
import isEmpty from "../../../utils/objectEmpty";
import { toast } from "react-toastify";

class SubhubDonationForm extends Component {
  state = {
    subhubId: "",
    amount: "",
    projectId: "",
    projectOptions: [],
    projects: [],
    project: {},
  };

  _project = React.createRef();
  _amount = React.createRef();

  async componentDidMount() {
    const subhubId = parseInt(this.props.match.params.id);
    try {
      let { data: projects } = await getProjects();

      projects = projects.filter(
        (project) =>
          project.donationProgress < 100 && project.subHubId === subhubId
      );

      const projectOptions = this.mapToOptions(projects);
      this.setState({ subhubId, projectOptions, projects });
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  mapToOptions = (array) => {
    return array.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
  };

  handleProjectChange = (event) => {
    const project = [...this.state.projects].find(
      (project) => project.id == event.target.value
    );
    this.setState({ projectId: event.target.value, project });
  };

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { subhubId, projectId, amount } = this.state;
    try {
      await DonateToProjects("", subhubId, projectId, amount, false);
      this._amount.current.value = "";
      this._project.current.value = "";
      toast.success("Money Added To Project");
    } catch (error) {
      toast.warn(error.response.data, {
        draggable: true,
        autoClose: 7000,
      });
    }
  };

  render() {
    const { project } = this.state;
    const max = isEmpty(project) ? "" : project.goal - project.raised;
    return (
      <div className="card text-center mx-auto mt-5 px-5 py-3 w-50 rounded">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="projects">Projects</label>
            <select
              ref={this._project}
              required
              className="form-control"
              id="projects"
              placeholder="Select Hub "
              aria-describedby="Hubs"
              onChange={this.handleProjectChange}
            >
              <option value=""></option>
              {this.state.projectOptions}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              max={max}
              ref={this._amount}
              type="number"
              className="form-control text-center"
              id="amount"
              required
              onChange={this.handleAmountChange}
            />
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default SubhubDonationForm;
