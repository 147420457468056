import React, { useEffect, useState } from "react";
import { Upload } from "../../common/dropZone";
import Typography from "@material-ui/core/Typography";
import LineGrid from "../../common/lineGrid";

const ThirdStep = (props) => {
  const {
    handleChangeImage,
    handleDropzoneChange,
    files,
    file,
    deleteImage,
    projectImages,
  } = props;
  const [initialFiles, setInitialFiles] = useState([]);

  useEffect(() => {
    setInitialFiles(files);
  }, []);

  return (
    <>
      <div className="card-body m-3 shadow p-3 bg-white rounded">
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <label
              htmlFor="file"
              style={{
                display: "block",
                marginTop: "3px",
              }}
            >
              Change Main Image
            </label>
            <input
              id="file"
              accept="image/*"
              type="file"
              name="base64Image"
              className="btn btn-success mb-2"
              onChange={handleChangeImage}
              encType="multipart/form-data"
            />
            <Typography variant="caption" display="block" gutterBottom>
              {file ? file.name : ""}
            </Typography>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            <Upload
              onChange={handleDropzoneChange}
              files={initialFiles}
              placeholder="Add or drop project images"
            />
          </div>
        </div>
        <LineGrid
          images={projectImages}
          onDelete={deleteImage}
          type="projects"
        />
      </div>
    </>
  );
};

export default ThirdStep;
