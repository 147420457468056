import React, {useEffect, useState} from "react";
import Joi from "joi";
import {urlValidation} from "../ContactUs/Contact";
import Input from "../../common/input";
import {getMagazineLinks, postMagazineLinks} from "../../../services/magazineService";
import {toast} from "react-toastify";

function Magazine() {

    const [state, setState] = useState({
        allMagazineLink: "",
        currentMagazineLink: ""
    });
    const [errors, setErrors] = useState({});

    const mainSchema = Joi.object({
        allMagazineLink: urlValidation,
        currentMagazineLink: urlValidation
    });

    const validateProperty = ({ name, value }) => {
        let obj = { [name]: value };
        let schema = Joi.object({
            [name]: mainSchema.extract(name),
        });
        let { error } = schema.validate(obj, {
            convert: true,
            errors: { wrap: { label: "" } },
        });
        return error ? error.details[0].message : null;
    };

    const handleInputChange = ({ currentTarget: input }) => {
        const errs = { ...errors };
        const res = validateProperty(input);

        if (res) errs[input.name] = res;
        else delete errs[input.name];
        const data = { ...state };
        data[input.name] = input.value;
        setState(data);
        setErrors(errs);
    };

    const buttonDisabled = () => {
        const { error } = mainSchema.validate(state, {
            convert: true,
            abortEarly: false,
        });
        console.log(error)
        return error;
    };

    const renderInput = (name, label, type, required) => {
        return (
            <Input
                type={type}
                name={name}
                value={state[name]}
                label={label}
                onChange={handleInputChange}
                required={required}
                error={errors[name]}
            />
        );
    };

    const renderButton = (label) => {
        return (
            <div className="text-center">
                <button
                    type="submit"
                    disabled={buttonDisabled()}
                    className="btn btn-outline-success mt-3"
                    style={{ borderRadius: "20px" }}>
                    {label}
                </button>
            </div>
        );
    };

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            const res = await postMagazineLinks(state);
            const { allMagazineLink, currentMagazineLink } = res.data;
            setState({ allMagazineLink, currentMagazineLink });
            toast.success("Magazine links updated successfully");
        } catch (ex) {
            toast.error("Failed to update magazine links!");
        }
    }

    async function fetchMagazine() {
        try {
            const res = await getMagazineLinks();
            const { allMagazineLink, currentMagazineLink } = res.data;
            setState({ allMagazineLink, currentMagazineLink });
        } catch (ex) {
            toast.error("Unable to fetch magazine links!");
        }
    }

    useEffect(() => {
        fetchMagazine();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                    {renderInput("allMagazineLink", "Our Magazine", "text", true)}
                </div>
                <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                    {renderInput("currentMagazineLink", "Monthly Magazine", "text", true)}
                </div>
            </div>

            {renderButton("Update")}
        </form>
    )
}

export default Magazine;