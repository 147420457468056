import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.globalApi + "/slider";

export function getSliderImages() {
  return http.get(apiEndpoint, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveSliderData(data) {
  const sliderData = {
    link: data.link,
    base64Image: data.base64Image,
  };
  if (data.id) {
    return http.post(`${apiEndpoint}/add`, data, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  }
  return http.post(`${apiEndpoint}/add`, sliderData, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteSliderItem(imageId) {
  return http.delete(`${apiEndpoint}/delete/${imageId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}
