import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { getCopyright, setCopyright } from "../../../services/copyrightService";
import { toast } from "react-toastify";

/**
 * Render Copyright Configuration Page
 */
class Copyright extends Form {
  state = {
    data: {
      copyrightEn: "",
      copyrightAr: "",
    },
    errors: {},
  };

  schema = {
    copyrightEn: Joi.string()
      .trim()
      .required()
      .regex(/^[A-Za-z\s\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/`~?\xA9\xAE\u2122]*$/)
      .label("Copyright")
      .error(() => {
        return {
          message: "required and english characters only",
        };
      }),
    copyrightAr: Joi.string()
      .trim()
      .required()
      .regex(/[\u0600-\u06FF]/)
      .label("Arabic Copyright")
      .error(() => {
        return {
          message: "required, starts in Arabic",
        };
      }),
  };

  async componentDidMount() {
    try {
      const { data } = await getCopyright();
      if (!data) return;
      this.setState({
        data: this.mapCopyrightToState(data.copyRightTranslation),
      });
    } catch (error) {
      return;
    }
  }

  mapCopyrightToState = (copyright) => {
    return {
      copyrightEn: copyright.find((item) => item.locale === "en")?.text || "",
      copyrightAr: copyright.find((item) => item.locale === "ar")?.text || "",
    };
  };

  doSubmit = async () => {
    try {
      await setCopyright(this.state.data);
      const { data } = await getCopyright();

      this.setState({
        data: this.mapCopyrightToState(data.copyRightTranslation),
      });
      toast.success("copyright updated");
    } catch (error) {
      toast.error("something went wrong !");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderInput("copyrightEn", "Copyright", "text", true)}
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderArabicInput(
              "copyrightAr",
              "Arabic Copyright",
              "text",
              true
            )}
          </div>
        </div>
        {this.renderButton("Update")}
      </form>
    );
  }
}

export default Copyright;
