import React from "react";
import { Progress as Bar } from "reactstrap";
import PropTypes from "prop-types";

/**
 * Shows the progress of the user requests.
 * By giving *value* and *label* it shows the
 * progress percentage and label in the UI.
 * @component
 * @example <caption> Progress on load </caption>
 * return (<Progress value={70} label={"saving project"}/>)
 *
 */

const Progress = ({ value, label }) => {
  return (
    <div className="mt-2" style={{ position: "sticky", bottom: 0 }}>
      <Bar color="success" value={value}>
        {label}
      </Bar>
    </div>
  );
};

Progress.propTypes = {
  /** The upload percentage */
  value: PropTypes.number.isRequired,
  /** The label to show on the progress bar */
  label: PropTypes.string.isRequired,
};

export default Progress;
