import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import {
  getDonationDescription,
  updateDonationDescription,
} from "../../../services/donationService";
import { toast } from "react-toastify";

/**
 * Render Donation Configuration Page which contains donation into
 */
class Donation extends Form {
  state = {
    data: {
      descriptionAr: "",
      descriptionEn: "",
    },
    errors: {},
  };

  async componentDidMount() {
    try {
      const { data } = await getDonationDescription();
      const descArray = data.donationDescriptionTranslations;
      this.setState({ ...this.state, data: this.mapDescToState(descArray) });
    } catch (error) {
      return;
    }
  }

  mapDescToState = (descArray) => {
    return {
      descriptionAr: descArray[1].description || "",
      descriptionEn: descArray[0].description || "",
    };
  };

  schema = {
    descriptionAr: Joi.string()
      .trim()
      .required()
      .label("Arabic Text")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    descriptionEn: Joi.string()
      .trim()
      .required()
      .regex(/^[A-Za-z\s\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/`~?]*$/)
      .label("English Text")
      .error(() => {
        return {
          message: "required and english characters only",
        };
      }),
  };

  doSubmit = async () => {
    try {
      await updateDonationDescription(this.state.data);
      const { data } = await getDonationDescription();

      this.setState({
        ...this.state,
        data: this.mapDescToState(data.donationDescriptionTranslations),
      });
      toast.success("updated Successfully");
    } catch (error) {
      toast.error("something went wrong !");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderEditor("descriptionEn", "English Text", "en", true)}
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderEditor("descriptionAr", "Arabic Text", "ar", true)}
          </div>
        </div>

        {this.renderButton("Update")}
      </form>
    );
  }
}

export default Donation;
