import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCurrencies,
  saveCurrency,
  toggleCurrencyActive,
} from "../../services/currencyService";
import Loader from "../common/loader";
import CurrencyTable from "./currenciesTable";

/**
 * A component to display a datatable of the currencies alongside their actions => edit/delete/view and a button to create a new currency
 * @component
 */
const Currencies = () => {
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);

  const fetchCurrencies = async () => {
    try {
      const { data: currencies } = await getCurrencies();
      currencies.forEach((currency) => {
        const arabicTranslation = currency.currencyTranslations.find(
          (t) => t.locale === "ar"
        );
        const englishTranslation = currency.currencyTranslations.find(
          (t) => t.locale === "en"
        );
        currency.currencyTranslations = [englishTranslation, arabicTranslation];
      });

      setCurrencies(currencies);
      setLoading(false);
    } catch (error) {
      toast.error("Could not fetch Currencies from server");
    }
  };

  const handleActiveToggle = async (currency) => {
    const originalCurrencies = currencies;
    const newCurrencies = [...currencies];
    const index = newCurrencies.findIndex((n) => n.id === currency.id);
    newCurrencies[index] = {
      ...newCurrencies[index],
      active: !newCurrencies[index].active,
    };
    setCurrencies(newCurrencies);
    try {
      await toggleCurrencyActive(currency.id);
      toast.success("changed successfuly ");
    } catch (error) {
      toast.error("Could Not Change ");
      setCurrencies(originalCurrencies);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Link
        to="/currencies/form/new"
        className="btn btn-sm btn-outline-success m-2"
        style={{ marginBottom: 20 }}
      >
        Add Currency
      </Link>
      <CurrencyTable
        currencies={currencies}
        toggleActive={handleActiveToggle}
      />
    </div>
  );
};

export default Currencies;
