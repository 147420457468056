import React from "react";
import {
  getVolunteer,
  saveVolunteer,
  deleteVolunteerVideos,
  deleteVolunteerImages,
} from "../../services/volunteer";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../common/form";
import NotFound from "../NotFound/NotFound";
import LineGrid from "../common/lineGrid";
import Progress from "../common/progress";
import UploadZone, { VideoUpload } from "../common/dropZone";

/**
 * Fetch Vlounteers from API and Display Vlounteers table, Add new Vlounteer
 * @component
 */
class VolunteersPage extends Form {
  state = {
    volunteerVideos: [],
    videoFiles: [],
    video: {},
    sendingData: false,
    image: "",
    uploadPercentage: "",
    imagesToDelete: [],
    videosToDelete: [],
    volunteerImages: [],
    files: [],
    data: {
      id: "",
      base64Images: [],
      htmlPageAr: "",
      htmlPageEn: "",
    },
    errors: {},
    error: false,
  };
  schema = {
    id: Joi.label("Id"),
    htmlPageAr: Joi.string()
      .trim()
      .required()
      .label("Arabic Text")
      .regex(/[\u0600-\u06FF]/)
      .error(() => {
        return {
          message: "required and arabic characters only",
        };
      }),
    htmlPageEn: Joi.string()
      .trim()
      .required()
      .regex(/^[A-Za-z\s\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/`~?]*$/)
      .label("English Text")
      .error(() => {
        return {
          message: "required and english characters only",
        };
      }),
    base64Images: Joi.label("Images"),
  };

  async componentDidMount() {
    await this.populateVolunteer();
  }

  async populateVolunteer() {
    try {
      const { data: volunteer } = await getVolunteer();
      this.setState({
        data: this.mapToViewModel(volunteer),
        volunteerImages: volunteer.images,
        volunteerVideos: volunteer.video,
      });
    } catch (ex) {
      this.setState({ error: true });
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/dashboard");
      toast.warn("Something Went Wrong !");
    }
  }

  mapToViewModel(volunteer) {
    return {
      id: volunteer.id || "",
      htmlPageEn: volunteer.htmlPageEn || "",
      htmlPageAr: volunteer.htmlPageAr || "",
    };
  }

  UploadPercentage = (percentage) => {
    this.setState({ uploadPercentage: percentage });
  };

  setupReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = { ...this.state.data };
      const base64 = e.target.result.split(",")[1];
      data.base64Images.push(base64);
      this.setState({ data });
    };
    reader.readAsDataURL(file);
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const files = [...this.state.files];
    const filteredFiles = files.filter((f) => f !== file);
    const updatedFiles = [...this.state.files, file];
    if (status === "error_file_size") {
      toast.warn("Max image size is 2MB!");
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "done") {
      this.setState({
        files: updatedFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
    if (status === "removed") {
      this.setState({
        files: filteredFiles,
        data: { ...this.state.data, base64Images: [] },
      });
    }
  };

  deleteImage = (imageId) => {
    const originalImages = [...this.state.volunteerImages];
    const updatedImages = originalImages.filter((img) => img.id !== imageId);
    const imagesToDelete = [...this.state.imagesToDelete];
    imagesToDelete.push(imageId);
    this.setState({ volunteerImages: updatedImages, imagesToDelete });
  };

  handleVideoChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ video: file });
    }
    if (status === "removed") {
      this.setState({ video: {} });
    }
  };

  handleChangeImage = (evt) => {
    try {
      var reader = new FileReader();
      var file = evt.target.files[0];
      if (file.size > 2097152) {
        toast.warn("Maximum allowed size for images is 2Mb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, base64Image: "" } });
      } else {
        reader.onload = (upload) => {
          const newImage = { ...this.state.data };
          newImage.base64Image = upload.target.result.split(",")[1];
          this.setState({
            data: newImage,
          });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      return;
    }
  };

  handleVideoDelete = (videoId) => {
    const originalVideos = [...this.state.volunteerVideos];
    const updatedVideos = originalVideos.filter((vid) => vid.id !== videoId);
    const videosToDelete = [...this.state.videosToDelete];
    videosToDelete.push(videoId);
    this.setState({ volunteerVideos: updatedVideos, videosToDelete });
  };

  handleVolunteerSave = async (data, imagesToDelete) => {
    if (imagesToDelete.length !== 0) {
      await deleteVolunteerImages(imagesToDelete);
    }
    if (this.state.videosToDelete.length > 0) {
      await deleteVolunteerVideos(this.state.videosToDelete);
    }
    await saveVolunteer(data, this.state.video, this.UploadPercentage);
    this.setState({
      data: { ...data, base64Images: [] },
      imagesToDelete: [],
      videosToDelete: [],
    });
    toast.success("Saved");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  doSubmit = async () => {
    const { files, data, imagesToDelete } = this.state;
    try {
      for (let i = 0; i < files.length; i++) {
        this.setupReader(files[i]);
      }
      setTimeout(() => {
        this.handleVolunteerSave(data, imagesToDelete);
      }, 1000);
    } catch (ex) {
      this.setState({
        data: { ...this.state.data, base64Images: [] },
        sendingData: false,
      });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.warn("Some Inputs Are Invalid !");
      }
    }
  };

  render() {
    const { uploadPercentage, volunteerImages, volunteerVideos } = this.state;

    if (this.state.error) {
      return <NotFound />;
    }
    return (
      <>
        <div className="card-body m-3 shadow p-3 bg-white rounded">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderEditor("htmlPageEn", "English Text", "en", true)}
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {this.renderEditor("htmlPageAr", "Arabic Text", "ar", true)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <UploadZone
                  onChange={this.handleChangeStatus}
                  placeholder="Add Images"
                />
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                <VideoUpload
                  onChange={this.handleVideoChange}
                  placeholder="Upload Video"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {volunteerImages && (
                  <LineGrid
                    images={volunteerImages}
                    onDelete={this.deleteImage}
                    type="volunteer"
                  />
                )}
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
                {volunteerVideos && (
                  <div className="video-container">
                    <ul className="video-list">
                      {volunteerVideos.map((video) => (
                        <div className="video-wrapper" key={video.id}>
                          <li className="video-list-item">
                            <p>{video.name}</p>
                            <span>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.handleVideoDelete(video.id)}
                              >
                                Delete
                              </button>
                            </span>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {this.renderButton("Save")}
          </form>
        </div>
        {uploadPercentage === "" || uploadPercentage === 100 ? null : (
          <Progress value={parseInt(uploadPercentage)} label="Saving Data" />
        )}{" "}
      </>
    );
  }
}

export default VolunteersPage;
