import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import Users from "./components/Users/Users";
import UserForm from "./components/Users/UserForm";
import UserEditForm from "./components/Users/userEditForm";
import NotFound from "./components/NotFound/NotFound";
import Projects from "./components/Projects/Projects";
import SingleProjectPage from "./components/Projects/singleProject";
import Home from "./components/Home/Home";
import MemberForm from "./components/Members/MemberForm";
import Members from "./components/Members/Members";
import Hubs from "./components/hubs/hubs";
import HubsForm from "./components/hubs/hubsForm";
import Subhubs from "./components/Subhubs/Subhubs";
import SubhubsForm from "./components/Subhubs/SubhubsForm";
import News from "./components/News/News";
import NewsForm from "./components/News/NewsForm";
import NewsEditForm from "./components/News/newsEditForm";
import EventsForm from "./components/events/eventsForm";
import EventsEditForm from "./components/events/eventsEditForm";
import Events from "./components/events/events";
import logOut from "./components/logout/logOut";
import Donations from "./components/donations/donations";
import SadagaatForm from "./components/donations/donationForm";
import HubDonationForm from "./components/donations/hubs/hubDonationForm";
import SingleHUbDonation from "./components/donations/hubs/singleHub";
import SingleSubhubDonation from "./components/donations/subhubs/singleSubhubDonation";
import subhubDonationForm from "./components/donations/subhubs/subhubDonationForm";
import SingleHub from "./components/hubs/hubSinglePage";
import SingleMember from "./components/Members/SingleMember";
import SingleUser from "./components/Users/singleUser.jsx";
import SingleSubhub from "./components/Subhubs/SingleSubHub";
import SingleEvent from "./components/events/singleEvent";
import HubsEditForm from "./components/hubs/hubsEditForm";
import Transactions from "./components/Transactions/Transactions";
import SubhubsEditForm from "./components/Subhubs/subhubsEditForm";
import UpdatePassword from "./components/Update Password/update_password";
import PrivateAdminRoute from "./components/common/privateAdminRoute";
import PrivateRoute from "./components/common/privateRoute";
import VolunteersPage from "./components/volunteers/VolunteersPage";
import MasterForm from "./components/Projects/Add multistep/masterForm";
import EditMultiStep from "./components/Projects/Edit multistep/masterForm";
import Vacancies from "./components/Vacancies/vacancies";
import VacanciesForm from "./components/Vacancies/vacanciesForm";
import VolunteerProjects from "./components/volunteer-projects/volunteerProjects";
import AddVolunteerProjects from "./components/volunteer-projects/Add multistep/masterForm";
import EditVolunteerProjects from "./components/volunteer-projects/Edit multistep/masterForm";
import SingleVolunteerProject from "./components/volunteer-projects/singleProject";
import Configuration from "./components/configuration/MasterPage";
import Currencies from "./components/currencies/currencies";
import CurrencyForm from "./components/currencies/currencyForm";
import Candidates from "./components/candidates/CandidatesTable";

class Routes extends Component {
  render() {
    return (
      <>
        <Switch>
          <PrivateAdminRoute
            exact
            path='/users/form/new'
            component={UserForm}
          />
          <PrivateAdminRoute
            exact
            path='/users/form/:id'
            component={UserEditForm}
          />
          <PrivateAdminRoute exact path='/users/:id' component={SingleUser} />
          <PrivateAdminRoute exact path='/users' component={Users} />
          <PrivateRoute exact path='/news/form/new' component={NewsForm} />
          <PrivateRoute exact path='/news/form/:id' component={NewsEditForm} />
          <PrivateRoute exact path='/news' component={News} />
          <PrivateRoute exact path='/members/form/:id' component={MemberForm} />
          <PrivateRoute exact path='/members/:id' component={SingleMember} />
          <PrivateRoute exact path='/members' component={Members} />
          <PrivateRoute exact path='/hubs/form/new' component={HubsForm} />
          <PrivateRoute exact path='/hubs/form/:id' component={HubsEditForm} />
          <PrivateRoute exact path='/hubs/:id' component={SingleHub} />
          <PrivateRoute exact path='/hubs' component={Hubs} />
          <PrivateRoute
            exact
            path='/subhubs/form/new'
            component={SubhubsForm}
          />
          <PrivateRoute
            exact
            path='/subhubs/form/:id'
            component={SubhubsEditForm}
          />
          <PrivateRoute exact path='/subhubs/:id' component={SingleSubhub} />
          <PrivateRoute exact path='/subhubs' component={Subhubs} />
          <PrivateRoute
            exact
            path='/projects/form/new'
            component={MasterForm}
          />
          <PrivateRoute
            exact
            path='/projects/form/:id'
            component={EditMultiStep}
          />
          <PrivateRoute
            exact
            path='/projects/:id'
            component={SingleProjectPage}
          />
          <PrivateRoute exact path='/projects' component={Projects} />
          <PrivateRoute
            exact
            path='/programs/form/new'
            component={AddVolunteerProjects}
          />
          <PrivateRoute
            exact
            path='/programs/form/:id'
            component={EditVolunteerProjects}
          />
          <PrivateRoute
            exact
            path='/programs/:id'
            component={SingleVolunteerProject}
          />
          <PrivateRoute exact path='/programs' component={VolunteerProjects} />
          <PrivateRoute exact path='/events/form/new' component={EventsForm} />
          <PrivateRoute
            exact
            path='/events/form/:id'
            component={EventsEditForm}
          />
          <PrivateRoute exact path='/events/:id' component={SingleEvent} />
          <PrivateRoute exact path='/events' component={Events} />
          <PrivateRoute
            exact
            path='/donations/donate'
            component={SadagaatForm}
          />
          <PrivateRoute
            exact
            path='/donations/hub/form/:id'
            component={HubDonationForm}
          />
          <PrivateRoute
            exact
            path='/donations/hub/:id'
            component={SingleHUbDonation}
          />
          <PrivateRoute
            exact
            path='/donations/subhub/form/:id'
            component={subhubDonationForm}
          />
          <PrivateRoute
            exact
            path='/donations/subhub/:id'
            component={SingleSubhubDonation}
          />
          <PrivateRoute exact path='/donations' component={Donations} />
          <PrivateAdminRoute
            exact
            path='/transactions'
            component={Transactions}
          />
          <PrivateRoute exact path='/logout' component={logOut} />
          <PrivateRoute exact path='/dashboard' component={Home} />
          <PrivateRoute
            exact
            path='/update-password'
            component={UpdatePassword}
          />
          <PrivateRoute exact path='/volunteers' component={VolunteersPage} />
          <PrivateRoute
            exact
            path='/vacancies/form/:id'
            component={VacanciesForm}
          />

          <PrivateRoute exact path='/vacancies' component={Vacancies} />
          <PrivateRoute
            exact
            path='/currencies/form/:id'
            component={CurrencyForm}
          />
          <PrivateRoute exact path='/currencies' component={Currencies} />
          <PrivateRoute exact path='/configuration' component={Configuration} />
          <PrivateRoute exact path='/candidates' component={Candidates} />

          <PrivateRoute exact path='/404' component={NotFound} />
          <Redirect to='/404' />
        </Switch>
      </>
    );
  }
}

export default Routes;
