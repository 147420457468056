import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { fileToBase64 } from "../../../utils/filereader";
import { toast } from "react-toastify";
import { getAboutUs, updateAboutUs } from "../../../services/aboutConfig";

/**
 * Render About Us Configuration Page
 */
class AboutUs extends Form {
  state = {
    data: {
      arabicText: "",
      englishText: "",
      visionEnglish: "",
      visionArabic: "",
      missionEnglish: "",
      missionArabic: "",
      valueEnglish: "",
      valueArabic: "",
      about1Image: "",
      about2Image: "",
      about3Image: "",
    },
    errors: {},
  };

  englishValidation = (label) => {
    return Joi.string()
      .regex(/^[A-Za-z\s\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/`~?]*$/)
      .trim()
      .required()
      .label(label)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.regex.base":
              err.message = "English characters only";
              break;
            default:
              break;
          }
        });
        return errors;
      });
  };

  arabicValidation = (label) => {
    return Joi.string()
      .trim()
      .required()
      .label(label)
      .regex(/[\u0600-\u06FF]/)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.regex.base":
              err.message = "required, starts in Arabic";
              break;
            default:
              break;
          }
        });
        return errors;
      });
  };

  schema = {
    englishText: this.englishValidation("English Text"),
    arabicText: this.arabicValidation("Arabic Text"),
    missionEnglish: this.englishValidation("Mission"),
    missionArabic: this.arabicValidation("Arabic Mission"),
    valueEnglish: this.englishValidation("Value"),
    valueArabic: this.arabicValidation("Arabic Value"),
    visionEnglish: this.englishValidation("Vision"),
    visionArabic: this.arabicValidation("Arabic Vision"),
    about1Image: Joi.label("Image"),
    about2Image: Joi.label("Image"),
    about3Image: Joi.label("Image"),
  };

  async componentDidMount() {
    try {
      const { data } = await getAboutUs();
      this.setState({ data: this.mapDataToState(data.aboutUsTranslation) });
    } catch (error) {
      toast.error("Could not retrieve data");
    }
  }

  mapDataToState = (data) => {
    return {
      englishText: data?.find((item) => item.locale === "en")?.text || "",
      arabicText: data?.find((item) => item.locale === "ar")?.text || "",
      missionEnglish: data?.find((item) => item.locale === "en")?.mission || "",
      missionArabic: data?.find((item) => item.locale === "ar")?.mission || "",
      valueEnglish: data?.find((item) => item.locale === "en")?.value || "",
      valueArabic: data?.find((item) => item.locale === "ar")?.value || "",
      visionEnglish: data?.find((item) => item.locale === "en")?.vision || "",
      visionArabic: data?.find((item) => item.locale === "ar")?.vision || "",
      about1Image: "",
      about2Image: "",
      about3Image: "",
    };
  };

  handleChangeImage = async (evt) => {
    evt.persist();
    try {
      const file = evt.target.files[0];
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        toast.warn("Please select a png/jpeg image");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, [evt.target.name]: "" } });
      }
      if (file.size > 300000) {
        toast.warn("Maximum allowed size for images is 300 Kb");
        evt.target.type = "text";
        evt.target.type = "file";
        this.setState({ data: { ...this.state.data, [evt.target.name]: "" } });
      } else {
        const base64 = await fileToBase64(file);
        const data = { ...this.state.data };
        data[evt.target.name] = base64;
        this.setState({ data });
      }
    } catch (error) {
      return;
    }
  };
  doSubmit = async () => {
    const { data } = this.state;
    try {
      await updateAboutUs(data);
      toast.success("Updated Successfully");
    } catch (ex) {
      this.setState({ sendingData: false });
      if (ex.response && ex.response.status === 500) {
        toast.warn("Something Went Wrong !");
      }
      if (ex.response && ex.response.status === 403) {
        toast.error("You Are Not Authorized to complete this action !");
      }
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.url);
      }
    }
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderTextArea("englishText", "English Text", "text", true)}
            {this.renderTextArea("visionEnglish", "Vision", "text", true)}{" "}
            {this.renderTextArea("missionEnglish", "Mission", "text", true)}{" "}
            {this.renderTextArea("valueEnglish", "Value", "text", true)}{" "}
            <label htmlFor="file" style={{ display: "block" }}>
              First Image
            </label>
            <input
              accept=".png, .jpg, .jpeg"
              name="about1Image"
              type="file"
              className="upload-file btn btn-success"
              onChange={this.handleChangeImage}
            />
            <label htmlFor="file" style={{ display: "block" }}>
              Third Image
            </label>
            <input
              accept=".png, .jpg, .jpeg"
              name="about3Image"
              type="file"
              className="upload-file btn btn-success"
              onChange={this.handleChangeImage}
            />
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
            {this.renderArabicTextArea(
              "arabicText",
              "Arabic Text",
              "text",
              true
            )}
            {this.renderArabicTextArea(
              "visionArabic",
              "Arabic Vision",
              "text",
              true
            )}
            {this.renderArabicTextArea(
              "missionArabic",
              "Arabic Mission",
              "text",
              true
            )}
            {this.renderArabicTextArea(
              "valueArabic",
              "Arabic Value",
              "text",
              true
            )}
            <label htmlFor="file" style={{ display: "block" }}>
              Second Image
            </label>
            <input
              name="about2Image"
              accept=".png, .jpg, .jpeg"
              type="file"
              className="upload-file btn btn-success"
              onChange={this.handleChangeImage}
            />
          </div>
        </div>
        {this.renderButton("Update")}
      </form>
    );
  }
}
export default AboutUs;
