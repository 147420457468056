import http from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";
import progress from "../utils/progress";

const apiEndpoint = config.globalApi + "/projects";
const paginatedProjectsApi = `${config.globalApi}/projects/header`;
const apiFeaturedProjectsEndpoint = `${apiEndpoint}/featured-ongoing`;

function projectUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function approveProjectUrl(id) {
  return `${apiEndpoint}/approve/${id}`;
}

export function getProjects() {
  return http.get(apiEndpoint, {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getPaginatedProjects() {
  return http.get(paginatedProjectsApi, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function getProject(projectId) {
  return http.get(projectUrl(projectId), {
    headers: {
      "accept-language": "en",
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function saveProject(project, onPercentageChange) {
  let projectProgress;

  if (
    project.statusEnglish === "planned" ||
    project.statusArabic === "planned"
  ) {
    projectProgress = 0;
  } else {
    projectProgress = parseInt(project.projectProgress);
  }

  const newProject = {
    currencyId: project.currencyId,
    projectProgress: projectProgress,
    goal: parseInt(project.goal),
    raised: parseInt(project.raised),
    subHubId: parseInt(project.subHubId),
    startAt: project.startAt,
    base64Images: project.base64Images,
    base64Image: project.base64Image,
    locationLat: project.locationLat,
    locationLng: project.locationLng,
    pinToHome: project.pinToHome,
    projectTranslations: [
      {
        locale: "en",
        name: project.nameEnglish,
        description: project.descriptionEnglish,
        locationName: project.locationNameEnglish,
        status: project.statusEnglish,
        default: false,
      },
      {
        locale: "ar",
        name: project.nameArabic,
        description: project.descriptionArabic,
        locationName: project.locationNameArabic,
        status: project.statusArabic,
        default: false,
      },
    ],
    locale: "en",
    name: project.nameEnglish,
    description: project.descriptionEnglish,
    status: project.statusEnglish,
    locationName: project.locationNameEnglish,
  };
  if (project.id) {
    if (!project.base64Image) {
      delete newProject["base64Image"];
    }
    delete newProject["base64Images"];
    const updatedProject = {
      ...newProject,
      projectTranslations: [
        { ...newProject.projectTranslations[0], id: project.englishId },
        { ...newProject.projectTranslations[1], id: project.arabicId },
      ],
    };
    return http.put(projectUrl(project.id), updatedProject, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    });
  } else {
    return http.post(apiEndpoint, newProject, {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    });
  }
}

export async function approveProject(project) {
  await http.put(
    approveProjectUrl(project.id),
    {},
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export async function updateProjectImages(id, newImages, onPercentageChange) {
  await http.post(
    `${apiEndpoint}/image/add`,
    { id: id, base64Images: newImages },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
      onUploadProgress: (progressEvent) =>
        onPercentageChange(progress(progressEvent)),
    }
  );
}

export async function getProjectSteps(projectId) {
  return http.get(`${apiEndpoint}/step/${projectId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function addProjectStep(data) {
  const step = {
    base64Images: data.base64Images,
    stepTranslation: [
      {
        locale: "en",
        text: data.stepEnglishDecsription,
      },
      {
        locale: "ar",
        text: data.stepArabicDescription,
      },
    ],
  };
  const details = JSON.stringify(step);
  const blob = new Blob([details], {
    type: "application/json",
  });

  const formData = new FormData();
  formData.append("file", data.video);
  formData.append("step", blob);

  return http.post(`${apiEndpoint}/step/${data.id}`, formData, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function updateProjectStep(step) {
  const stepTranslation = [
    { id: step.englishId, text: step.englishText, locale: "en" },
    {
      id: step.arabicId,
      text: step.arabicText,
      locale: "ar",
    },
  ];

  return http.post(
    `${apiEndpoint}/step/update/${step.id}`,
    { stepTranslation },
    {
      headers: {
        Authorization: "Bearer " + getJwt(),
      },
    }
  );
}

export async function updateStepImages(base64Images, stepId) {
  return http.post(`${apiEndpoint}/step/image/add/${stepId}`, base64Images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function deleteStepImages(base64Images, stepId) {
  return http.post(`${apiEndpoint}/step/image/delete/${stepId}`, base64Images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function addStepVideo(video, stepId) {
  const data = new FormData();
  data.append("file", video);

  return http.post(`${apiEndpoint}/step/video/add/${stepId}`, data, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export async function deleteStepVideo(videoIds, stepId) {
  return videoIds.forEach((videoId) => {
    http.post(`${apiEndpoint}/step/video/delete/${stepId}`, videoId, {
      headers: {
        Authorization: "Bearer " + getJwt(),
        "content-type": "application/json",
      },
    });
  });
}

export async function deleteProjectStep(stepId) {
  return http.delete(`${apiEndpoint}/step/${stepId}`, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteProjectImages(images) {
  return http.post(`${apiEndpoint}/image/delete`, images, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function deleteProject(projectId) {
  return http.delete(projectUrl(projectId), {
    headers: {
      Authorization: "Bearer " + getJwt(),
    },
  });
}

export function putFeaturedOngoingProject(projectId) {
  return http.put(`${apiFeaturedProjectsEndpoint}/${projectId}`, null, {
    headers: {
      Authorization: "Bearer " + getJwt(),
    }
  })
}
