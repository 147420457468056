import React from "react";
import PropTypes from "prop-types";

const Select = ({ name, label, options, required, error, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <select
        name={name}
        id={name}
        {...rest}
        className="form-control"
        style={{
          borderRadius: "20px",
          border: "solid #029746",
        }}
      >
        <option value="" />
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {error && (
        <p
          className="alert alert-light text-danger text-center"
          style={{ borderRadius: "25px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

/**
 * Render an Arabic (rtl) Select
 */
export const ArabicSelect = ({
  name,
  label,
  options,
  error,
  required,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <select
        dir="rtl"
        name={name}
        id={name}
        {...rest}
        className="form-control"
        style={{
          borderRadius: "20px",
          border: "solid #029746",
        }}
      >
        <option value="" />
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {error && (
        <p
          className="alert alert-light text-danger text-center"
          style={{ borderRadius: "25px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

ArabicSelect.propTypes = {
  /** element name */
  name: PropTypes.string.isRequired,
  /** element label */
  label: PropTypes.string.isRequired,
  /** select element options*/
  options: PropTypes.array.isRequired,
  /** html attribute required */
  required: PropTypes.bool.isRequired,
  /** error message*/
  error: PropTypes.any,
};

export default Select;
