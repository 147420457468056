import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getEvents,
  deleteevent,
  approveEvent,
} from "../../services/eventService";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import EventsTable from "./eventsTable";

/**
 * Display Events Table and Add Event Button
 * @component
 */
class Events extends Component {
  state = { events: [], loading: true };

  /**
   * Fetch Events from API
   */
  async componentDidMount() {
    try {
      const { data: events } = await getEvents();
      this.setState({ events, loading: false });
    } catch (error) {
      toast.error("Could not fetch events from server");
    }
  }

  handleEventApproval = async (event) => {
    const originalEvents = this.state.events;
    const events = [...this.state.events];
    const index = events.findIndex((n) => n.id === event.id);
    events[index] = {
      ...events[index],
      approvedByAdmin: !events[index].approvedByAdmin,
    };
    this.setState({ events });
    try {
      await approveEvent(event);
      toast.success("Approval Changed");
    } catch (error) {
      toast.error("Could Not Change Approval");
      this.setState({ events: originalEvents });
    }
  };

  handleEventDelete = async (event) => {
    const originalevents = this.state.events;
    const events = originalevents.filter((n) => n.id !== event.id);
    this.setState({ events });

    try {
      const res = await deleteevent(event.id);
      if (res) toast.success("Deleted Successfuly !");
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 500)
        this.props.history.replace("/events");
      this.setState({ events: originalevents });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div>
        <Link
          to="/events/form/new"
          className="btn btn-sm btn-outline-success m-2"
          style={{ marginBottom: 20 }}
        >
          Add event
        </Link>
        <EventsTable
          events={this.state.events}
          onDelete={this.handleEventDelete}
          approveEvent={this.handleEventApproval}
        />
      </div>
    );
  }
}

export default Events;
