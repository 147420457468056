import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Upload, VideoUpload } from "../../common/dropZone";

const FifthStep = (props) => {
  const {
    renderEditor,
    onImageChange,
    onStepVideoChange,
    onStepSave,
    disableStepSave,
    files,
    video,
  } = props;
  const [initialFiles, setInitialFiles] = useState([]);

  useEffect(() => {
    setInitialFiles(files);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          {renderEditor("stepEnglishDecsription", "Description", "en", true)}
          <Upload
            onChange={onImageChange}
            files={initialFiles}
            placeholder="Add Step Images"
          />
        </div>
        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12">
          {renderEditor(
            "stepArabicDescription",
            "Arabic Description",
            "ar",
            true
          )}
          <VideoUpload
            onChange={onStepVideoChange}
            placeholder="Upload Video"
            file={[video]}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center m-2">
        <Button
          variant="contained"
          color="primary"
          size="small"
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          disabled={disableStepSave()}
          onClick={onStepSave}
        >
          Add Step
        </Button>
      </div>
    </>
  );
};

export default FifthStep;
