import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ar";
import PropTypes from "prop-types";

/**
 * Rich Text Editor component with RTL and arabic localization support
 * @component
 */
const defaultToolbar = [
  "heading",
  "|",
  "bold",
  "link",
  "|",
  "bulletedList",
  "numberedList",
  "|",
  "insertTable",
  "|",
  "undo",
  "redo",
];
const Editor = ({
  value,
  onChange,
  name,
  label,
  error,
  language,
  required,
  toolbar,
}) => {
  const configuration = {
    language: language || "en",
    toolbar: toolbar || defaultToolbar,
    removePlugins: ["BlockQuote", "MediaEmbed"],
    highlight: {
		options: [
			{ 
				model: "yellowMarker",
				class: "marker-yellow",
				title: "Yellow Marker",
				color: "var(--ck-highlight-marker-yellow)",
				type: "marker",
			},
			{
				model: "greenMarker",
				class: "marker-green",
				title: "Green marker",
				color: "var(--ck-highlight-marker-green)",
				type: "marker",
			},
			{
				model: "pinkMarker",
				class: "marker-pink",
				title: "Pink marker",
				color: "var(--ck-highlight-marker-pink)",
				type: "marker",
			},
			{
				model: "blueMarker",
				class: "marker-blue",
				title: "Blue marker",
				color: "var(--ck-highlight-marker-blue)",
				type: "marker",
			},
			{
				model: "orangeMarker",
				class: "marker-orange",
				title: "Orange marker",
				color: "var(--ck-highlight-marker-orange)",
				type: "marker",
			},
			{
				model: "redPen",
				class: "pen-red",
				title: "Red pen",
				color: "var(--ck-highlight-pen-red)",
				type: "pen",
			},
			{
				model: "greenPen",
				class: "pen-green",
				title: "Green pen",
				color: "var(--ck-highlight-pen-green)",
				type: "pen",
			},
		],
	},
  };

  return (
    <>
      <p dir="rtl">
        {label} {required && <span className="text-danger">*</span>}
      </p>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={configuration}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data, name);
        }}
      />
      {error && (
        <p
          className="alert alert-light text-danger text-center"
          style={{ borderRadius: "30px", fontSize: "12px" }}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default Editor;

Editor.propTypes = {
  /** The initial value of the text editor - normally an HTML5 string */
  value: PropTypes.string.isRequired,
  /** Handler function fired when the user changes the value*/
  onChange: PropTypes.func.isRequired,
  /** The label to be displayed above the editor */
  label: PropTypes.string.isRequired,
  /** Unique name property to identify the editor instance */
  name: PropTypes.string.isRequired,
  /** Error message to be displayed if there is a validation error */
  error: PropTypes.string,
  /** Boolean to determine whether the field is required or not and display an asterisck accordingly */
  required: PropTypes.bool,
};
