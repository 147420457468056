import http from "./httpService";
import config from "../config.json";

const reset = config.globalApi + "/password-reset";
const forget = config.globalApi + "/password-forgot";

export async function forgetPassword(email) {
  await http.post(`${forget}`, null, { params: { email: email } });
}

export async function resetPassword(password, token) {
  await http.post(`${reset}`, null, {
    params: {
      token: token,
      password: password,
    },
  });
}

export async function updatePassword(currentPassword, newPassword) {
  const userId = localStorage.getItem("id");
  await http.post(
    `${config.globalApi}/users/${userId}/updatePassword?currentPassword=${currentPassword}&newPassword=${newPassword}`
  );
}
